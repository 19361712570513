//Import libraries
import React, { useState, useEffect, useMemo, useCallback } from 'react'
import { BrowserRouter as Router, Switch, Route, Redirect } from "react-router-dom"
import { Box, CssBaseline, Grid } from '@mui/material'
import * as XLSX from 'xlsx'
import { SnackbarProvider } from 'notistack'
//Import pages and components
import Error404 from './pages/Error404.jsx'
import SignIn from './pages/SignIn.jsx'
// import SignUp from './pages/SignUp'
// import ForgotPassword from './pages/ForgotPassword'
// import Homepage from './pages/Homepage'
// import Role from './pages/Role'
// import AddRole from './pages/AddRole'
import Profile from './pages/Profile.jsx'
import Users from './pages/Users.jsx'
// import UserPermissions from './pages/UserPermissions'
import Organization from './pages/Organization.jsx'
import School from './pages/School.jsx'
import Participant from './pages/Participant.jsx'
import CSVUpload from './pages/CSVUpload.jsx'
import Task from './pages/Task.jsx'
import AddTask from './pages/AddTask.jsx'
import EditTask from './pages/EditTask.jsx'
// import TaskModerationOverview from './pages/TaskModerationOverview'
// import Translations from './pages/Translations'
// import TranslateTasks from './pages/TranslateTasks'
// import ModerateTask from './pages/ModerateTasks'
import Collection from './pages/Collection.jsx'
import UploadStudentAnswer from './pages/UploadStudentAsnwer.jsx'
import AddCollection from './pages/AddCollection.jsx'
import EditCollection from './pages/EditCollection.jsx'
// import AddTaskToCollection from './pages/AddTaskToCollection'
import DifficultyGroup from './pages/DifficultyGroup'
import GeneralAnswers from './pages/GeneralAnswers'
import Domain from './pages/Domain'
import Competitions from './pages/Competitions'
import AddCompetition from './pages/AddCompetition'
import EditCompetition from './pages/EditCompetition'
import CompetitionDetails from './pages/CompetitionDetails'
import MarkingPreparation from './pages/MarkingPreparation'
import MarkingOverview from './pages/MarkingOverview'
import EditRoundMarking from './pages/EditRoundMarking'
// import PendingCompetitionPartners from './pages/PendingCompetitionPartners'
import AssignDifficulty from './pages/AssignDifficulty.jsx'
import Copyright from './components/Copyright.jsx'
import SideBar from './components/SideBar'
import NavBar from './components/NavBar'
import ReusableButton from './components/general/ReusableButton'
import ReusableDatePicker from './components/general/ReusableDatePicker'
//Import CSS
import './css/global.css'
//Import redux stuff
import { useSelector, useDispatch } from 'react-redux'
import { setCountryOptions, setRoleOptions, setLanguageOptions } from './actions'
//Import functions
import {
   isLoggedIn, isAdmin, isAdminOrPartner, isAdminOrPartnerOrAssistant,
   isAdminOrPartnerOrAssistantOrManager, isManagerOrTeacher, isAssistant
} from './functions/checkrole'
import { getCountries, getRoles, getLanguages } from './functions/getData'
import UploadStudentAnswerModal from './components/Competitions/UploadStudentAnswerModal'
import Indvedual from './pages/Indvidual'
import Comp from './pages/Comp'
import TasksModeration from './pages/TasksModeration'
import CollectionsModeration from './pages/CollectionsModeration'
import CompReport from './pages/CompReport'
import { logout } from './functions/httpMethods'
import AwardStats from './pages/AwardsStats'
import CompCollections from './pages/CompCollections'
import VerfiyAssign from './pages/VerfiyAssign'
import EditParticpantsBulk from './pages/editParticpantsBulk'

import CheatingList from './pages/CheatingList'
const EveryLink = ({ path, content, exact, defaultContent, condition }) => {
   let whatToShow
   if (isLoggedIn()) {
      if (defaultContent) whatToShow = defaultContent
      else {
         if (condition) whatToShow = content
         else whatToShow = 'Not allowed'
      }
   } else {
      if (defaultContent) whatToShow = content
      else whatToShow = <Redirect to='/signin' />
   }
   return <Route path={path} exact={exact}>{whatToShow}</Route>
}
const Dashboard = () => {
   const [pageNo, setPageNo] = useState(0)
   const [csvFile, setCSVFile] = useState(null)
   const [headers, setHeaders] = useState([])
   const [data, setData] = useState([])
   const [isRange, setIsRange] = useState(false)
   const [dates, setDates] = useState([
      new Date().setHours(0, 0, 0, 0),
      new Date(new Date().setDate(new Date().getDate() + 7)).setHours(0, 0, 0, 0)
   ])
   document.title = 'Dashboard'
   const divStyle = color => ({ border: `1px solid ${color}`, width: '100%', display: 'flex', justifyContent: 'center', marginBottom: 10 })
   const openFileInput = () => document.getElementById('file-upload-for-csv-xlsx').click()
   const onChangeCSVFile = (e, file) => {
      e.target.value = null
      const reader = new FileReader()
      if (file.type === 'text/csv') {
         setCSVFile(file)
         try {
            reader.onload = e => {
               const text = e.target.result
               processCSV(text, ',', file.type)
            }
            reader.readAsText(file)
         } catch (err) {
            console.log(err.message)
         }
      } else if (file.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') {
         setCSVFile(file)
         try {
            const rABS = !!reader.readAsBinaryString;
            reader.onload = e => { // e = on_file_select event
               /* Parse data */
               const bstr = e.target.result;
               const wb = XLSX.read(bstr, { type: 'binary' })
               /* Get first worksheet */
               const wsname = wb.SheetNames[0]
               const ws = wb.Sheets[wsname]
               /* Convert array of arrays */
               const text = XLSX.utils.sheet_to_csv(ws, { header: 1 })
               /* Update state */
               processCSV(text, ',', file.type)
            };
            if (rABS) reader.readAsBinaryString(file)
            else reader.readAsArrayBuffer(file)
         } catch (err) {
            console.log(err.message)
         }
      } else {
         alert('FUCKOFF')
      }
   }
   const processCSV = (str, delim = ',', type) => {
      //Get the header from the large chunk of string read from the csv file
      const header = str.slice(0, str.indexOf('\n')).trim().replace(/['"]+/g, '').split(delim)
      setHeaders(header)
      //Get all the data from the large chunk of string read from the csv file as an array
      const rows = type === 'text/csv'
         ? str.slice(str.indexOf('\n') + 1).split('\n').slice(0, -1)
         : str.slice(str.indexOf('\n') + 1).split('\n')
      // let isSameColumns = [... new Set(rows.map(r => r.split(',').length).slice(0,-1))].length === 1
      // console.log(rows)
      const newArray = rows.map((row, index) => {
         const values = row.split(',')
         if (index < 10) {
            console.log(JSON.stringify(values.at(-1).replace(/["]+/g, '')))
         }
         // const values = delimitCSVRow(row)
         const eachObject = header.reduce((obj, header, i) => {
            //This function will return an array with the last item being an object with undefined values
            //So we check if the object is undefined before using string functions on the object
            obj[header] = values[i]
               ? values[i].trim().replace(/["]+/g, '')
               : values[i]
            return obj
         }, {})
         return eachObject
      })
      //We do not want to set the last item as the csvArray since the last item is just undefined
      //as we are setting the rows by checking if there is a next row
      const a = newArray
      setData(a)
   }
   const tdStyle = { border: '1px solid' }
   
   return (
      <Grid container justifyContent='center' style={{ border: '1px solid red' }}>
         <div style={divStyle('red')}><h2>Welcome to SIMCC</h2></div>
         <div style={{
            width: '100%', display: window.location.host.includes('simcc') ? 'none' : 'flex',
            flexDirection: 'column', alignItems: 'center'
         }}>
            <div style={divStyle('green')}>
               <button onClick={() => setIsRange(!isRange)}>Set mode to {isRange ? 'normal' : 'range'}</button>
               <ReusableDatePicker width={210} state={dates} multiple required range={isRange}
                  setState={setDates} allowEmpty />
            </div>
            <ReusableButton text='Select File' bgColor='#144A94' fontSize={16} height={59} width={160} iconType='file'
               onClick={() => openFileInput()} />
            File Select:{csvFile ? ` ${csvFile.name}` : ''}
            <input id='file-upload-for-csv-xlsx' type="file" accept='.csv, .xlsx' onChange={e => onChangeCSVFile(e, e.target.files[0])}
               style={{ display: 'none' }} />
            <table style={{
               display: 'block', overflowY: 'hidden', overflowX: 'auto', whiteSpace: 'nowrap',
               width: 1200, border: '1px solid'
            }}>
               <tbody>
                  <tr>
                     {headers.map(h => <th key={h} style={tdStyle}>{h}</th>)}
                  </tr>
                  {data.slice(pageNo * 20, pageNo * 20 + 20).map((d, i) => (
                     <tr key={i}>
                        {Object.values(d).map((v, i2) => <td key={`${i}-${i2}`} style={tdStyle}>{v}</td>)}
                     </tr>
                  ))}
               </tbody>
            </table>
            <Grid container>
               {data.length && [...Array(Math.ceil(data.length / 20)).keys()].map((a, i) => (
                  <div key={i} style={{
                     backgroundColor: pageNo === i ? 'blue' : 'white',
                     color: pageNo === i ? 'white' : 'black', borderRadius: '50%',
                     height: 24, width: 24, textAlign: 'center', cursor: 'pointer'
                  }} onClick={() => setPageNo(i)}>
                     {i + 1}
                  </div>
               ))}
            </Grid>
         </div>
      </Grid>
   )
}
const BodyContent = ({ open, toggleDrawer, hoverDrawer, unhoverDrawer }) => {
   const [partcipient , setPartcipient] = useState()
   console.log(partcipient , 'main')
   const defaultContent = <Redirect to={isManagerOrTeacher() ? '/participants' : '/schools'} />
   // const defaultContent = isManagerOrTeacher() ? '/participants' : '/schools'
   const routes = [
      { path: '/', content: <School />, exact: true },
      { path: '/signin', content: <SignIn />, defaultContent },
      // { path: '/signup', content: <SignUp />, defaultContent },
      // { path: '/forgotpassword', content: <ForgotPassword />, defaultContent },
      { path: '/dashboard', content: <Dashboard /> },
      { path: '/schools', content: <School /> },
      { path: '/participants', content: <Participant setPartcipient={setPartcipient} /> },
      { path: '/csvupload', content: <CSVUpload /> },
      { path: '/profile', content: <Profile /> },
      { path: '/users', content: <Users />, condition: isAdminOrPartnerOrAssistantOrManager() },
      // { path: '/userpermissions/:id', content: <UserPermissions /> },
      { path: '/collection', content: <Collection />, condition: isAdmin() },
      { path: '/addcollection', content: <AddCollection />, condition: isAdmin() },
      { path: '/editcollection', content: <EditCollection />, condition: isAdmin() },
      { path: '/organizations', content: <Organization />, condition: isAdmin() },
      // { path: '/role', content: <Role />, condition: isAdmin() },
      // { path: '/addrole', content: <AddRole />, condition: isAdmin() },
      { path: '/task', content: <Task />, condition: isAdmin() },
      { path: '/addtask', content: <AddTask />, condition: isAdmin() },
      { path: '/edittask', content: <EditTask />, condition: isAdmin() },
      // { path: '/taskmoderation', content: <TaskModerationOverview /> },
      // { path: '/translations', content: <Translations /> },
      // { path: '/translateTasks/:id?', content: <TranslateTasks /> },
      // { path: '/moderatetask', content: <ModerateTask /> },
      // { path: '/addtasktocollection', content: <AddTaskToCollection /> },
      { path: '/domain', content: <Domain />, condition: isAdmin() },
      { path: '/difficultygroup', content: <DifficultyGroup /> },
      { path: '/competitions', content: <Competitions />, condition: isAdminOrPartnerOrAssistant() },
      { path: '/addcompetition', content: <AddCompetition />, condition: isAdmin() },
      { path: '/editcompetition', content: <EditCompetition />, condition: isAdminOrPartner() },
      { path: '/viewcompetition', content: <CompetitionDetails />, condition: isAssistant() },
      { path: '/markingpreparation', content: <MarkingPreparation /> },
      { path: '/markingoverview', content: <MarkingOverview /> },
      {path:'/EditRoundMarking' , content:<EditRoundMarking/>},
      {path: '/uploadStudentAnswer', content:<UploadStudentAnswer/>},
      // { path: '/pendingcompetitionpartners', content: <PendingCompetitionPartners />, condition: isAdmin() },
      // { path: '/assignDifficulty/:competitionID/:roundIndex/:levelIndex/:collectionID', content: <AssignDifficulty /> },
      { path: '/assignDifficulty', content: <AssignDifficulty />, condition: isAdmin() },
      { path:'/indevidalStudent' , content:<Indvedual particiapnt={partcipient}/>},
      {path:'/tasks' , content:<TasksModeration></TasksModeration>},
      {path:'/collections' , content:<CollectionsModeration></CollectionsModeration>},
      {path:'/competition' , content:<Comp></Comp>},
      {path:'/compReport' , content:<CompReport/>},
      {path:'/awardsStats',content:<AwardStats/>},
      {path:'/compCollections' , content:<CompCollections/>},
      {path:'/verfiyassign' , content:<VerfiyAssign/>},
      {path:'/editParticpantsBulk' , content:<EditParticpantsBulk/>},
      {path:'/generalanswers',content:<GeneralAnswers/>},
      {path:'/cheatinglist' , content:<CheatingList/>},
      { path: '', content: <Error404 /> },
   ]
   const contentContainerStyle = {
      paddingTop: isLoggedIn() && 64,
      paddingBottom: 48,
      // borderRadius: '0 0 0 8px', backgroundColor: '#FFF'
      // display: 'flex', flexDirection: 'column', justifyContent: 'space-between', minHeight: '93.4vh'
   }
   return (
      <Box style={{ display: 'flex' }}>
         {isLoggedIn() && <SideBar open={open} toggleDrawer={toggleDrawer} hoverDrawer={hoverDrawer} unhoverDrawer={unhoverDrawer} />}
         <Grid container style={contentContainerStyle}>
            {/* Content of the web app */}
            <Switch>
               {routes.map(({ path, content, exact, defaultContent, condition }, i) => (
                  <EveryLink key={i} path={path} content={content} exact={exact}
                     defaultContent={defaultContent} condition={condition === undefined ? true : condition} />
               ))}
            </Switch>
         </Grid>
      </Box>
   )
}
export default function App() {
   const [open, setOpen] = useState(false)
   const [forceOpen, setForceOpen] = useState(false)
   const user = useSelector(state => state.user)
   const roleOptions = useSelector(state => state.roleOptions)
   const countryOptions = useSelector(state => state.countryOptions)
   const languageOptions = useSelector(state => state.languageOptions)
   const dispatch = useDispatch()
   const toggleDrawer = state => {
      setOpen(state)
      setForceOpen(state)
   }
   const hoverDrawer = () => !forceOpen && setOpen(true)
   const unhoverDrawer = () => !forceOpen && setOpen(false)
   const populateCountryOptions = useCallback(() => {
      getCountries().then(d => dispatch(setCountryOptions(d))).catch(e => console.log(e))
   }, [dispatch])
   const populateRoleOptions = useCallback(() => {
      getRoles().then(d => dispatch(setRoleOptions(d))).catch(e => console.log(e))
   }, [dispatch])
   const populateLanguageOptions = useCallback(() => {
      getLanguages().then(d => dispatch(setLanguageOptions(d))).catch(e => console.log(e))
   }, [dispatch])
   const roleOptionsLength = useMemo(() => roleOptions.length, [roleOptions])
   const countryOptionsLength = useMemo(() => countryOptions.length, [countryOptions])
   const languageOptionsLength = useMemo(() => languageOptions.length, [languageOptions])
   
   useEffect(() => {
      if (isLoggedIn() && !roleOptionsLength && !countryOptionsLength && !languageOptionsLength) {
         populateCountryOptions()
         populateRoleOptions()
         populateLanguageOptions()
      }
   }, [user, roleOptionsLength, countryOptionsLength, languageOptionsLength,
      populateRoleOptions, populateCountryOptions, populateLanguageOptions])
   const boxStyle = { display: 'flex', flexDirection: 'column', justifyContent: 'space-between', minHeight: '100vh' }
   return (
      <SnackbarProvider maxSnack={4}>
         <Router>
            <Box style={boxStyle}>
               <CssBaseline />
               {/* NavBar({ open, toggleDrawer }) */}
               {isLoggedIn() && <NavBar open={open} toggleDrawer={toggleDrawer} />}
               {/* {BodyContent({ open, toggleDrawer, hoverDrawer, unhoverDrawer })} */}
               <BodyContent open={open} toggleDrawer={toggleDrawer} hoverDrawer={hoverDrawer} unhoverDrawer={unhoverDrawer} />
               {/* <div style={{ border: '1px solid red', paddingLeft: 400 }}>{JSON.stringify({ roleOptionsLength, countryOptionsLength, languageOptionsLength })}</div> */}
               <Copyright open={open} />
            </Box >
         </Router>
      </SnackbarProvider>
   )
}
