import React, { useMemo } from 'react'
import { Box, FormControlLabel, Grid, Radio, RadioGroup } from '@mui/material'
import { useSelector } from 'react-redux'
import { changeFiltered, delimitCSVRow, makeOptions, makeSureIsArray } from '../../functions/general'
import NunitoText from '../general/NunitoText'
import ReusableButton from '../general/ReusableButton'
import { useState } from 'react'
import Filter from '../Filter'
import { getCompAnswers } from '../../functions/getData'
import { showWarningSwal } from '../../functions/alert'
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min'
import { useEffect } from 'react'
import { useRef } from 'react'
import { Margin } from '@mui/icons-material'
import { deleteAnswer } from '../../functions/deleteData'
import { useSnackbar } from 'notistack'
import { showNotification } from '../../functions/snackbar'
export default function DeleteAnswer({ deleteFunction, cancelDelete ,refreshdata }) {
    const [params, setParams] = useState('')
    const isMounted = useRef(false)
    const [type , setType] = useState('0')
    const [csvFile, setCSVFile] = useState(null);
    const [filterOptions, setFilterOptions] = useState()
    const [csvHeaders, setCSVHeaders] = useState();
    const [indexes , SetIndexes] = useState([])
    const [csvArray, setCSVArray] = useState([]);
    const [country , setCountry] = useState()
    const [grade , setGrade] = useState()
    const [status , setStatus] = useState()
    const [filtered, setFiltered] = useState([
        { label: "country", state: '', key: 'country_id', header: 'country' },
       { label: 'grade', state: '', key: 'grade', header: 'grade' },
        { label: 'Status', state: '', key: 'status', header: 'status' }
    ].filter(Boolean))
    const exportTypeDivStyle = {
        display: "flex",
        alignItems: "center",
        marginLeft: 28,
      };
      const { enqueueSnackbar, closeSnackbar } = useSnackbar()

      const radioGroupStyle = { marginInline: "20px 50px" };
      const radioStyle = { color: "#000" };
      const controller = useMemo(() => new AbortController(), [])
      const signal = controller.signal
      let location = useLocation()
      const getData = (params, signal) => {
        if (location.search.slice(0, 4) === '?id=') {
        getCompAnswers(location.search.slice(4),params, signal).then(c=>{
          
             setFilterOptions(c.filterOptions)   
        }).catch(e => console.log(e))}else {
            showWarningSwal('Wrong query')
            if (!signal.aborted) {
           
            }
         }
    }

    const processCSV = (str, delim = ",") => {
  
      const rows = str.slice(str.indexOf("\n") + 1).split("\n");
      //Get the header from the large chunk of string read from the csv file
      const header = str
        .slice(0, str.indexOf("\n"))
        .trim()
        .replace(/['"]+/g, "")
        .split(delim);
      setCSVHeaders(header);
   
     let indexes = []
   
      const newArray = rows.map((row) => {
        // const values = row.split(',')
        let rowarr = row.split("");
        rowarr.map((el, index) => {
          if (el === "/r" && rowarr[index - 1] === ",") {
            rowarr.splice(index, 0, " ");
          }
        });
        
        const values = delimitCSVRow(rowarr.join(""));
        const eachObject = header.reduce((obj, header, i) => {
          //This function will return an array with the last item being an object with undefined values
          //So we check if the object is undefined before using string functions on the object
          obj[header] = values[i]
            ? values[i].trim().replace(/["]+/g, "")
            : values[i];
          return obj;
        }, {});
        return eachObject;
      });
  
      //We do not want to set the last item as the csvArray since the last item is just undefined
      //as we are setting the rows by checking if there is a next row
      const a = newArray.filter((n, i) => i < newArray.length);
  
     
        setCSVArray(a);
        a.map(el=>{
          let index=el[Object.keys(el)[0]];
          if(index!==''){
            indexes.push(index)
          }
        })
        SetIndexes(indexes)
        //Keep the original as reference and use the copyCSVArray to decide which columns to map to which field
      // if (!isAdmin()) submitCSVAsList(a)
    };

    const fillfilters = (value)=>{
        for(let i=0;i<3;i++){
            if(value[i].label==="country"){
                if(value[i].state!==''){
                    setCountry(value[i].state)
                }
            }else  if(value[i].label==="grade"){
                if(value[i].state!==''){
                    setGrade(value[i].state)
                }
            }else  if(value[i].label==="Status"){
                if(value[i].state!==''){
                    setStatus(value[i].state)
                }
            }
        }
    }
    useEffect(() => {
        isMounted.current = true
       
       getData(params,signal)
        return () => {
            controller.abort()
            isMounted.current = false
        }
    }, [controller, signal])
    const openFileInput = () =>
    document.getElementById("file-upload-for-csv").click();
    const onChangeCSVFile = (e, file) => {
      e.target.value = null;
      setCSVFile(file);
      const reader = new FileReader();
      try {
        reader.onload = (e) => {
          const text = e.target.result;
          processCSV(text);
        };
        reader.readAsText(file);
      } catch (err) {}
    };

      const onChangeFiltered = value => {
        changeFiltered(value, filtered, '', 10, signal, setFiltered, setParams, getData ,true , fillfilters)
    }
    const submitpayload = ()=>{
      const payload = {}
      if(type==='0'){
        if(country){
          payload.country_id=country
        }
        if(status){
          payload.status = status
        }
        if(grade){
          payload.grade = grade
        }
      }else{
        payload.indexes = indexes
      }
     if(type==='0'){
      if(Object.keys(payload).length === 0){
        return showWarningSwal('you must select atleast one filter before deleting')
      }
     }else{
      if(indexes.length<=0){
        return showWarningSwal('you must select a file before deleting')
      }
     }
     deleteAnswer(location.search.slice(4),payload).then(res=>{
      console.log(res , 'responseeee')
      if(res.status===200||res.status===201){
        showNotification('success', res.message, enqueueSnackbar, closeSnackbar)
        refreshdata()
        cancelDelete()
      }
     })
      
     
    }
    return (
        <Box className='popUpModal' style={{ width:'800px', minHeight: 400, maxHeight: "90%" , background:'#F5F5F5'}}>
              <NunitoText
        value="Mass Delete Participants"
        fontSize={30}
        fontWeight={600}
        color="#5E75C3"
        marginTop={20}
      />
           <div style={exportTypeDivStyle}>
              <NunitoText
                value="Delete Type: "
                fontSize={20}
                fontWeight={600}
              />
              <RadioGroup
                value={type}
                style={radioGroupStyle}
                onChange={(e) => setType(e.target.value)}
              >
                <Grid container>
                  <FormControlLabel
                    value="0"
                    control={<Radio style={radioStyle} />}
                    label="Via Filters"
                  />
                  <FormControlLabel
                    value="1"
                    control={<Radio style={radioStyle} />}
                    label="Via Csv"
                  />
                </Grid>
              </RadioGroup>
            </div>
            <Grid width={'100%'} container justifyContent={'center'} >
            { type==='0'? filterOptions&&<Filter  filterOptions={filtered.map(f => makeOptions(filterOptions, f.header))}
                    filtered={filtered} onChangeFiltered={onChangeFiltered} />:<>
                   
            <Grid>
            <NunitoText
                     value={`Make sure the csv file contains one column with a header index number`}
                     fontSize={20}
                     fontWeight={600}
                     marginBottom={20}
                     align="left"
                    />
            <input
              id="file-upload-for-csv"
              type="file"
              accept=".csv"
              onChange={(e) => onChangeCSVFile(e, e.target.files[0])}
              style={{ display: "none" }}
            />
                    <ReusableButton
                    text="Select File"
                    bgColor="#144A94"
                    fontSize={16}
                    height={59}
                    width={160}
                    iconType="file"
                    onClick={() => openFileInput()}
                  />
                 
              <NunitoText
              value={`File Select:${csvFile ? ` ${csvFile.name}` : ""}`}
              fontSize={20}
              fontWeight={600}
              color="#144A94"
              align="left"
            />
             </Grid>
                  </>
                    }
                    </Grid>
            <Grid container justifyContent="space-evenly" style={{ marginBlock: "30px 20px" }}>
                <ReusableButton text='Cancel' bgColor='#8D8D8D' fontSize={25} height={63} width={280}
                    onClick={() => cancelDelete()} />
                <ReusableButton text='Confirm' bgColor='#E83042' fontSize={25} height={63} width={280}
                    onClick={() => submitpayload()} />
            </Grid>
        </Box>
    )
}