import { Grid } from "@mui/material"
import NunitoText from "./general/NunitoText"
import { useEffect } from "react"
import { getCheaters } from "../functions/getData"

export default function CheatingBar({percentage ,setPercentage , err }){
    useEffect(()=>{

    },[setPercentage])
    const ProgressBar = ({ percentage,width , margin }) => <div style={{
        height: 40, width: width, borderRadius: 6,margin:'0 auto',
        background: `linear-gradient(90deg, #5E75C3 ${percentage}%, #D7D7D7 0%)`
     }} />
    
return   <Grid container>
    <Grid container>
<NunitoText width={'100%'} value={`Computing Progress: ${percentage}%`} fontSize={40} fontWeight={700} italic color='#144A94'  marginTop={'15%'} />
{err!==''&&<NunitoText width={'100%'} value={err} fontSize={40} fontWeight={700} italic color='#E83042'   />}
</Grid>
<ProgressBar  percentage={percentage}  width={'80%'} ></ProgressBar>
</Grid>
}