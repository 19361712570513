import ProgressBar from "@ramonak/react-progress-bar"
import ReusableButton from "./general/ReusableButton"
import { Button } from "@mui/material"
import { useEffect, useState } from "react"
import { BulkDownload } from "../functions/postData"
import { getBulkStatus } from "../functions/getData"
import { showNotification } from '../functions/snackbar'
import { useSnackbar } from 'notistack'
import { makeSureIsArray } from "../functions/general"
import { showWarningSwal } from "../functions/alert"
export const Progress = ({isdownloading , setDownloading , list , params})=>{
    const [percent,setPercent] = useState(0)
    const [jobid , setJobId] = useState(localStorage.getItem('jobid')===null?0:localStorage.getItem('jobid'))
    const [status , setStatus] = useState('')
    const [filepath , setfilepath] = useState(true)
    const [currentmsg , setcurrentMsg] = useState('')
    const [jobStatus , setJobStatus] = useState(0);
    const { enqueueSnackbar, closeSnackbar } = useSnackbar()

  const getpayload = ()=>{
    let temparr = []
    makeSureIsArray(list.data).map(el=>{
        temparr.push({
            index_no:el.index_no,
            certificate_no:el.certificate_no
        })
    })
    return {
        participants:temparr
    }
  }
  useEffect(()=>{
    if(localStorage.getItem('jobid')!==null&&localStorage.getItem('jobid')!==undefined&&localStorage.getItem('jobid')!==0){
        setDownloading(true)
    }
  },[])
    useEffect(()=>{
        if(isdownloading){
        if(status=='Completed'){
            console.log('in complete')
            window.open(`https://api.dev.simccms.org/api/participant/reports/bulk_download/download_file/${localStorage.getItem('jobid')}`, '_blank', 'noreferrer');
            setDownloading(false)
            localStorage.removeItem('jobid')
            setStatus('')
            setPercent(0)
        }else if(status=='Failed'){
            showWarningSwal(currentmsg)
            setDownloading(false)
            localStorage.removeItem('jobid')
            setStatus('')
            setPercent(0)
        }else{
           setTimeout(()=>{
           getBulkStatus(Number(localStorage.getItem('jobid'))).then(res=>{
                console.log(res , 'here')
                setStatus(res.status)
                setcurrentMsg(res.message)
                setPercent(Number(res.progress))
                setfilepath(!filepath)
           })
           },5000)
        }}
    },[percent,setPercent,isdownloading, setJobId,jobid,setfilepath,filepath])
    console.log(params.substring(11) , 'here')
    return  isdownloading?<div  style={{marginRight:20 , width:200, position:'relative'}} height={48}  >
       { percent===0&&<p style={{position:'absolute', width:'100%' ,height:'100%',textAlign:'center',color:'#999', fontSize:'12px',zIndex:'0'}}>Downloading Reports</p>}
        <ProgressBar  labelSize={15} bgColor="#5E75C3" height="100%"  completed={percent}/></div>:<ReusableButton text='Mass Download Reports' onClick={()=>{   
    BulkDownload(`?limits=0`).then(res=>{
        console.log(res)
        if(res.status===200){
        setDownloading(true)
       
        localStorage.setItem('jobid',res.job_id)
      
        showNotification('success','the data donwload has started' , enqueueSnackbar , closeSnackbar)
        }
    })
    }}  fontSize={15} bgColor='#5E75C3' height={48} marginRight={20}></ReusableButton>
}