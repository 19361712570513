import React, { useState, useEffect, useMemo, useRef } from "react";
import { Box, Grid, Collapse, Modal } from "@mui/material";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ViewOption from "../components/ViewOption";
import Filter from "../components/Filter";
import AddSchoolModal from "../components/School/AddSchoolModal";
import AddUserModal from "../components/User/AddUserModal";
import AddParticipantModal from "../components/Participant/AddParticipantModal";
import EditSchoolModal from "../components/School/EditSchoolModal";
import DeleteModal from "../components/modal/DeleteModal";
import ApproveSchoolModal from "../components/School/ApproveSchoolModal";
import RejectModal from "../components/modal/RejectModal";
import RestoreSchoolModal from "../components/School/RestoreSchoolModal";
import HeavyTable from "../components/table/HeavyTable";
import { useSelector } from "react-redux";
import {
  makeSureIsArray,
  makeOptions,
  checkIfAllSelectedRowsMatchStatus,
  checkIfAnySelectedRowIs,
  resetAll,
  changeViewOptions,
  changeRowsPerPage,
  changeFiltered,
  changeSearch,
  loadData,
  controlView,
  controlFilter,
  cancelAction,
} from "../functions/general";
import { showWarningSwal } from "../functions/alert";
import { getSchool, getSchools } from "../functions/getData";
import {
  approveSchool,
  rejectSchool,
  restoreSchool,
} from "../functions/patchData";
import { deleteSchool } from "../functions/deleteData";
import { showNotification } from "../functions/snackbar";
import NunitoText from "../components/general/NunitoText";
import ReusableTextField from "../components/general/ReusableTextField";
import ReusableButton from "../components/general/ReusableButton";
import ViewAndFilterButton from "../components/ViewAndFilterButton";
import LoadingBackdrop from "../components/general/LoadingBackdrop";
import NoData from "../components/general/NoData";
import Loader from "../components/general/Loader";
import { useSnackbar } from "notistack";
import {
  isAdmin,
  isAdminOrPartner,
  isPartnerOrAssistant,
  isAdminOrPartnerOrAssistant,
} from "../functions/checkrole";
const schoolHeader = () =>
  [
    { id: "name", label: "School/Tuition" },
    { id: "name_in_certificate", label: "name in certificate" },
    isAdmin() && { id: "country_name", label: "Country" },
    { id: "participants_count", label: "participants" },
    { id: "status", label: "Status" },
    { id: "email", label: "Email" },
    isAdminOrPartner() && { id: "teachers", label: "Teachers" },
    { id: "private", label: "Tuition Centre" },
    { id: "address", label: "Address" },
    { id: "postal", label: "Postal Code" },
    { id: "province", label: "Province" },
    { id: "phone", label: "Phone Number" },
    { id: "created_by_username", label: "Submit By" },
    { id: "approved_by_username", label: "Approved By" },
    { id: "rejected_by_username", label: "Rejected By" },
    { id: "modified_by_username", label: "Last Modified By" },
    { id: "reject_reason", label: "Has been rejected" },
  ].filter(Boolean);
const initialParams = "?limits=10";
export default function School() {
  document.title = "School";
  const isMounted = useRef(false);
  const user = useSelector((state) => state.user);
  const [header, setHeader] = useState(schoolHeader());
  const [schoolTable, setSchoolTable] = useState();
  const [filtered, setFiltered] = useState(
    [
      {
        label: "School/Tuition",
        state: "",
        key: "private",
        header: "schooltype",
      },
      isAdmin() && {
        label: "Country",
        state: "",
        key: "country_id",
        header: "countries",
      },
      { label: "Status", state: "", key: "status", header: "status" },
    ].filter(Boolean)
  );
  const [filterOptions, setFilterOptions] = useState();
  const [viewOptions, setViewOptions] = useState([
    { label: "Address", state: true, key: "address" },
    { label: "Postal Code", state: true, key: "postal" },
    { label: "Province", state: true, key: "province" },
    { label: "Phone Number", state: true, key: "phone" },
    { label: "Submit By", state: true, key: "created_by_username" },
    { label: "Approve By", state: true, key: "approved_by_username" },
    { label: "Rejected By", state: true, key: "rejected_by_username" },
    { label: "Last Modified By", state: true, key: "modified_by_username" },
    { label: "Has been rejected", state: true, key: "reject_reason" },
  ]);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [search, setSearch] = useState("");
  const [params, setParams] = useState(initialParams);
  const [loading, setLoading] = useState(false);
  const [loadingTable, setLoadingTable] = useState(false);
  const [firstLoaded, setFirstLoaded] = useState(false);
  const [viewing, setViewing] = useState(false);
  const [filtering, setFiltering] = useState(false);
  const [id, setId] = useState("");
  const [selected, setSelected] = useState([]);
  const [selecting, setSelecting] = useState([]);
  const [onDelete, setOnDelete] = useState(false);
  const [onAddSchool, setOnAddSchool] = useState(false);
  const [onEditSchool, setOnEditSchool] = useState(false);
  const [onApprove, setOnApprove] = useState(false);
  const [onReject, setOnReject] = useState(false);
  const [onRestore, setOnRestore] = useState(false);
  const [onAddUser, setOnAddUser] = useState(false);
  const [onAddParticipant, setOnAddParticipant] = useState(false);
  const handleView = () => controlView(viewing, setViewing, setFiltering);
  const handleFilter = () => controlFilter(filtering, setFiltering, setViewing);
  const cancelDelete = () => cancelAction(setOnDelete, setId);
  const cancelEdit = () => cancelAction(setOnEditSchool, setId);
  const cancelApprove = () => cancelAction(setOnApprove, setId);
  const cancelReject = () => cancelAction(setOnReject, setId);
  const cancelRestore = () => cancelAction(setOnRestore, setId);
  const cancelAddUser = () => cancelAction(setOnAddUser, setId);
  const cancelAddParticipant = () => cancelAction(setOnAddParticipant, setId);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const afterFunction = (cancel, data) => {
    if ([200, 201].includes(data.status)) {
      resetAll(initialParams, filtered, setParams, setSearch, setFiltered);
      getData(initialParams, signal);
      cancel();
      showNotification("success", data.message, enqueueSnackbar, closeSnackbar);
      setSelected([]);
      setSelecting([]);
    }
  };
  const deleteFunction = () => {
    setLoading(true);
    deleteSchool({ id: id === "" ? selected : [id] })
      .then((d) => {
        afterFunction(cancelDelete, d);
      })
      .catch((e) => console.log(e))
      .finally(() => isMounted.current && setLoading(false));
  };
  const approveFunction = () => {
    setLoading(true);
    approveSchool({ id: id === "" ? selected : [id] })
      .then((d) => {
        setLoading(false);
        afterFunction(cancelApprove, d);
      })
      .catch((e) => console.log(e))
      .finally(() => isMounted.current && setLoading(false));
  };
  const rejectFunction = (reason) => {
    setLoading(true);
    rejectSchool({ id: [id], reject_reason: [reason] })
      .then((d) => {
        setLoading(false);
        afterFunction(cancelReject, d);
      })
      .catch((e) => console.log(e))
      .finally(() => isMounted.current && setLoading(false));
  };
  const restoreFunction = () => {
    setLoading(true);
    restoreSchool({ id: [id] })
      .then((d) => {
        setLoading(false);
        afterFunction(cancelRestore, d);
      })
      .catch((e) => console.log(e))
      .finally(() => isMounted.current && setLoading(false));
  };
  const getData = (params, signal) => {
    setSelected([]);
    setSelecting([]);
    loadData(
      setLoadingTable,
      setFirstLoaded,
      getSchools,
      params,
      setSchoolTable,
      setFilterOptions,
      false,
      signal
    );
  };
  const onChangeViewOptions = (value) =>
    changeViewOptions(schoolHeader(), setViewOptions, setHeader, value);
  const onChangeRowsPerPage = (value) => {
    changeRowsPerPage(
      value,
      rowsPerPage,
      filtered,
      search,
      signal,
      setRowsPerPage,
      setParams,
      getData
    );
  };
  const onChangeFiltered = (value) => {
    changeFiltered(
      value,
      filtered,
      search,
      rowsPerPage,
      signal,
      setFiltered,
      setParams,
      getData
    );
  };
  const onChangeSearch = (value) => {
    changeSearch(
      value,
      search,
      filtered,
      rowsPerPage,
      signal,
      setSearch,
      setParams,
      getData
    );
  };
  const onChangePage = (pageNo) => getData(`${params}&page=${pageNo}`, signal);
  const controller = useMemo(() => new AbortController(), []);
  const signal = controller.signal;
  useEffect(() => {
    isMounted.current = true;
    getData(initialParams, signal);

    return () => {
      controller.abort();
      isMounted.current = false;
    };
  }, [controller, signal]);
  const onClickMassApprove = () => {
    return schoolTable &&
      checkIfAllSelectedRowsMatchStatus(
        selected,
        schoolTable.data,
        ["pending"],
        user
      )
      ? setOnApprove(true)
      : showWarningSwal(
          "Please only select pending schools that are not added by you to mass approve"
        );
  };
  const onClickMassDelete = () => {
    if (!selected.length)
      return showWarningSwal("Please select at least one school");
    if (isAdmin()) {
      return schoolTable &&
        checkIfAnySelectedRowIs(selected, schoolTable.data, "deleted")
        ? showWarningSwal(
            "Please select schools that aren't already deleted to delete"
          )
        : setOnDelete(true);
    } else if (isPartnerOrAssistant()) {
      return schoolTable &&
        checkIfAllSelectedRowsMatchStatus(selected, schoolTable.data, [
          "pending",
          "rejected",
        ])
        ? setOnDelete(true)
        : showWarningSwal("You can only delete pending or rejected schools");
    }
  };
  const downloadFile = ({ data, fileName, fileType }) => {
    const blob = new Blob([data], { type: fileType });

    const a = document.createElement("a");
    a.download = fileName;
    a.href = window.URL.createObjectURL(blob);
    const clickEvt = new MouseEvent("click", {
      view: window,
      bubbles: true,
      cancelable: true,
    });
    a.dispatchEvent(clickEvt);
    a.remove();
  };

  const exportToCsv = (e, data, compname) => {
    // Headers for each column
    let headers = [
      "Name,id,Country,Status,Email,Address,Postal Code,Province,Phone Number",
    ];
    // Convert users data to a csv
    data.shift();
    let usersCsv = data.reduce((acc, user) => {
      const {
        name,
        id,
        country,
        status,
        email,
        address,
        postal,
        province,
        phone,
      } = user;
      acc.push(
        [
          name,
          id,
          country,
          status,
          email,
          address,
          postal,
          province,
          phone,
        ].join(",")
      );
      return acc;
    }, []);

    downloadFile({
      data: [...headers, ...usersCsv].join("\n"),
      fileName: `${compname}.csv`,
      fileType: "text/csv",
    });
  };
  const exportData = (e) => {
    setLoading(true);
    getSchool("?mode=csv").then((res) => {
      console.log(res);
      setLoading(false);
      let filtered = res.filter((school) => {
        return school.status === "active" || school.status === "pending";
      });
      exportToCsv(e, filtered, "Schools");
    });
  };
  return (
    <Box className="wrapperBox">
      <LoadingBackdrop loading={loading} />
      <Collapse in={viewing}>
        <ViewOption
          viewOptions={viewOptions}
          rowsPerPage={rowsPerPage}
          onChangeRowsPerPage={onChangeRowsPerPage}
          onChangeViewOptions={onChangeViewOptions}
        />
      </Collapse>
      <Collapse in={filtering}>
        {filterOptions && (
          <Filter
            type="collapse"
            filterOptions={filtered.map((f) =>
              makeOptions(filterOptions, f.header)
            )}
            filtered={filtered}
            onChangeFiltered={onChangeFiltered}
          />
        )}
      </Collapse>
      <Grid
        className="firstRowContainer"
        container
        justifyContent="space-between"
      >
        <div className="dashboardAndSelfBtnDiv">
          <ReusableButton
            text="Dashboard"
            fontSize={14}
            bgColor="#F16774"
            height={36}
            width={125}
            br={18}
            to="/dashboard"
            iconType="home"
          />
          <ChevronRightIcon />
          <ReusableButton
            text="Schools"
            fontSize={14}
            bgColor="#F16774"
            height={36}
            br={18}
          />
        </div>
        <div className="viewAndFilterBtnDiv">
          <ViewAndFilterButton
            text="View Options"
            state={viewing}
            fontSize={14}
            height={42}
            onClick={handleView}
            marginRight={10}
          />
          <ViewAndFilterButton
            text="Filter"
            state={filtering}
            fontSize={14}
            height={42}
            onClick={handleFilter}
          />
        </div>
      </Grid>
      <Grid className="tableContainer" container>
        <NunitoText
          value="Schools"
          fontSize={40}
          fontWeight={700}
          italic
          color="#144A94"
        />
        <Grid
          className="searchAndBtnContainer"
          container
          alignItems="center"
          justifyContent="space-between"
        >
          <ReusableTextField
            type="search"
            width={500}
            height={60}
            bgColor="#F2F2F2"
            placeholder="Search using keyword"
            state={search}
            setState={onChangeSearch}
            onBlur
          />
          <div>
            {isAdminOrPartnerOrAssistant() && (
              <ReusableButton
                text="Export CSV"
                fontSize={15}
                bgColor="#5E75C3"
                height={46}
                marginRight={20}
                iconType="export"
                onClick={(e) => exportData(e)}
              />
            )}
            {isAdminOrPartnerOrAssistant() && (
              <ReusableButton
                text="Import CSV"
                fontSize={15}
                bgColor="#5E75C3"
                height={46}
                marginRight={20}
                iconType="import"
                to="csvupload"
              />
            )}
          </div>
          {isAdminOrPartnerOrAssistant() && (
            <div className="endBtnDiv">
              <ReusableButton
                text="Add School"
                fontSize={15}
                bgColor="#5E75C3"
                height={46}
                marginRight={20}
                onClick={() => setOnAddSchool(true)}
                iconType="add"
              />
              {isAdminOrPartner() && (
                <ReusableButton
                  text="Mass Approve"
                  fontSize={15}
                  bgColor="#5E75C3"
                  height={46}
                  marginRight={20}
                  onClick={() => onClickMassApprove()}
                  iconType="check"
                />
              )}
              <ReusableButton
                text="Mass Delete"
                fontSize={15}
                bgColor="#E83042"
                height={46}
                marginRight={20}
                onClick={() => onClickMassDelete()}
                iconType="delete"
              />
            </div>
          )}
        </Grid>
        <NunitoText
          value='Search only in "School/Tuition", "Address", "Postal Code", "Province" and "Phone Number" column'
          fontSize={20}
          fontWeight={400}
          italic
          color="#144A94"
        />
        {loadingTable && <Loader height={600} />}
        {!loadingTable &&
          schoolTable &&
          makeSureIsArray(schoolTable.data).length && (
            <HeavyTable
              headers={header}
              list={schoolTable}
              title="schools"
              rowsPerPage={rowsPerPage}
              setOnDelete={setOnDelete}
              onChangePage={onChangePage}
              fixed={["name", "country_name"]}
              selecting={selecting}
              setSelecting={setSelecting}
              selected={selected}
              setSelected={setSelected}
              setId={setId}
              setOnApprove={setOnApprove}
              setOnReject={setOnReject}
              setOnRestore={setOnRestore}
              setOnEdit={setOnEditSchool}
              setOnAddUser={setOnAddUser}
              setOnAddParticipant={setOnAddParticipant}
            />
          )}
        {!loadingTable &&
          firstLoaded &&
          schoolTable &&
          !makeSureIsArray(schoolTable.data).length && <NoData height={600} />}
      </Grid>
      <Modal open={onDelete} onClose={() => cancelDelete()}>
        <>
          {schoolTable && (
            <DeleteModal
              table={schoolTable}
              id={id}
              selected={selected}
              deleteFunction={deleteFunction}
              cancelDelete={cancelDelete}
            />
          )}
        </>
      </Modal>
      <Modal open={onApprove} onClose={() => cancelApprove()}>
        <>
          {schoolTable && (
            <ApproveSchoolModal
              table={schoolTable}
              id={id}
              selected={selected}
              approveFunction={approveFunction}
              cancelApprove={cancelApprove}
              object="schools"
            />
          )}
        </>
      </Modal>
      <Modal open={onReject} onClose={() => cancelReject()}>
        <>
          {schoolTable && (
            <RejectModal
              table={schoolTable}
              id={id}
              rejectFunction={rejectFunction}
              cancelReject={cancelReject}
            />
          )}
        </>
      </Modal>
      <Modal open={onRestore} onClose={() => cancelRestore()}>
        <>
          {schoolTable && (
            <RestoreSchoolModal
              table={schoolTable}
              id={id}
              restoreFunction={restoreFunction}
              cancelRestore={cancelRestore}
              object="schools"
            />
          )}
        </>
      </Modal>
      <Modal open={onAddSchool} onClose={() => setOnAddSchool(false)}>
        <>
          <AddSchoolModal
            setLoading={setLoading}
            setOnAddSchool={setOnAddSchool}
            afterAddingSchool={afterFunction}
          />
        </>
      </Modal>
      <Modal open={onEditSchool} onClose={() => cancelEdit()}>
        <>
          {schoolTable && (
            <EditSchoolModal
              setLoading={setLoading}
              table={schoolTable}
              id={id}
              afterEditingSchool={afterFunction}
              cancelEdit={cancelEdit}
            />
          )}
        </>
      </Modal>
      <Modal open={onAddUser} onClose={() => cancelAddUser()}>
        <>
          {schoolTable && (
            <AddUserModal
              setLoading={setLoading}
              setOnAddUser={setOnAddUser}
              table={schoolTable}
              id={id}
              afterAddingUser={afterFunction}
              cancelAddUser={cancelAddUser}
            />
          )}
        </>
      </Modal>
      <Modal open={onAddParticipant} onClose={() => cancelAddParticipant()}>
        <>
          {schoolTable && (
            <AddParticipantModal
              setLoading={setLoading}
              setOnAddParticipant={setOnAddParticipant}
              table={schoolTable}
              id={id}
              afterAddingParticipant={afterFunction}
              cancelAddParticipant={cancelAddParticipant}
            />
          )}
        </>
      </Modal>
    </Box>
  );
}
