import React, { useEffect } from 'react'
import { Box, Grid } from '@mui/material'
import NunitoText from '../general/NunitoText'
import ReusableTextField from '../general/ReusableTextField'
import ReusableButton from '../general/ReusableButton'
import { useState } from 'react'
import ReusableAutocompleteWithID from '../general/ReusableAutocompleteWithID'

export default function CheatDownloadModal({downloadcsv , compName , allCountryOptions}) {
    const [fileName , setFileName] = useState(compName)
    const [country , setcountry] = useState([])
   

  
   
  
    console.log(allCountryOptions)
    return  <Box className='popUpModal horizontalScrollable' style={{ width: 1000, height: 500 }}>
    <NunitoText value='DOWNLOAD CSV' fontSize={30} fontWeight={600} color='#5E75C3' marginTop={20} />

    <Grid container justifyContent={'space-between'} width={'80%'} alignSelf={'center'}>
        <ReusableTextField type={'withLabel'} placeholder={'File Name'} fullWidth state={fileName} setState={setFileName}/>
    </Grid>
    <Grid container alignSelf={'center'} justifyContent={'center'}>
        <ReusableAutocompleteWithID type='withLabel' width={550} placeholder='Country' multiple
                        state={country} setState={setcountry} borderColor='#707070' 
                        options={allCountryOptions.map(c => ({ id: c.id, option: c.display_name }))}/>
    </Grid>
    <Grid>
        <ReusableButton  text={'Download'}  fontSize={22} height={60} width={300} bgColor='#5E75C3' onClick={() => {downloadcsv(fileName,country)}} marginBottom={20} />
    </Grid>
</Box>
}