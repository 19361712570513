import React, {
  useEffect,
  useState,
  useCallback,
  useMemo,
  useRef,
} from "react";
import { Box, Grid } from "@mui/material";
import { useSelector } from "react-redux";
import {
  makeSureIsArray,
  gradeOptions as allGradeOptions,
} from "../../functions/general";
import { showWarningSwal } from "../../functions/alert";
import { addParticipant } from "../../functions/postData";
import {
  getCompetitions,
  getSchools,
  getOrganizations,
} from "../../functions/getData";
import NunitoText from "../general/NunitoText";
import ReusableTextField from "../general/ReusableTextField";
import ReusableAutocompleteWithID from "../general/ReusableAutocompleteWithID";
import ReusableButton from "../general/ReusableButton";
import {
  isAdmin,
  isAdminOrPartnerOrAssistant,
  isPartnerOrAssistant,
  isManagerOrTeacher,
  isFromTuitionCentre,
} from "../../functions/checkrole";
import Swal from "sweetalert2";
import { useSnackbar } from "notistack";
import { showNotification } from "../../functions/snackbar";

const boxStyle = { width: 1200, maxHeight: "90%" };
const firstRowStyle = { marginTop: 20, marginBottom: 30 };
const rowStyle = { marginBottom: 30 };
const lastRowStyle = { marginBottom: 30, marginLeft: 25 };
const buttonsContainerStyle = { marginBottom: 30, paddingInline: 100 };
export default function AddParticipantModal({
  setLoading,
  setOnAddParticipant,
  table,
  id,
  afterAddingParticipant,
  cancelAddParticipant,
}) {
  const isMounted = useRef(false);
  const selectedSchool =
    table === undefined
      ? undefined
      : makeSureIsArray(table.data).find((s) => s.id === id);
  const user = useSelector((state) => state.user);
  const typeOptions = [
    { id: 0, name: "School Candidate" },
    { id: -1, name: "Private Candidate" },
    { id: 1, name: "Organization Candidate" },
  ];
  const [competitionOptions, setCompetitionOptions] = useState([]);
  const [gradeOptions, setGradeOptions] = useState(allGradeOptions);
  const [allOrganizationOptions, setAllOrganizationOptions] = useState([]);
  const [organizationOptions, setOrganizationOptions] = useState([]);
  const allCountryOptions = useSelector((state) => state.countryOptions);
  const [countryOptions, setCountryOptions] = useState([]);
  const [allSchoolOptions, setAllSchoolOptions] = useState([]);
  const [schoolOptions, setSchoolOptions] = useState([]);
  const [allTuitionCentreOptions, setAllTuitionCentreOptions] = useState([]);
  const [tuitionCentreOptions, setTuitionCentreOptions] = useState([]);

  const [name, setName] = useState("");
  const [studentClass, setStudentClass] = useState("");
  const [competition, setCompetition] = useState("");
  const [organization, setOrganization] = useState("");
  const [country, setCountry] = useState("");
  const [email, setEmail] = useState("");
  const [school, setSchool] = useState("");
  const [tuitionCentre, setTuitionCentre] = useState("");
  const [grade, setGrade] = useState("");
  const [type, setType] = useState("");
  const [identifier, setIdentifier] = useState("");
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const allowedStatus = useMemo(
    () =>
      [
        "active",
        isAdminOrPartnerOrAssistant() && "ready",
        isAdmin() && "lock",
      ].filter(Boolean),
    []
  );
  const actualSchoolOptions = [
    [-1, 1].includes(type) && { id: -1, name: "Home School" },
    ...schoolOptions,
  ].filter(Boolean);
  const checkIfCompOrgIsAvailable = useCallback(
    (org) => {
      return org && allowedStatus.includes(org.status);
    },
    [allowedStatus]
  );
  const loadCompetitionsForAdmin = useCallback(
    (competitions, country_id) => {
      console.log(
        makeSureIsArray(competitions),
        makeSureIsArray(competitions).filter((d) => {
          let foundOrganizations = d.competition_organization.filter((o) =>
            country_id === undefined ? true : o.country_id === country_id
          );
          return d.status === "active" && foundOrganizations.length;
        }),
        "dd"
      );

      let filteredCompetitions = makeSureIsArray(competitions).filter((d) => {
        let foundOrganizations = d.competition_organization.filter((o) =>
          country_id === undefined ? true : o.country_id === country_id
        );
        return (
          d.status === "active" &&
          foundOrganizations.length &&
          foundOrganizations.some((org) => checkIfCompOrgIsAvailable(org))
        );
      });
      setCompetitionOptions(
        filteredCompetitions.map((d) => ({
          id: d.id,
          name: d.name,
          competition_organization: d.competition_organization,
          allowed_grades: d.allowed_grades,
        }))
      );
    },
    [checkIfCompOrgIsAvailable]
  );
  const loadCompetitionsForPartnerOrBelow = useCallback(
    (competitions) => {
      console.log(competitions, "dd");

      let filteredCompetitions = makeSureIsArray(competitions).filter((d) => {
        return (
          d.status === "active" &&
          checkIfCompOrgIsAvailable(d.competition_organization[0])
        );
      });

      setCompetitionOptions(
        filteredCompetitions.map((d) => ({
          id: d.id,
          name: d.name,
          competition_organization: d.competition_organization,
          allowed_grades: d.allowed_grades,
        }))
      );
    },
    [checkIfCompOrgIsAvailable]
  );
  useEffect(() => {
    isMounted.current = true;
    // If adding participants directly to a school
    const controller = new AbortController();
    const signal = controller.signal;
    if (id !== undefined) {
      setCountryOptions([
        {
          id: selectedSchool.country_id,
          display_name: selectedSchool.country_name,
        },
      ]);
      setCountry(selectedSchool.country_id);
      getCompetitions("?limits=50&status=active", signal)
        .then((c) => {
          let competitions = c.competitionList.data;
          if (isAdmin()) {
            loadCompetitionsForAdmin(competitions, selectedSchool.country_id);
          } else {
            loadCompetitionsForPartnerOrBelow(competitions);
          }
        })
        .catch((e) => console.log(e));
      setType(selectedSchool.private ? -1 : 0);
      if (isAdmin()) {
        getOrganizations(`?limits=100&status=active`, signal)
          .then((o) => {
            setAllOrganizationOptions(
              o.OrganizationLists.data.map((d) => ({
                id: d.id,
                name: d.name,
                users: d.users,
              }))
            );
          })
          .catch((e) => console.log(e));
      }
      if (selectedSchool.private) {
        getSchools(
          `?limits=0&status=active&country_id=${selectedSchool.country_id}&private=0`,
          signal
        )
          .then((s) => {
            setSchoolOptions(
              s.SchoolLists.data.map((d) => ({ id: d.id, name: d.name }))
            );
          })
          .catch((e) => console.log(e));
        setTuitionCentreOptions([
          { id: selectedSchool.id, name: selectedSchool.name },
        ]);
        setTuitionCentre(selectedSchool.id);
      } else {
        setSchoolOptions([
          { id: selectedSchool.id, name: selectedSchool.name },
        ]);
        setSchool(selectedSchool.id);
      }
    } else {
      getCompetitions("?limits=50&status=active", signal)
        .then((c) => {
          let competitions = c.competitionList.data;
          if (isAdmin()) {
            loadCompetitionsForAdmin(competitions);
          } else {
            loadCompetitionsForPartnerOrBelow(competitions);
          }
        })
        .catch((e) => console.log(e));
      if (isAdmin()) {
        getOrganizations(`?limits=100&status=active`, signal)
          .then((o) => {
            setAllOrganizationOptions(
              o.OrganizationLists.data.map((d) => ({
                id: d.id,
                name: d.name,
                users: d.users,
              }))
            );
          })
          .catch((e) => console.log(e));
      } else if (isPartnerOrAssistant()) {
        getSchools("?limits=0&status=active", signal)
          .then((s) => {
            setAllSchoolOptions(
              s.SchoolLists.data
                .filter((s) => !s.private)
                .map((d) => ({ id: d.id, name: d.name }))
            );
            setAllTuitionCentreOptions(
              s.SchoolLists.data
                .filter((s) => s.private)
                .map((d) => ({ id: d.id, name: d.name }))
            );
          })
          .catch((e) => console.log(e));
      }
    }
    return () => {
      controller.abort();
      isMounted.current = false;
    };
  }, [
    id,
    selectedSchool,
    user,
    loadCompetitionsForAdmin,
    loadCompetitionsForPartnerOrBelow,
  ]);
  const getAppropriateOrgs = (competition_id, country_id) => {
    let competitionOrganizations = competitionOptions.find(
      (c) => c.id === competition_id
    ).competition_organization;
    // let filteredOrganizations = competitionOrganizations.filter(org =>
    //     (country_id === undefined ? true : org.country_id === country_id) && checkIfCompOrgIsAvailable(org)
    // )
    let filteredOrganizations =
      country_id === undefined
        ? competitionOrganizations
        : competitionOrganizations.filter(
            (org) => org.country_id === country_id
          );
    setOrganizationOptions(
      allOrganizationOptions.filter((o) =>
        filteredOrganizations.map((org) => org.organization_id).includes(o.id)
      )
    );
  };
  const onChangeCompetition = (value) => {
    setCompetition(value);
    setGrade("");
    setOrganization("");
    if (!selectedSchool) {
      setCountry("");
      if (school !== -1) setSchool("");
      if (!isPartnerOrAssistant()) setTuitionCentre("");
    }
    if (value !== "") {
      let selectedCompetition = competitionOptions.find((c) => c.id === value);
      setGradeOptions(
        allGradeOptions.filter((g) =>
          selectedCompetition.allowed_grades
            .map((z) => Number(z))
            .includes(g.id)
        )
      );
      if (isAdmin()) {
        selectedSchool
          ? getAppropriateOrgs(value, country)
          : getAppropriateOrgs(value);
      } else if (isPartnerOrAssistant()) {
        if (!selectedSchool) {
          setSchoolOptions(allSchoolOptions);
          setTuitionCentreOptions(allTuitionCentreOptions);
        }
      }
    }
  };
  // onChangeType is used for Admin, Country Partner and Country Partner Assistant
  // onChangeOrganization and onChangeCountry are only used for Admin
  const onChangeType = (value) => {
    if (value === 0 && school === -1) setSchool("");
    setTuitionCentre("");
    setType(value);
  };
  const onChangeOrganization = (value) => {
    setOrganization(value);
    if (!selectedSchool) {
      setCountry("");
      setSchool("");
      setTuitionCentre("");
    }
    if (value !== "") {
      let competitionOrganizations = competitionOptions.find(
        (c) => c.id === competition
      ).competition_organization;
      let participatingCountries = competitionOrganizations
        .filter((o) => o.organization_id === value)
        .map((c) => c.country_id);
      let organizationUsers = organizationOptions
        .find((o) => o.id === value)
        .users.filter((u) => u.role_id === 2 && u.status !== "deleted");
      let organizationCountries = [
        ...new Set(organizationUsers.map((u) => u.country_id)),
      ].filter((c) => participatingCountries.includes(c));
      setCountryOptions(
        allCountryOptions.filter((c) => organizationCountries.includes(c.id))
      );
    }
  };
  const onChangeCountry = (value) => {
    setCountry(value);
    setSchoolOptions([]);
    setTuitionCentreOptions([]);
    if (school !== -1) setSchool("");
    setTuitionCentre("");
    if (value !== "") {
      getSchools(`?country_id=${value}&status=active&limits=0`)
        .then((s) => {
          let allSchools = s.SchoolLists.data
            .filter((d) => !d.private)
            .map((d) => ({ id: d.id, name: d.name }));
          let allCentres = s.SchoolLists.data
            .filter((d) => d.private)
            .map((d) => ({ id: d.id, name: d.name }));
          setAllSchoolOptions(allSchools);
          setAllTuitionCentreOptions(allCentres);
          setSchoolOptions(allSchools);
          setTuitionCentreOptions(allCentres);
        })
        .catch((e) => console.log(e));
    }
  };
  const onSubmit = () => {
    let msg = "";
    // if (invalidName(name)) msg += 'Participant name is invalid.<br>'
    if (name === "") msg += "Participant name is invalid.<br>";
    if (competition === "") msg += "Please select a competition.<br>";
    if (grade === "") msg += "Please select a grade.<br>";
    if (isAdmin()) {
      if (organization === "") msg += "Please select a organization.<br>";
      if (country === "") msg += "Please select a country.<br>";
    }
    if (isAdminOrPartnerOrAssistant()) {
      if (type === "") msg += "Participant type is invalid.<br>";
      if (type !== "" && school === "") msg += "Please select a school.<br>";
      //   if (type === -1 && tuitionCentre === "")
      //     msg += `Please select a tuition centre if participant is Private Candidate.<br>`;
      if (type === 0 && school === -1) {
        msg +=
          "Please select an actual school(Meaning not Home School) if participant is a School Candidate.<br>";
      }
    }
    if (msg) return showWarningSwal(msg);
    if (isAdmin()) {
      let selectedCompOrg = competitionOptions
        .find((c) => c.id === competition)
        .competition_organization.find(
          (org) =>
            org.organization_id === organization && org.country_id === country
        );
      console.log(selectedCompOrg);
      if (checkIfCompOrgIsAvailable(selectedCompOrg)) {
        return actuallySubmit();
      }
      Swal.fire({
        title: "Are you sure?",
        html:
          "Selected Organization and Country either has not open for registration or their competition date" +
          " has already passed.<br>Proceed anyway?",
        showDenyButton: true,
        confirmButtonText: "Proceed",
        denyButtonText: `Cancel`,
        customClass: { container: "swalContainer" },
      }).then((result) => {
        if (result.isConfirmed) actuallySubmit();
      });
    } else actuallySubmit();
  };
  const actuallySubmit = () => {
    setLoading(true);

    let payload = {
      name: name.toUpperCase(),
      grade,
      competition_id: competition,
      class: studentClass,
      email: email,
      identifier: identifier,
      for_partner: [1].includes(type) ? 1 : 0,
      //type == 1 means the type is school candidate
      is_private: type === 1 ? 0 : 1,
    };
    if (isAdmin()) {
      payload.organization_id = organization;
      payload.country_id = country;
    }
    if (isAdminOrPartnerOrAssistant()) {
      payload.school_id = ["", -1].includes(school) ? null : school;
      payload.tuition_centre_id = tuitionCentre === "" ? null : tuitionCentre;
    } else {
      // This part need differentiate between tuition centre cher and school cher
      if (isFromTuitionCentre()) {
        payload.school_id = ["", -1].includes(school) ? null : school;
      }
    }
    addParticipant({ participant: [payload] })
      .then((d) => {
        console.log(d, payload);
        showNotification("success", d.message, enqueueSnackbar, closeSnackbar);
        isMounted.current && setLoading(false);
        afterAddingParticipant(cancelFunction, d);
      })
      .catch((e) => console.log(e))
      .finally(() => {
        isMounted.current && setLoading(false);
        setOnAddParticipant(false);
      });
  };
  const cancelFunction = () =>
    cancelAddParticipant ? cancelAddParticipant() : setOnAddParticipant(false);
  const disableSchoolAndTuitionCentre = isAdmin()
    ? country === ""
    : competition === "";
  return (
    <Box className="popUpModal horizontalScrollable" style={boxStyle}>
      <div>
        <NunitoText
          value="ADD NEW PARTICIPANT"
          fontSize={30}
          fontWeight={600}
          color="#5E75C3"
          marginTop={20}
        />
        {isAdminOrPartnerOrAssistant() && !selectedSchool && (
          <Grid container justifyContent="space-evenly">
            <ReusableAutocompleteWithID
              type="withLabel"
              width={550}
              height={60}
              placeholder="Type*"
              state={type}
              setState={onChangeType}
              borderColor="#707070"
              required
              options={typeOptions.map((t) => ({ id: t.id, option: t.name }))}
            />
            <ReusableTextField
              width={550}
              height={60}
              placeholder={"Email"}
              type="withLabel"
              bgColor="#F2F2F2"
              state={email}
              setState={setEmail}
            />
          </Grid>
        )}
        <Grid
          container
          justifyContent="space-evenly"
          alignItems="center"
          style={firstRowStyle}
        >
          <ReusableTextField
            type="withLabel"
            width={550}
            height={60}
            bgColor="#F2F2F2"
            placeholder="Name*"
            state={name}
            setState={setName}
            required
          />
          <ReusableTextField
            type="withLabel"
            width={550}
            height={60}
            bgColor="#F2F2F2"
            placeholder="Class"
            state={studentClass}
            setState={setStudentClass}
          />
        </Grid>
        <Grid
          container
          justifyContent="space-evenly"
          alignItems="center"
          style={rowStyle}
        >
          <ReusableAutocompleteWithID
            type="withLabel"
            width={550}
            placeholder="Competition*"
            state={competition}
            setState={onChangeCompetition}
            borderColor="#707070"
            required
            options={competitionOptions.map((c) => ({
              id: c.id,
              option: c.name,
            }))}
          />
          <ReusableAutocompleteWithID
            type="withLabel"
            width={550}
            placeholder="Grade*"
            state={grade}
            setState={setGrade}
            borderColor="#707070"
            required
            options={gradeOptions.map((c) => ({ id: c.id, option: c.name }))}
            grayedOut={!competition}
            readOnly={!competition}
          />
        </Grid>
        {isAdmin() && (
          <Grid
            container
            justifyContent="space-evenly"
            alignItems="center"
            style={rowStyle}
          >
            <ReusableAutocompleteWithID
              type="withLabel"
              width={550}
              placeholder="Organization*"
              state={organization}
              setState={onChangeOrganization}
              borderColor="#707070"
              required
              options={organizationOptions.map((o) => ({
                id: o.id,
                option: o.name,
              }))}
              grayedOut={!competition}
              readOnly={!competition}
            />
            <ReusableAutocompleteWithID
              type="withLabel"
              width={550}
              placeholder="Country*"
              state={country}
              setState={onChangeCountry}
              borderColor="#707070"
              required
              options={countryOptions.map((c) => ({
                id: c.id,
                option: c.display_name,
              }))}
              grayedOut={!organization || selectedSchool}
              readOnly={!organization || selectedSchool}
            />
            {/* <ReusableAutocompleteWithID type='withLabel' width={550}
                        placeholder='Partner*' state={partner} setState={setPartner} 
                        borderColor='#707070' required options={partnerOptions.map(u => ({ id: u.id, option: u.name }))}
                        grayedOut={!country || (selectedSchool && !competition)} readOnly={!country || (selectedSchool && !competition)} /> */}
          </Grid>
        )}
        {
          <Grid
            container
            justifyContent="space-evenly"
            alignItems="center"
            style={rowStyle}
          >
            <ReusableTextField
              type="withLabel"
              width={550}
              height={60}
              bgColor="#F2F2F2"
              placeholder="identifier"
              state={identifier}
              setState={setIdentifier}
            />
          </Grid>
        }
        {isAdminOrPartnerOrAssistant() && [0, -1, 1].includes(type) && (
          <Grid
            container
            justifyContent="space-evenly"
            alignItems="center"
            style={rowStyle}
          >
            <ReusableAutocompleteWithID
              type="withLabel"
              width={550}
              placeholder="School*"
              state={school}
              setState={setSchool}
              borderColor="#707070"
              required
              options={actualSchoolOptions.map((c) => ({
                id: c.id,
                option: c.name,
              }))}
              grayedOut={
                disableSchoolAndTuitionCentre ||
                (selectedSchool && !selectedSchool.private)
              }
              readOnly={
                disableSchoolAndTuitionCentre ||
                (selectedSchool && !selectedSchool.private)
              }
            />
            <ReusableAutocompleteWithID
              type="withLabel"
              width={550}
              placeholder="Tuition Centre*"
              state={tuitionCentre}
              setState={setTuitionCentre}
              borderColor="#707070"
              required
              options={tuitionCentreOptions.map((c) => ({
                id: c.id,
                option: c.name,
              }))}
              grayedOut={disableSchoolAndTuitionCentre || selectedSchool}
              readOnly={disableSchoolAndTuitionCentre || selectedSchool}
              hide={[0, 1].includes(type)}
            />
            {/* hide={!Boolean(!selectedSchool || selectedSchool.private) || type !== -1} */}
          </Grid>
        )}
        {Boolean(isManagerOrTeacher() && isFromTuitionCentre()) && (
          <Grid container alignItems="center" style={lastRowStyle}>
            <ReusableAutocompleteWithID
              type="withLabel"
              width={550}
              placeholder="School"
              state={school}
              setState={setSchool}
              borderColor="#707070"
              required
              options={[{ id: -1, name: "Home School" }, ...schoolOptions].map(
                (c) => ({ id: c.id, option: c.name })
              )}
            />
          </Grid>
        )}
        {/* <NunitoText value={`*This participant is a ${isPrivateCandidate ? 'private' : 'school'} candidate`}
                    fontSize={20} fontWeight={400} /> */}
      </div>
      <Grid
        container
        justifyContent="space-evenly"
        style={buttonsContainerStyle}
      >
        <ReusableButton
          text="Cancel"
          fontSize={22}
          height={60}
          width={300}
          bgColor="#8D8D8D"
          onClick={() => cancelFunction()}
        />
        <ReusableButton
          text="Submit"
          fontSize={22}
          height={60}
          width={300}
          bgColor="#5E75C3"
          onClick={() => onSubmit()}
        />
      </Grid>
      {/* {JSON.stringify([{
                name, class: studentClass, grade, competition,
                country, partner, school, tuitionCentre
            }])} */}
    </Box>
  );
}
