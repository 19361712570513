import React, { useState, useEffect, useMemo, useRef } from 'react'
import { Box, Grid, Collapse, Modal } from '@mui/material'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import ViewOption from '../components/ViewOption'
import Filter from '../components/Filter'
import HeavyTable from '../components/table/HeavyTable'
import { useSelector } from 'react-redux'
import {
    makeSureIsArray, makeOptions, checkIfAllSelectedRowsMatchStatus, checkIfAnySelectedRowIs, resetAll,
    changeViewOptions, changeRowsPerPage, changeFiltered, changeSearch, loadData, controlView, controlFilter, cancelAction
} from '../functions/general'
import { getEditRound, getSchools } from '../functions/getData'
import { useLocation } from 'react-router-dom'
import '../css/status.css'
import { showNotification } from '../functions/snackbar'
import NunitoText from '../components/general/NunitoText'
import ReusableTextField from '../components/general/ReusableTextField'
import ReusableButton from '../components/general/ReusableButton'
import ViewAndFilterButton from '../components/ViewAndFilterButton'
import LoadingBackdrop from '../components/general/LoadingBackdrop'
import NoData from '../components/general/NoData'
import Loader from '../components/general/Loader'
import { useSnackbar } from 'notistack'
import { isAdmin, isAdminOrPartner, isPartnerOrAssistant, isAdminOrPartnerOrAssistant } from '../functions/checkrole'
import Widget from '../components/general/Widget'
import EnhancedPagination from '../components/table/EnhancedPagination'
import { Save } from '@mui/icons-material'
import { patchEditMarkRound } from '../functions/patchData'
const schoolHeader = () => ([
    { id: 'index_no', label: 'Index' },
    isAdmin() && { id: 'name', label: 'Name' },
    { id: 'country', label: 'Contry' },
    { id: 'school', label: 'School/Tuition Center' },
    isAdminOrPartner() && { id: 'grade', label: 'Grade' },
    { id: 'points', label: 'Points' },
    {id:'percentile' , label:'Percentile'},
    { id: 'reference_award', label: 'reference award' },
    { id: 'assigned_award_id', label: 'Award Given' },
   
].filter(Boolean))
const initialParams = '?limits=10'

export default function EditRoundMarking() {
    document.title = 'Moderate Results'
    const isMounted = useRef(false)
    const user = useSelector(state => state.user)
    const [header, setHeader] = useState(schoolHeader())
    const [awardType , setAwardType] = useState('')
    const [errorMsg , setErrorMsg] = useState('')
    const [pts , setPts] = useState(undefined)
    const [max , setMax] = useState(undefined)
    const [schoolTable, setSchoolTable] = useState()
    const [rewards , setRewards] = useState([])
    const [filtered, setFiltered] = useState([
        { label: 'Country', state: '', key: 'status', header: 'status' },
        { label: "School/Tuition", state: '', key: 'private', header: 'schooltype' },
        isAdmin() && { label: 'Reference Award', state: '', key: 'country_id', header: 'countries' },
        { label: 'Award Given', state: '', key: 'status', header: 'status' }
    ].filter(Boolean))
    const [filterOptions, setFilterOptions] = useState()
    const [viewOptions, setViewOptions] = useState([
        { label: "Country", state: true, key: 'country' },
        { label: "School/Tuition Center", state: true, key: 'school' },
        { label: "Grade", state: true, key: 'grade' },
        { label: "Reference Points", state: true, key: 'points' },
        { label: "Reference Award", state: true, key: 'reference_award' },
        { label: 'Percentile', state: true, key: 'approved_by_username' },
    
    ])
    const [rowsPerPage, setRowsPerPage] = useState(20)
    const [loading, setLoading] = useState(false)
    const [loadingTable, setLoadingTable] = useState(false)
    const [firstLoaded, setFirstLoaded] = useState(false)
    const [viewing, setViewing] = useState(false)
    const [filtering, setFiltering] = useState(false)
    const [allData , setAllData] = useState([])
    const [id, setId] = useState('')
    const [selected, setSelected] = useState([])
    const [perfectScorer , setPerfectScorer] = useState(0)
    const [selecting, setSelecting] = useState([])
    const { enqueueSnackbar, closeSnackbar } = useSnackbar()
    let location = useLocation()
    const controller = useMemo(() => new AbortController(), [])
    const signal = controller.signal
   
    const onChangeViewOptions = value => changeViewOptions(schoolHeader(), setViewOptions, setHeader, value)
    const onChangeRowsPerPage = value => {
        // changeRowsPerPage(value, rowsPerPage, filtered, search, signal, setRowsPerPage, setParams, getData)
    }
    const onChangeFiltered = value => {
        // changeFiltered(value, filtered, search, rowsPerPage, signal, setFiltered, setParams, getData)
    }
    const getAwardId=(name)=>{
        let id = allData.header_data.awards.filter((el)=>{
            return el===name
        })
        return name
    }
    const reset = ()=>{
        if(allData.data.length>0){
        let newresult = []
       
        allData.data.map((el)=>{
            let obj = {
                index_no: el.participant_index,
                private:el.private,
                name:el.participant.name,
                country:el.participant.country.name,
                school:el.participant.school.name,
                grade: el.participant.grade,
                points: el.points,
                percentile:el.percentile,
                reference_award: el.ref_award,
                assigned_award_id: el.ref_award!==null?el.ref_award:el.award
            }
            newresult.push(obj)
        }) 
        const sampleData = {
            competition_name:allData.header_data.competition,
            level : allData.header_data.level,
            competition_Awards:rewards,
            current_page : 1,
            data:newresult,
            first_page_url: "https://api.debug.simccms.org/api/competition?page=1",
            from: 1,
            last_page:1,
            last_page_url: "https://api.debug.simccms.org/api/competition?page=1",
            links:[],
            next_page_url: null,           
            path: "https://api.debug.simccms.org/api/competition",           
            per_page: "10",
            prev_page_url: null,            
            to: 5,
            total: 5
        }
        setSchoolTable(sampleData)}
    }
    const assingAward = (points ,rewards)=>{
        let award = '' 
       
        rewards.map((el,index)=>{
           
            if(points>=Number(el.cutoff.min) && points<=Number(el.cutoff.max)){
                award = el.name
            }
    })
    
        return award
    }
    const onChangePage = pageNo => {}

   const getRewardCount = (reward)=>{
    let rewardCount = 0;
        schoolTable.data.map(el=>{
            if(el.assigned_award_id===reward){
                rewardCount++
            }
        })
        return rewardCount
   }
    const filterRowsOnEdit =(reward)=>{
      
        if(allData.data.length>0){  
            let results = []
            let perfect=0;
            allData.data.map((el)=>{
                if(el.ref_award==='PERFECT SCORER'){
                    perfect++
                }
                const obj = {
                    index_no: el.participant_index,
                    private:el.private,
                    name:el.participant.name,
                    country:el.participant.country.name,
                    school:el.participant.school?el.participant.school.name:'-',
                    grade: el.participant.grade,
                    points: el.points,
                    percentile:el.percentile,
                    reference_award: el.ref_award,
                    assigned_award_id:schoolTable===undefined?el.award:el.award==='PERFECT SCORE'?el.award:getAwardId(assingAward(el.points , reward))
                }
               setPerfectScorer(perfect)
                results.push(obj)
           
            })
           
                const sampleData = {
                    competition_name:allData.header_data.competition,
                    level : allData.Level,
                    competition_Awards:reward,
                    current_page : 2,
                    data:results,
                    first_page_url: "https://api.debug.simccms.org/api/competition?page=1",
                    from: 1,
                    last_page:25,
                    last_page_url: "https://api.debug.simccms.org/api/competition?page=1",
                    links:[],
                    next_page_url: null,           
                    path: "https://api.debug.simccms.org/api/competition",           
                    per_page: "10",
                    prev_page_url: null,            
                    to: 5,
                    total: 5
                }
               setSchoolTable(sampleData)
           
            
        }    
    }

    const getmax = (reward)=>{
        let max = 0;
        schoolTable.data.map(el=>{
            if(el.reference_award===reward){
                if(el.points>max){
                    max = el.points
                }
            }
        })
        return max;
    }
    const getMin = (reward)=>{
        let min = Number.MAX_VALUE;
        schoolTable.data.map(el=>{
            if(el.reference_award===reward){
                if(el.points<min){
                    min = el.points
                }
            }
        })
        return min;
    }
   
    const handleRewards = ()=>{

        const handlecutoff=(el , index)=>{ 
            let currentIndex = rewards.indexOf(rewards.filter(el=>el.name===awardType)[0])
            let afterindex = currentIndex+1
            let beforeindex = currentIndex-1
            let beforeFlag = true
            let afterFlag = true
            let localmin = 0
            let maxlocal = 0
            if(awardType===el.name){
                localmin = pts===undefined||pts===''?getMin(awardType):pts
                maxlocal = max===undefined||max===''?getmax(awardType):max
                
            }else{   
                let i = currentIndex-1
                let j = currentIndex+1
                    while(beforeFlag &&i>0){
                            let tempmax = max===undefined||max===''?getmax(awardType):max
                        if(Number(tempmax)<rewards[i].cutoff.max){
                            beforeindex = i 
                            beforeFlag = false
                        }
                        i--;
                    }
                    while(afterFlag &&j<rewards.length){
                        let temppts =  pts===undefined||pts===''?getMin(awardType):pts
                        console.log(rewards[j])
                        if(Number(temppts)<rewards[j].cutoff.min){
                            afterindex = j
                            beforeFlag = false
                        }
                        j++;
                    }
                    if(index===beforeindex){
                        localmin = max===undefined||max===''?getmax(awardType)+1:Number(max)+1
                        maxlocal = el.cutoff.max
                    }else if(index===afterindex){
                        localmin = el.cutoff.min
                        maxlocal = pts===undefined||pts===''?getMin(awardType)-1:Number(pts)-1
                    }else{
                        localmin = el.cutoff.min
                        maxlocal = el.cutoff.max
                    }

            }
            return {min:localmin , max:maxlocal}
        }
        let reward =[]
        if(awardType===''){
            setErrorMsg('you must select a reward first')
        }else if(pts!==''&&max!==''&&Number(pts)>Number(max)){
            setErrorMsg('min must be lower than or euqal to the max point')
        }else{ 
           
                    rewards.map((el , index)=>{
                        let obj={
                            name:el.name,
                            id:el.id,
                            cutoff:handlecutoff(el , index)
                        }
                        reward.push(obj)
                    })
                    setRewards(reward)
                    showNotification('success', 'success', enqueueSnackbar, closeSnackbar)
                    setErrorMsg(``)
                    filterRowsOnEdit(reward)
                }  
    }
    const Save =()=>{
        let awards = []
        schoolTable.data.map((el,index)=>{
            let obj = {
                participant_index:el.index_no,
                award:el.assigned_award_id   
        }
    if(obj.award!==allData.data[index].award){
          awards.push(obj)
        }
        })
      
       
        patchEditMarkRound(location.search.slice(4),awards).then((data)=>{
                        showNotification('success', data.message, enqueueSnackbar, closeSnackbar)

        })
    }
    console.log(rewards)
    useEffect(() => {
          if(rewards.length>0 ){
            filterRowsOnEdit(rewards)
          }
        }, [allData])
        useEffect(() => {
            const controller = new AbortController()
            const signal = controller.signal
            if (location.search.slice(0, 4) === '?id=') {
                isMounted.current = true
                setLoading(true)
            getEditRound(`/${location.search.slice(4 , location.search.indexOf('&'))}/${location.search.slice(location.search.indexOf('&')+8)}`, signal).then((res)=>{
                let results = res.data
              console.log(res)
              if(res.data.length>0){
               const rewardsarr =makeSureIsArray(res.header_data.cut_off_points)
                let award = []
                res.header_data.awards.map((el,index)=>{
                    let obj =  {
                        id: index,
                        name: el,
                        cutoff : res.header_data.cut_off_points[el]===undefined?rewardsarr[index]!==undefined?{min:rewardsarr[index].max+1 , max:rewardsarr[index].max+2}:{min:-99999 , max:-9999}:res.header_data.cut_off_points[el]
                    }
                    award.push(obj)
                })
                setRewards(award)
                setAllData(res)
                setRowsPerPage(10)}else{
                    setAllData({data:[]})
                    setSchoolTable({ competition_name:res.header_data.competition,
                        level : allData.Level,
                        competition_Awards:[],
                        current_page : 2,
                        data:[],
                        first_page_url: "https://api.debug.simccms.org/api/competition?page=1",
                        from: 1,
                        last_page:25,
                        last_page_url: "https://api.debug.simccms.org/api/competition?page=1",
                        links:[],
                        next_page_url: null,           
                        path: "https://api.debug.simccms.org/api/competition",           
                        per_page: "10",
                        prev_page_url: null,            
                        to: 5,
                        total: 5})
                        setRewards([])
                        setFirstLoaded(true)
                }
            })
            
            setLoading(false)
        }
            return () => {
                controller.abort()
                isMounted.current = false
            }
        }, [controller, signal ])
        const downloadFile = ({ data, fileName, fileType }) => {
            const blob = new Blob([data], { type: fileType })
          
            const a = document.createElement('a')
            a.download = fileName
            a.href = window.URL.createObjectURL(blob)
            const clickEvt = new MouseEvent('click', {
              view: window,
              bubbles: true,
              cancelable: true,
            })
            a.dispatchEvent(clickEvt)
            a.remove()
          }
          const exportToCsv = (e,data,compname) => {
      
            // Headers for each column
            let headers = ['index,participant,country,school,grade,points,award,award_rank']
          
            // Convert users data to a csv
            let usersCsv = data.reduce((acc, user) => {
              const { index,participant,country,school,grade,points,award , award_rank} = user
              acc.push([index,participant,country,school,grade,points,award , award_rank].join(','))
              return acc
            }, [])
          
            downloadFile({
              data: [...headers, ...usersCsv].join('\n'),
              fileName: `${compname}.csv`,
              fileType: 'text/csv',
            })
          }
          
      
      console.log(rewards , pts , max , Number(pts)>=Number(max))
    return (
        <Box className='wrapperBox'>
          
            <Collapse in={viewing}>
                <ViewOption viewOptions={viewOptions} rowsPerPage={10}
                    onChangeRowsPerPage={onChangeRowsPerPage} onChangeViewOptions={onChangeViewOptions} />
            </Collapse>
            <Collapse in={filtering}>
                {filterOptions && <Filter type='collapse' filterOptions={filtered.map(f => makeOptions(filterOptions, f.header))}
                    filtered={filtered} onChangeFiltered={onChangeFiltered} />}
            </Collapse>
           
            {schoolTable&&<Grid className='tableContainer' container>
                <NunitoText value='Moderate Results' fontSize={40} fontWeight={700} italic color='#144A94' />
                <Grid className='firstRowContainer' container justifyContent="space-between">
                <div className='dashboardAndSelfBtnDiv' style={{width:'40%'}}>
                    <div style={{display:'flex' , 'justifyContent':'space-between' , width:'100%' }}>
                      {allData.header_data&&<> <Widget content={`${allData.header_data.competition}`} title='Competition'></Widget>
                        <Widget content={`${allData.header_data.round.slice(5)}`} title='Round'></Widget>
                        <Widget content={`${allData.header_data.level}`} title='Level'></Widget>
                        <Widget content={`${allData.header_data.award_type}`} title = 'Award Type'></Widget></>}
                    </div>
                    {/* <ReusableButton text='Dashboard' fontSize={14} bgColor='#F16774' height={36} width={125} br={18} to='/dashboard' iconType='home' />
                    <ChevronRightIcon />
                    <ReusableButton text='Schools' fontSize={14} bgColor='#F16774' height={36} br={18} /> */}
                </div>
                {/* <div className='viewAndFilterBtnDiv'>
                    <ViewAndFilterButton text='View Options' state={viewing} fontSize={14} height={42}
                        onClick={handleView} marginRight={10} />
                    <ViewAndFilterButton text='Filter' state={filtering} fontSize={14} height={42}
                        onClick={handleFilter} />
                </div> */}
            </Grid>
            {schoolTable.data.length>0&&<Grid className='status'>
                <div>
                    <table style={{'backgroundColor':'white' , 'box-shadow':'rgb(158 158 158) 0px 0px 5px'  , 'padding':'20px' ,textAlign:'center' , 'border-collapse':'collapse'}}>
                        <tr>
                            <th >participants</th>
                            {perfectScorer>0&&<th>Perfect Scorer</th>}
                            {rewards.map((el)=>{
                                return <th >{el.name==='HONORABLE MENTION'?'HM':el.name==='CERTIFICATE OF PARTICIPATION'?'TS':el.name==='PERFECT SCORER'?'PS':el.name}</th>
                            })}
                        </tr>
                        <tr>
                           
                            {schoolTable&&<><td >{schoolTable.data.length}</td>
                            {perfectScorer>0&&<td>{perfectScorer}</td>}
                            {rewards.map(el=>{
                                return <td >{getRewardCount(el.name)}</td>
                            })}
                            </>
                            }
                        </tr>
                        <tr>
                            <td>100%</td>
                            {perfectScorer>0&&<td>{((perfectScorer/schoolTable.data.length)*100).toFixed(2)}%</td>}
                            {schoolTable&&<>
                            {rewards.map(el=>{
                                return <td >{((getRewardCount(el.name)/schoolTable.data.length)*100).toFixed(2)}%</td>
                            })}
                            </>
                            }
                        </tr>
                    </table>
                </div>
            </Grid>}
               {schoolTable.data.length>0&& <Grid className='searchAndBtnContainer' container  justifyContent="space-between" alignItems="flex-end">
                
                   <div style={{'backgroundColor':'white' , 'box-shadow':'rgb(158 158 158) 0px 0px 5px' , 'width':'25%','borderRadius':'18px' , 'padding':'5px','display':'flex'
                ,'flexDirection':'column',alignItems:'center'
                }}>
                    <p style={{'fontSize':'20px'}}>Edit Range</p>
                    <div style={{'display':'flex' , 'justifyContent':'space-between' , 'width':'80%','height':'48px','marginBottom':'2%' , 'alignItems':'center'}}>
                        <select style={{'width':'60%','borderRadius':'10px', marginRight:'2%' , paddingTop:'3%' , paddingBottom:'3%'}}  onChange={(e)=>{
                            
                            setAwardType(e.target.value)
                            setMax('')
                            setPts('')
                            }}>
                        <option value="" selected disabled hidden>awards</option>

                            {schoolTable&&rewards.map(el=>{
                                return <option value={el.name}>{el.name}</option>
                            })}
                        </select>
                        <input value={`${pts}`} placeholder={'Min'} disabled={rewards.length>0?awardType===rewards[rewards.length-1].name:true} onChange={(e)=>{setPts(e.target.value)
                      
                        }} type='number' style={{'width':'30%','borderRadius':'10px', 'border':'1px solid','textAlign':'center' , paddingTop:'3%' , paddingBottom:'3%'}}></input>
                        <h5 style={{'textAlign':'center' , marginLeft:'1%' , marginRight:'1%'}}>To</h5>
                         <input placeholder={'Max'} disabled={rewards.length>0?awardType===rewards[0].name:true} value={`${max}`} onChange={(e)=>{setMax(e.target.value)
                      
                    }} type='number' style={{'width':'30%','borderRadius':'10px', 'border':'1px solid','textAlign':'center' , paddingTop:'3%' , paddingBottom:'3%'}}></input>
                    </div>
                    <div style={{'display':'flex' , 'justifyContent':'space-between', 'width':'90%','alignItems':'center'}}>
                        <p style={{'fontSize':'15px', 'color':'#F3797E'}}>{errorMsg}</p>
                        <button style={{'height':'30px','backgroundColor':'#B5C3FC','color':'white','border':'1px solid','borderRadius':'10px'}} onClick={()=>{
                                handleRewards()
                         
                            
                        }}>confirm edit</button>
                    </div>
                   </div>
                    {isAdminOrPartnerOrAssistant() && <div className='endBtnDiv'>
                    <ReusableButton text='Export Csv' fontSize={15} bgColor='#5E75C3' height={46} onClick={(e)=>{
                        getEditRound(`/${location.search.slice(4 , location.search.indexOf('&'))}/${location.search.slice(location.search.indexOf('&')+8)}?is_for_report=1`, signal).then(res=>{
                            exportToCsv(e  , res, schoolTable.competition_name)
                        })
                        }}
                            marginRight={20}  iconType='export' />
                    <ReusableButton text='Reset Default' fontSize={15} bgColor='#E83042' height={46} onClick={()=>{reset()}}
                            marginRight={20}  iconType='reset' />
                        {/* <ReusableButton text='Remark' fontSize={15} bgColor='#5E75C3' height={46}
                            marginRight={20}  iconType='add' /> */}
                        {isAdmin() && <ReusableButton text='save' fontSize={15} bgColor='#5E75C3' height={46} onClick={()=>Save()}
                            marginRight={20}  iconType='save' />}
                       
                    </div>}
                </Grid>}
                {/* <NunitoText value='Search only in "School/Tuition", "Address", "Postal Code", "Province" and "Phone Number" column'
                    fontSize={20} fontWeight={400} italic color='#144A94' /> */}
                {loadingTable && <Loader height={600} />}
                {!loadingTable && schoolTable && makeSureIsArray(schoolTable.data).length>0 &&
                   <> <HeavyTable headers={header} list={schoolTable}  rowsPerPage={10}
                        onChangePage={onChangePage} fixed={['index_no', 'name']}
                        selecting={selecting} setSelecting={setSelecting} selected={selected} setSelected={setSelected}
                        setId={setId}  title="editround" 
                        
                    />
                    <EnhancedPagination list={schoolTable} rowsPerPage={10} onChangePage={onChangePage} />
                    </>
                    }
                    

                {!loadingTable && firstLoaded && schoolTable && !makeSureIsArray(schoolTable.data).length && <NoData height={600} />}
            </Grid>}
            
        </Box>
    )
}