import React, { useState, useEffect } from "react";
import {
  Table,
  TableRow,
  Grid,
  TableCell,
  TableContainer,
  TableBody,
  Paper,
  IconButton,
  Box,
  Button,
} from "@mui/material";
import { getComparator, stableSort } from "../../functions/table";
import { showNotification } from "../../functions/snackbar";
import EnhancedTableHead from "./EnhancedTableHead";
import useWindowDimensions from "../../hooks/WindowDimensions";
import LightTooltip from "../general/LightTooltip";
import { FaCalculator } from "react-icons/fa";
import { FiEdit } from "react-icons/fi";
import { GrNotes } from "react-icons/gr";
import NunitoText from "../general/NunitoText";
import { useSnackbar } from "notistack";
import { Link } from "react-router-dom";
import { patchMarkingGroupOverview } from "../../functions/patchData";
import VerfiyCollectionModal from "../modal/VerfiyCollectionModal";
import ConfirmVerfiy from "../modal/ConfrimVerfiy";
import PatchMarkingOptionsModal from "../modal/PatchMarkingOptionsModal";
import ViewLogsModal from "../modal/ViewLogsModal";

const containerStyle = (width) => ({
  marginBlock: "2%",
  width: 0.89 * width,
  // position: "relative",
});
const paperStyle = (width) => ({
  boxShadow: "0 0 3px #9E9E9E",
  minWidth: 400,
  maxWidth: 3200,
  width: 0.89 * width,
});
const divStyle = {
  whiteSpace: "nowrap",
  textOverflow: "ellipsis",
  display: "block",
  overflow: "hidden",
  minWidth: 150,
  maxWidth: 300,
};
const progressDivStyle = {
  whiteSpace: "nowrap",
  textOverflow: "ellipsis",
  display: "block",
  overflow: "hidden",
  width: 500,
};
const ProgressBar = ({ percentage }) => (
  <div
    style={{
      height: 40,
      width: "78%",
      borderRadius: 6,
      background: `linear-gradient(90deg, #A9ABD3 ${percentage}%, #D7D7D7 0%)`,
    }}
  />
);
const centerFlexStyle = { display: "flex", alignItems: "center" };
const iconButtonStyle = { marginLeft: 20, color: "#000" };
const iconStyle = { fontSize: 20 };
const CountryAndAbsentees = ({ title, data }) => {
  return (
    Array.isArray(data) && (
      <div style={{ padding: 10 }}>
        <NunitoText
          value={`${title.charAt(0).toUpperCase()}${title.slice(1)}`}
          fontSize={20}
          fontWeight={600}
        />
        {data.map((d, i) => (
          <NunitoText key={i} value={d} fontSize={18} fontWeight={500} />
        ))}
      </div>
    )
  );
};
const Cell = ({
  row,
  getter,
  color,
  widths,
  fixed,
  setloading,
  setFinished,
  setCounter,
  counter,
  setCanbeComputed,
}) => {
  const [pressed, setPressed] = useState(false);
  const [displayOptionsModal, setDisplayOptionsModal] = useState(false);
  const [displayLogsModal, setDisplayLogsModal] = useState(false);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const tableCellStyle = {
    paddingLeft: 15,
    backgroundColor: color,
    backgroundClip: "padding-box",
  };
  const fixedTableCellStyle = (getter) => {
    let index = widths.findIndex((w) => w.id === getter);
    let w = widths[index - 1]?.totalWidth || 0;
    return { ...tableCellStyle, position: "sticky", left: w, zIndex: 999 };
  };
  let output = row[getter] || "-";
  let hoverData = row[getter];
  if (
    row.computing_status !== "Not Started" &&
    row.computing_status !== "Finished"
  ) {
    setCanbeComputed(false);
  }
  if (Array.isArray(row[getter])) {
    if (getter === "countries") {
      output = row[getter].join();
    }
    if (getter === "absentees") {
      hoverData = row.absentees.join(",");
      output = row.absenteesCount;
    }
  } else {
    if (getter === "progress") {
      const compute = (checks) => {
        setPressed(true);
        showNotification(
          "success",
          "calculating ...",
          enqueueSnackbar,
          closeSnackbar
        );
        const payload = {
          not_to_compute: [
            ...checks
              .map((item) => {
                if (!item.checked) {
                  return item.name;
                }
                return null;
              })
              .filter((item) => {
                return !!item;
              }),
          ],
        };
        setFinished(false);
        patchMarkingGroupOverview(row.id, row.marking_group_id, payload)
          .then((res) => {
            setCanbeComputed(false);
            setCounter(!counter);
            if (res.error) {
              showNotification(
                "warning",
                res.error,
                enqueueSnackbar,
                closeSnackbar
              );
            } else {
              showNotification(
                "success",
                res.message,
                enqueueSnackbar,
                closeSnackbar,
                setDisplayOptionsModal(false)
              );
            }
          })
          .catch((err) => {});
      };
      output = (
        <div style={centerFlexStyle}>
          <ProgressBar percentage={row.progress} />
          {!pressed &&
            row.level_is_ready_to_compute &&
            row.levelReady &&
            row.isactive &&
            (row.computing_status === "Not Started" ||
              row.computing_status === "Finished") && (
              <LightTooltip
                title="Compute"
                type="Button"
                rollover={0}
                topic={0}
                hide={0}
              >
                {/* <IconButton style={iconButtonStyle} onClick={() => compute()}> */}
                <IconButton
                  style={iconButtonStyle}
                  onClick={() => setDisplayOptionsModal(true)}
                >
                  {<FaCalculator style={iconStyle} />}
                </IconButton>
              </LightTooltip>
            )}
          {row.computing_status === "Finished" && (
            <LightTooltip
              title="moderate"
              type="Button"
              rollover={0}
              topic={0}
              hide={0}
            >
              <IconButton style={iconButtonStyle}>
                <Link
                  to={`editRoundMarking?id=${row.id}&&group=${row.marking_group_id}`}
                >
                  {<FiEdit style={iconStyle} />}
                </Link>
              </IconButton>
            </LightTooltip>
          )}
          {Object.keys(row.logs).length > 0 && (
            <LightTooltip
              title="Logs"
              type="Button"
              rollover={0}
              topic={0}
              hide={0}
            >
              <IconButton
                style={iconButtonStyle}
                onClick={() => setDisplayLogsModal(true)}
              >
                {<GrNotes style={iconStyle} />}
              </IconButton>
            </LightTooltip>
          )}
          {displayOptionsModal && (
            <PatchMarkingOptionsModal
              confirmFunction={compute}
              cancelConfirm={() => {
                setDisplayOptionsModal(false);
              }}
            />
          )}
          {displayLogsModal && (
            <ViewLogsModal
              handleClose={() => setDisplayLogsModal(false)}
              logs={row.logs}
              countries={row.countries}
              levelName={row.level}
              // headers={}
              // data={}
            />
          )}
        </div>
      );
    }
    if (getter === "marked") {
      output = `${row.marked === undefined ? 0 : row.marked}/${
        row.uploaded === undefined ? 0 : row.uploaded
      }`;
    } else if (getter === "total") {
      output = row.total_attended_participants;
    }
  }
  let owo = (
    <div style={getter === "progress" ? progressDivStyle : divStyle}>
      {output}
    </div>
  );
  return (
    <LightTooltip
      title={CountryAndAbsentees({ title: getter, data: row.absentees })}
      rollover={typeof hoverData === "string" ? 1 : 0}
      topic={0}
      hide={!["absentees"].includes(getter) ? 1 : 0}
    >
      <TableCell
        align="left"
        component="th"
        scope="row"
        style={
          fixed.includes(getter) ? fixedTableCellStyle(getter) : tableCellStyle
        }
      >
        {owo}
      </TableCell>
    </LightTooltip>
  );
};
const Row = ({
  count,
  row,
  getters,
  widths,
  fixed,
  setloading,
  setFinished,
  setCounter,
  counter,
  setCanbeComputed,
}) => {
  const [color, setColor] = useState("#FFF");
  const tableRowStyle = {
    height: 80,
    overFlow: "auto",
  };
  return (
    <TableRow
      style={tableRowStyle}
      onMouseEnter={() => setColor("#F6F6F6")}
      onMouseLeave={() => setColor("#FFF")}
    >
      {getters.map((getter, i) => (
        <Cell
          setCanbeComputed={setCanbeComputed}
          counter={counter}
          key={`${getter}-${i}`}
          row={row}
          getter={getter}
          color={color}
          widths={widths}
          fixed={fixed}
          setloading={setloading}
          setFinished={setFinished}
          setCounter={setCounter}
        />
      ))}
    </TableRow>
  );
};
export default function MarkingOverviewTable({
  headers,
  data,
  fixed,
  setloading,
  setFinished,
  setCounter,
  counter,
  setCanbeComputed,
  loadingData
}) {
  const getters = headers.map((h) => h.id);
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("id");
  const [widths, setWidths] = useState(
    headers.map((h) => ({ id: h.id, width: 0, totalWidth: 0 }))
  );
  const handleRequestSort = (property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };
  const { width } = useWindowDimensions();
  return (
    <Grid container alignItems="flex-start" style={containerStyle(width)}>
      <Paper style={paperStyle(width)}>
        <TableContainer>
       { !loadingData&& <Table size="small">
            <EnhancedTableHead
              handleRequestSort={handleRequestSort}
              headers={headers}
              widths={widths}
              setWidths={setWidths}
              fixed={fixed}
            />
            <TableBody>
              {stableSort(data, getComparator(order, orderBy)).map((row, i) => (
               <Row
                  key={i}
                  count={data.findIndex((d) => d.id === row.id)}
                  row={row}
                  getters={getters}
                  setFinished={setFinished}
                  setCanbeComputed={setCanbeComputed}
                  widths={widths}
                  fixed={fixed}
                  setloading={setloading}
                  setCounter={setCounter}
                  counter={counter}
                />
              ))}
            </TableBody>
          </Table>}
        </TableContainer>
      </Paper>
    </Grid>
  );
}
