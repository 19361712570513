import React, { useState } from "react";
import { Grid, Modal } from "@mui/material";
import { invalidName } from "../../functions/general";
import { isEmpty } from "../../functions/alert";
import { getBase64 } from "../../functions/upload";
import { getTasks } from "../../functions/getData";
import { patchTaskSettings } from "../../functions/patchData";
import { showWarningSwal } from "../../functions/alert";
import { afterEdit, isUnchanged, populateTask } from "../../functions/edit";
import { showNotification } from "../../functions/snackbar";
import TitleBar from "../general/TitleBar";
import AddPageWrapper from "../general/AddPageWrapper";
import Solution from "./Solution";
import Recommendations from "./Recommendations";
import Contents from "./Contents";
import AnswerSettings from "./AnswerSettings";
import NunitoText from "../general/NunitoText";
import InputContainer from "../general/InputContainer";
import ReusableTextField from "../general/ReusableTextField";
import ReusableAutocompleteWithID from "../general/ReusableAutocompleteWithID";
import ReusableButton from "../general/ReusableButton";
import DescriptionEditor from "../DescriptionEditor";
import Loader from "../general/Loader";
import { useSnackbar } from "notistack";
import ConfirmVerfiy from "../modal/ConfrimVerfiy";
import RemoveVerify from "../modal/RemoveVerify";
import { verfiyTask, removeVerifyTask } from "../../functions/postData";
import { isAdmin, isSuperAdmin } from "../../functions/checkrole";
const taskContainerStyle = {
  backgroundColor: "#F3F0F4",
  borderRadius: "0 0 12px 12px",
};
const centerFlexStyle = {
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
};
const imageContainerStyle = { marginInline: 36 };
const imageStyle = { maxHeight: 600, maxWidth: 1500 };
const buttonContainerStyle = { marginRight: 20, marginBottom: 20 };
export default function Task({
  original,
  setOriginal,
  task,
  setTask,
  allTopicOptions,
  tagOptions,
  editAnswers,
  IsReadOnly,
  isVerfiy,
}) {
  const [expandTask, setExpandTask] = useState(true);
  const [expandSetting, setExpandSetting] = useState(true);
  const [confirmVerfiy, setConfirmVerfiy] = useState(false);
  const [removeVerfiy, setRemoveVerify] = useState(false);
  const [loading, setLoading] = useState(false);
  const handleFileInputChange = (file) => {
    updateTask("imgName", file.name);
    getBase64(file)
      .then((result) => updateTask("image", result))
      .catch((err) => {
        console.log(err);
        alert(`Error at handeFileInputChange: ${JSON.stringify(err)}`);
      });
  };
  const {
    id,
    title,
    identifier,
    domains,
    tags,
    description,
    imgName,
    image,
    solutions,
    languages,
  } = task;
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const updateTask = (identifier, value) => {
    let newTask = window.structuredClone(task);
    newTask[identifier] = value;
    if (identifier === "title")
      newTask.identifier = value.toLowerCase().trim().replace(/\s/g, "_");
    setTask(newTask);
  };
  const onSubmit = () => {
    const settingsKeyArr = [
      "title",
      "identifier",
      "domains",
      "tags",
      "description",
      "image",
      "solutions",
    ];
    const originalSettings = settingsKeyArr.reduce(
      (obj, header) => ({ ...obj, [header]: original[header] }),
      {}
    );
    const taskSettings = settingsKeyArr.reduce(
      (obj, header) => ({ ...obj, [header]: task[header] }),
      {}
    );
    if (isUnchanged(originalSettings, taskSettings))
      return showWarningSwal("Please make some changes before submitting");
    let warningMessage = "";
    if (invalidName(title)) warningMessage += "Invalid Task Title. ";
    if (invalidName(identifier)) warningMessage += "Invalid Task Identifier. ";
    if (isEmpty([languages]))
      warningMessage += "Task Content cannot be empty. ";
    if (warningMessage) return showWarningSwal(warningMessage);
    let payload = {
      id,
      title,
      identifier,
      tag_id: [domains, tags].flat(),
      description,
      solutions,
      image,
    };
    console.log(JSON.stringify(payload));
    setLoading(true);
    patchTaskSettings(payload)
      .then((d) => {
        if (d.status === 200)
          showNotification(
            "success",
            d.message,
            enqueueSnackbar,
            closeSnackbar
          );
      })
      .catch((e) => console.log(e))
      .finally(() => {
        afterEdit(getTasks, populateTask, setOriginal, task, setTask);
        setLoading(false);
      });
  };
  const vefiy = () => {
    verfiyTask(id)
      .then((res) => {
        if (res.status === 200 || res.status === 201) {
          showNotification(
            "success",
            res.message,
            enqueueSnackbar,
            closeSnackbar
          );
        } else {
          showWarningSwal(res.message);
        }
      })
      .catch((e) => console.log(e))
      .finally(() => {
        setLoading(false);
        afterEdit(getTasks, populateTask, setOriginal, task, setTask);
        setConfirmVerfiy(false);
      });
  };

  const confirmRemoveVerify = () => {
    removeVerifyTask(id)
      .then((res) => {
        if (res.status === 200 || res.status === 201) {
          showNotification(
            "success",
            res.message,
            enqueueSnackbar,
            closeSnackbar
          );
        } else {
          showWarningSwal(res.message);
        }
      })
      .catch((e) => console.log(e))
      .finally(() => {
        setLoading(false);
        afterEdit(getTasks, populateTask, setOriginal, task, setTask);
        setRemoveVerify(false);
      });
  };
  console.log(original);
  return (
    <Grid container>
      <TitleBar
        title={task.title}
        state={expandTask}
        setState={setExpandTask}
        main
      />
      {expandTask && (
        <Grid container style={taskContainerStyle}>
          {loading ? (
            <Loader height={600} />
          ) : (
            <>
              <TitleBar
                title="Setting"
                state={expandSetting}
                setState={setExpandSetting}
              />
              {expandSetting && (
                <AddPageWrapper>
                  <InputContainer
                    first
                    label="Task Title*"
                    field={
                      <ReusableTextField
                        type="default"
                        placeholder="Task Title"
                        state={title}
                        setState={updateTask}
                        disabled={IsReadOnly}
                        grayedOut={IsReadOnly}
                        target="title"
                        borderColor="#000"
                        required
                        onBlur
                        fullWidth
                      />
                    }
                  />
                  <InputContainer
                    label="Task Identifier*"
                    field={
                      <ReusableTextField
                        type="default"
                        placeholder="Task Identifier"
                        state={identifier}
                        disabled={IsReadOnly}
                        grayedOut={IsReadOnly}
                        setState={updateTask}
                        target="identifier"
                        borderColor="#000"
                        required
                        onBlur
                        fullWidth
                      />
                    }
                  />
                  <InputContainer
                    label="Domain and Topic"
                    field={
                      <ReusableAutocompleteWithID
                        type="default"
                        placeholder="Domain and Topic"
                        width={610}
                        multiple
                        state={domains}
                        setState={updateTask}
                        target={IsReadOnly ? "" : "domains"}
                        borderColor="#707070"
                        disabled={IsReadOnly}
                        grayedOut={IsReadOnly}
                        nested
                        options={allTopicOptions}
                      />
                    }
                  />
                  <InputContainer
                    label="Tags"
                    field={
                      <ReusableAutocompleteWithID
                        type="default"
                        placeholder="Tags"
                        width={610}
                        multiple
                        disabled={IsReadOnly}
                        grayedOut={IsReadOnly}
                        state={tags}
                        setState={updateTask}
                        target={IsReadOnly ? "" : "tags"}
                        borderColor="#707070"
                        options={tagOptions.map((t) => ({
                          id: t.id,
                          option: t.name,
                        }))}
                      />
                    }
                  />
                  <InputContainer
                    label="Description"
                    field={
                      <DescriptionEditor
                        state={description}
                        setState={updateTask}
                        height={360}
                        disabled={IsReadOnly}
                        grayedOut={IsReadOnly}
                        placeholder="Description"
                        target="description"
                      />
                    }
                  />
                  {!IsReadOnly && (
                    <InputContainer
                      last
                      label="Select Image"
                      field={
                        <ReusableButton
                          text="Browse"
                          bgColor="#5E75C3"
                          width={140}
                          height={50}
                          fontSize={16}
                          onClick={() =>
                            document
                              .getElementById(`raised-button-file`)
                              .click()
                          }
                          iconType="upload"
                        />
                      }
                    />
                  )}
                  <input
                    id={`raised-button-file`}
                    accept="image/*"
                    style={{ display: "none" }}
                    multiple
                    type="file"
                    onChange={(e) => handleFileInputChange(e.target.files[0])}
                  />
                  <Grid
                    container
                    flexDirection="column"
                    style={imageContainerStyle}
                  >
                    <NunitoText
                      value={image !== "" ? imgName : "Test.jpg"}
                      fontSize={20}
                      color="#707070"
                    />
                    {image !== "" ? (
                      <div style={centerFlexStyle}>
                        <img src={image} alt="Task" style={imageStyle} />
                      </div>
                    ) : (
                      <ReusableTextField
                        type="imagePlaceholder"
                        placeholder="Image placeholder"
                        bgColor="#F2F2F2"
                        height={600}
                      />
                    )}
                  </Grid>
                  <Solution
                    solutions={solutions}
                    updateTask={updateTask}
                    IsRead={IsReadOnly}
                  />
                  {!IsReadOnly && (
                    <Grid
                      container
                      justifyContent="flex-end"
                      style={buttonContainerStyle}
                    >
                      <ReusableButton
                        text="Save"
                        bgColor="#5E75C3"
                        width={140}
                        height={50}
                        fontSize={16}
                        onClick={() => onSubmit()}
                      />
                    </Grid>
                  )}
                </AddPageWrapper>
              )}
            </>
          )}
          {/* {JSON.stringify(task)} */}
          <Recommendations
            original={original}
            setOriginal={setOriginal}
            task={task}
            setTask={setTask}
            IsReadOnly={IsReadOnly}
          />
          <Contents
            original={original}
            setOriginal={setOriginal}
            task={task}
            setTask={setTask}
            IsRead={IsReadOnly}
          />
          <AnswerSettings
            original={original}
            setOriginal={setOriginal}
            task={task}
            setTask={setTask}
            editAnswers={editAnswers}
            isread={!isSuperAdmin() && (IsReadOnly || task.is_resricted)}
          />
          {isVerfiy && (
            <Grid
              container
              style={{ margin: " 0 30px 30px 30px" }}
              justifyContent="flex-end"
            >
              <ReusableButton
                text={
                  original.status === "Verified"
                    ? isAdmin()
                      ? "remove verified"
                      : "verified"
                    : "verify"
                }
                bgColor={
                  original.status === "Verified"
                    ? isAdmin()
                      ? "#5E75C3"
                      : "#999"
                    : "#5E75C3"
                }
                fontSize={16}
                marginTop={"1%"}
                onClick={() =>
                  original.status === "Verified"
                    ? isAdmin()
                      ? setRemoveVerify(true)
                      : {}
                    : setConfirmVerfiy(true)
                }
              ></ReusableButton>
            </Grid>
          )}
          <Modal
            open={confirmVerfiy}
            onClose={() => {
              setConfirmVerfiy(false);
            }}
          >
            <ConfirmVerfiy
              confirmFunction={vefiy}
              cancelConfirm={() => setConfirmVerfiy(false)}
              object={"task"}
              value={task}
            ></ConfirmVerfiy>
          </Modal>
          <Modal
            open={removeVerfiy}
            onClose={() => {
              setRemoveVerify(false);
            }}
          >
            <RemoveVerify
              cancelVerify={() => setRemoveVerify(false)}
              id={id}
              confirmUnverify={confirmRemoveVerify}
              task={task}
              type="Task"
            />
          </Modal>
        </Grid>
      )}
    </Grid>
  );
}
