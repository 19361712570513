import React from "react";
import { Box, Grid } from "@mui/material";
import { useSelector } from "react-redux";
import { makeSureIsArray } from "../../functions/general";
import NunitoText from "../general/NunitoText";
import ReusableButton from "../general/ReusableButton";

export default function RemoveVerify({
  cancelVerify,
  confirmUnverify,
  id,
  table,
  task,
  type,
}) {
  const getSinglename = (id) => {
    if (table) {
      let single = makeSureIsArray(table?.data).filter((s) => s.id === id);
      return single[0].identifier;
    }
    if (task) {
      return task.title;
    }
  };
  return (
    <Box className="popUpModal" style={{ width: 705, minHeight: 365 }}>
      <NunitoText
        value="Remove Verify?"
        fontSize={30}
        fontWeight={600}
        color="#5E75C3"
        marginTop={30}
      />
      <NunitoText
        value={`Are you sure you want unverify`}
        // value={`Are you sure you want unverify ${name}`}
        fontSize={20}
        fontWeight={600}
      />
      <NunitoText
        value={type + " " + getSinglename(id)}
        fontSize={20}
        fontWeight={700}
      />
      <Grid
        container
        justifyContent="space-evenly"
        style={{ marginBlock: "30px 20px" }}
      >
        <ReusableButton
          text="Cancel"
          bgColor="#8D8D8D"
          fontSize={25}
          height={63}
          width={280}
          onClick={() => cancelVerify()}
        />
        <ReusableButton
          text="Confirm"
          bgColor="#5E75C3"
          fontSize={25}
          height={63}
          width={280}
          onClick={() => confirmUnverify()}
        />
      </Grid>
    </Box>
  );
}
