import React from "react";
import { Box, Grid } from "@mui/material";
import NunitoText from "../general/NunitoText";
import ReusableButton from "../general/ReusableButton";

export default function ApproveModal({
  approveFunction,
  cancelFunction,
  affectedCompetitions,
}) {
  return (
    <Box className="popUpModal" style={{ width: 705, minHeight: 365 }}>
      <NunitoText
        value="WARNING"
        fontSize={30}
        fontWeight={600}
        color="#5E75C3"
        marginTop={30}
      />
      <NunitoText
        value={`This collection is in use by competitions:`}
        fontSize={21}
        fontWeight={600}
      />
      <div
        style={{ maxHeight: 200, overflowY: "auto", margin: "10px 50px 15px" }}
      >
        {affectedCompetitions?.map(({ id, competition }) => {
          return (
            <li key={id} style={{ fontSize: 17, fontWeight: "500" }}>
              {competition}
            </li>
          );
        })}
      </div>
      <NunitoText
        value={`You need to update task marks for these competition`}
        fontSize={21}
        fontWeight={600}
      />
      <Grid
        container
        justifyContent="space-evenly"
        style={{ marginBlock: "30px 20px" }}
      >
        <ReusableButton
          text="Cancel"
          bgColor="#8D8D8D"
          fontSize={25}
          height={63}
          width={280}
          onClick={() => cancelFunction()}
        />
        <ReusableButton
          text="Confirm"
          bgColor="#5E75C3"
          fontSize={25}
          height={63}
          width={280}
          onClick={() => approveFunction()}
        />
      </Grid>
    </Box>
  );
}
