import React, { useState, useEffect } from "react";
import {
    IconButton, Checkbox, Table, TableRow, TableHead, Grid, TableCell, TableContainer,
    TableBody, Paper, TablePagination
} from '@mui/material'
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp'
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'
import NavigateNextIcon from '@mui/icons-material/NavigateNext'
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore'
import CloseIcon from '@mui/icons-material/Close'
import ReusableTextField from '../general/ReusableTextField'
import ReusableAutocomplete from '../general/ReusableAutocomplete'
import useWindowDimensions from '../../hooks/WindowDimensions'
import LightTooltip from '../general/LightTooltip'
const EnhancedTableHead = ({ order, orderBy, handleRequestSort, headCells }) => {
    // Use position sticky to ensure the first column's position is fixed when the table is scrolled horizontally
    const tableHeadStyle = index => ({
        fontSize: 20, fontWeight: 'bolder', color: '#144A94', cursor: 'pointer', height: 61,
        position: 'sticky', left: 0, zIndex: Boolean(!index) && 999,
        backgroundColor: Boolean(!index) && '#FFF', backgroundClip: 'padding-box'
    })
    const tableCellDivStyle = index => ({
        display: 'flex', alignItems: 'center', width: Boolean(!index) ? 320 : 280
    })
    const arrowWrapperStyle = { display: 'flex', flexDirection: 'column' }
    const arrowStyle = { color: '#000', position: 'relative' }
    return (
        <TableHead>
            <TableRow>
            <TableCell  align='left' padding='normal'
                        sortDirection={ order}
                        style={{
                            fontSize: 20, fontWeight: 'bolder', color: '#144A94', cursor: 'pointer', height: 61,
                            position: 'sticky', left: 0,
                            backgroundColor: Boolean(!0) && '#FFF', backgroundClip: 'padding-box'
                        }}
                     
                    >
                        <div style={{
                             display: 'flex', alignItems: 'center', width: 20
                        }}>
                            <div>{'#'}</div>
                            <div style={arrowWrapperStyle}>
                                <ArrowDropUpIcon style={{ ...arrowStyle, top: 8 }} />
                                <ArrowDropDownIcon style={{ ...arrowStyle, bottom: 8 }} />
                            </div>
                        </div>
                    </TableCell>
                {headCells.map((headCell, index) => (
                    <TableCell key={index} align='left' padding='normal'
                        sortDirection={orderBy === headCell.id ? order : false}
                        style={tableHeadStyle(index)}
                        onClick={e => handleRequestSort(e, headCell.id)}
                    >
                        <div style={tableCellDivStyle(index)}>
                            <div>{headCell.label}</div>
                            <div style={arrowWrapperStyle}>
                                <ArrowDropUpIcon style={{ ...arrowStyle, top: 8 }} />
                                <ArrowDropDownIcon style={{ ...arrowStyle, bottom: 8 }} />
                            </div>
                        </div>
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
}
const ErrorMsg = getter => {
    let msg = ''
    switch (getter) {
        case 'email':
            msg = 'Email format is invalid'
            break;
        case 'name':
            msg = 'Name format is invalid'
            break;
        case 'school':
            msg = 'School name is invalid'
            break;
        case 'tuition_centre':
            msg = 'Tuition centre name not found'
            break;
        case 'grade':
            msg = 'Grade invalid'
            break;
        case 'class':
            msg = 'Class invalid'
            break;
        case 'address':
            msg = 'Address invalid'
            break;
        case 'postal':
            msg = 'Postal code must be numeric for now'
            break;
        case 'phone':
            msg = 'Phone number must be numeric'
            break;
        case 'province':
            msg = 'Province invalid'
            break;
        case 'partner':
            msg = 'Partner name invalid'
            break
        default: break;
    }
    return msg
}
const PaginationButton = ({ isSelected, selecting, setPage }) => {
    return (
        <div style={{
            backgroundColor: isSelected && '#144A94', borderRadius: 32, width: 32,
            marginInline: 8, height: 32, display: 'flex', alignItems: 'center',
            justifyContent: 'center', cursor: 'pointer'
        }} onClick={() => setPage(selecting)}>
            <h4 style={{ color: isSelected ? '#FFF' : '#000', fontWeight: 'normal' }}>{selecting + 1}</h4>
        </div>
    )
}
const Cell = ({ getter, cellCount, color, count, data, row, setState, headers, isSelected, error, isDuplicate,
    tuitionCentreOptions, schoolOptions, gradeOptions, isPrivate }) => {
    let options = []
    let placeholder = `Enter ${headers.find(h => h.id === getter).label}`
    let p = placeholder
    let grayCondition = false
    switch (getter) {
        case 'school':
            options = schoolOptions.map(s => ({ id: s.id, option: s.name }))
            break;
        case 'tuition_centre':
            options = tuitionCentreOptions.map(s => ({ id: s.id, option: s.name }))
            if(row[getter]==='-'||!row[getter]){
                row[getter] = "Home School"
            }
            grayCondition = !isPrivate
            break
        case 'grade':
            options = gradeOptions.map(s => ({ id: s.id, option: s.name }))
            break
        default:
    }
    const gotError = () => {
        // if (getter === 'tuition_centre') {
        //     return error && error.find(e => e.id === getter).condition(row[getter], row.school)
        // }
        // else return error && error.find(e => e.id === getter).condition(row[getter])
    }
    const errorFunction = value => {
        // if (getter === 'tuition_centre') {
        //     return error && error.find(e => e.id === getter).condition(row[getter], row.school)
        // }
        // else return error && error.find(e => e.id === getter).condition(value)
    }
    const update = (count, getter, value) => {
        let newData = [...data]
        newData[count][getter] = value
        setState(newData)
    }
    const tableCellStyle = index => ({
        paddingLeft: 15, position: 'sticky', left: 0, zIndex: index === 0 && 999,
        backgroundColor: isDuplicate ? 'lightyellow' : isSelected ? '#EDF4FB' : color,
        backgroundClip: 'padding-box'
    })
    return (
        <TableCell align='left' component="th" scope="row" style={tableCellStyle(cellCount)}>
            {!['school', 'tuition_centre', 'partner', 'grade'].includes(getter) ?
                <LightTooltip title={ErrorMsg(getter)} hide={!gotError() ? 1 : 0}>
                    <div>
                        <ReusableTextField type='table' height={48} bgColor='#F2F2F2' target={getter}
                            placeholder={p} count={count} state={row[getter]} setState={update}
                            errorFunction={errorFunction} />
                    </div>
                </LightTooltip> :
                <LightTooltip title={ErrorMsg(getter)} hide={!gotError() ? 1 : 0}>
                    <div>
                        <ReusableAutocomplete type='table' width={290} placeholder={p} bgColor='#F2F2F2'
                            state={row[getter]} count={count} target={getter} setState={update} freeSolo
                            options={options} errorFunction={errorFunction}
                            grayedOut={grayCondition} readOnly={grayCondition} />
                    </div>
                </LightTooltip>}
        </TableCell>
    )
}
const Row = ({ key , count, data, row, setState, headers, isSelected, error, isDuplicate, checkForErrorInRow, getters, uploadSchool,isAnwers,
    tuitionCentreOptions, schoolOptions, gradeOptions, isPrivate , errorslist }) => {
    const [color, setColor] = useState('#FFF')
    let numberederrors = error.map(el=>{
        return Number(el)
    })
    // If at least one error is present in the row, the entire row border is red
    let numserrors =errorslist? errorslist.map(el=>{
        return Number(el)
    }):[]
    const tableRowStyle = {
        height: 80, overFlow: 'auto',
        border: isAnwers?(error.length>data.indexOf(row) || checkForErrorInRow(row))&& '2px solid red':((checkForErrorInRow(row)||numserrors.includes(count))||numberederrors.includes(count))&& '2px solid red',
    }
    const tableCellStyle = index => ({
        paddingLeft: 15, position: 'sticky', left: 0, zIndex: index === 0 && 999,
        backgroundColor: isSelected ? '#EDF4FB' : color,
        backgroundClip: 'padding-box'
    })
    return (
        <TableRow hover tabIndex={-1} selected={isSelected} style={tableRowStyle}
            onMouseEnter={() => setColor('#F6F6F6')}
            onMouseLeave={() => setColor('#FFF')}>
                   <TableCell align='left' component="th" scope="row" style={tableCellStyle(count)}>
           <h1>{count+1}</h1>
        </TableCell>
            {getters.map((getter, index) => (
                !['id'].includes(getter) &&
                <Cell key={`${row.id}-${getter}`} getter={getter} cellCount={index} color={color}
                    count={count} data={data} row={row} setState={setState} headers={headers} isSelected={isSelected}
                    error={error} isDuplicate={!uploadSchool && isDuplicate(data, row)}
                    tuitionCentreOptions={tuitionCentreOptions}
                    schoolOptions={schoolOptions} gradeOptions={gradeOptions} isPrivate={isPrivate} />
            ))}
        </TableRow>
    )
}
export default function CSVUploadTable({ headers, data, error, rowsPerPage, setRowsPerPage,isAnwers,
    selecting, setSelecting, selected, setSelected, setState,
    schoolOptions, tuitionCentreOptions, gradeOptions,
    checkForErrorInRow, isDuplicate, uploadSchool, isPrivate , errorslist }) {
    const getters = headers.map(h => h.id)
    const headCells = headers
    const [order, setOrder] = useState('asc');
    const [orderBy, setOrderBy] = useState('id');
    const [page, setPage] = useState(0);
    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };
    const handleSelectAllClick = (event, names) => {
        if (event.target.checked) {
            const newSelecteds = data.map(n => n.id);
            setSelected(newSelecteds);
            setSelecting(names)
            return;
        }
        setSelected([]);
        setSelecting([])
    };
    const handleClick = (event, id, name) => {
        const selectedIndex = selected.indexOf(id);
        let newSelected = [];
        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, id);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1),
            );
        }
        setSelected(newSelected)
        if (event.target.checked) setSelecting([...selecting, name])
        else setSelecting(selecting.filter(d => d !== name))
    };
    const isSelected = (id) => selected.indexOf(id) !== -1;
    // Avoid a layout jump when reaching the last page with empty rows.
    const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - data.length) : 0;
    useEffect(() => {
        setRowsPerPage(parseInt(rowsPerPage, 10));
        setPage(0);
    }, [rowsPerPage, setRowsPerPage])
   useEffect(()=>{
    if(isAnwers){
    let newdata = [...data]
    setState(newdata)}
   },[error , errorslist])
    const closeIconDivStyle = { height: 80, display: 'flex', alignItems: 'center', justifyContent: 'center' }
    const closeIconStyle = {
        backgroundColor: '#000', color: '#FFF', fontSize: 32, padding: 4,
        borderRadius: 20, cursor: 'pointer'
    }
    const deleteRow = row => setState(data.filter(d => d.id !== row.id))
    const { width } = useWindowDimensions()
    return (
        <Grid container alignItems='flex-start' style={{ marginTop: '2%', width: 0.9 * width }}>
            <Grid item xs={0.5}>
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                    <Checkbox style={{ color: "#000", height: 61, paddingTop: 12 }}
                        indeterminate={selected.length > 0 && selected.length < data.length}
                        checked={data.length > 0 && selected.length === data.length}
                        onChange={e => handleSelectAllClick(e, data.map(d => d[getters[0]]))}
                    />
                    {data.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map(row => (
                        <Checkbox key={row.id} onClick={e => handleClick(e, row.id, row[getters[0]])}
                            style={{ color: "#000", height: 80 }} checked={isSelected(row.id)} />
                    ))}
                </div>
            </Grid>
            <Grid item xs={11}>
                <Paper sx={{ boxShadow: '0 0 3px #9E9E9E', borderRadius: 0 }} >
                    <TableContainer>
                        <Table style={{ minWidth: 350 }} aria-labelledby="tableTitle" size='small'>
                            <EnhancedTableHead order={order} orderBy={orderBy} handleRequestSort={handleRequestSort} headCells={headCells} />
                            <TableBody>
                                {data.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, i) => (
                                    <Row key={row.id} count={i + (page * rowsPerPage)} data={data} row={row} setState={setState} headers={headers} isAnwers={isAnwers}
                                        isSelected={isSelected(row.id)} error={error} isDuplicate={isDuplicate}
                                        checkForErrorInRow={checkForErrorInRow} getters={getters} uploadSchool={uploadSchool}
                                        tuitionCentreOptions={tuitionCentreOptions} schoolOptions={schoolOptions}
                                        gradeOptions={gradeOptions} isPrivate={isPrivate} errorslist={errorslist} />
                                ))}
                                {emptyRows > 0 && <TableRow style={{ height: 80 * emptyRows }}>
                                    <TableCell colSpan={6} />
                                </TableRow>}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <TablePagination showFirstButton showLastButton
                        component={() => {
                            if (rowsPerPage >= 1) {
                                let a = []
                                let z = data.length
                                while (z > 0) { z -= rowsPerPage; a.push(z); }
                                return (
                                    <Grid container direction="row" justifyContent="flex-end" alignItems="center"
                                        sx={{ mt: 6, mb: 6 }}>
                                        <IconButton disabled={!page} onClick={() => setPage(page - 1)}>
                                            <NavigateBeforeIcon />
                                        </IconButton>
                                        <PaginationButton isSelected={!page} selecting={0} setPage={setPage} />
                                        {page > 4 && '...'}
                                        {a.map((c, index) => {
                                            //All pages between first and last, and only display if its 3 page or less
                                            //away from the current page
                                            if (index && index < a.length - 1 && index <= page + 3 && index >= page - 3) {
                                                return (
                                                    <PaginationButton key={index} isSelected={page === index}
                                                        selecting={index} setPage={setPage} />
                                                )
                                            }
                                            return null
                                        })}
                                        {a.length > page + 5 && '...'}
                                        {a.length > 1 && <PaginationButton isSelected={page === a.length - 1}
                                            selecting={a.length - 1} setPage={setPage} />}
                                        <IconButton disabled={page === a.length - 1} onClick={() => setPage(page + 1)}>
                                            <NavigateNextIcon />
                                        </IconButton>
                                    </Grid>)
                            } return null
                        }}
                        count={data.length} onPageChange={() => { }}
                        page={page} rowsPerPage={rowsPerPage} />
                </Paper>
            </Grid>
            <Grid item xs={0.5}>
                <div style={{ display: 'flex', flexDirection: 'column', marginTop: 61 }}>
                    {data.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map(row => (
                        <div key={row.id} style={closeIconDivStyle} onClick={() => deleteRow(row)}>
                            <CloseIcon style={closeIconStyle} />
                        </div>
                    ))}
                </div>
            </Grid>
        </Grid>
    )
}
