import React, { useState, useEffect, useMemo, useRef } from 'react'
import { Box, Grid, Collapse, Modal } from '@mui/material'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import ViewOption from '../components/ViewOption'
import Filter from '../components/Filter'
import AddSchoolModal from '../components/School/AddSchoolModal'
import AddUserModal from '../components/User/AddUserModal'
import AddParticipantModal from '../components/Participant/AddParticipantModal'
import EditSchoolModal from '../components/School/EditSchoolModal'
import DeleteModal from '../components/modal/DeleteModal'
import ApproveSchoolModal from '../components/School/ApproveSchoolModal'
import RejectModal from '../components/modal/RejectModal'
import RestoreSchoolModal from '../components/School/RestoreSchoolModal'
import HeavyTable from '../components/table/HeavyTable'
import { useSelector } from 'react-redux'
import {
    makeSureIsArray, makeOptions, checkIfAllSelectedRowsMatchStatus, checkIfAnySelectedRowIs, resetAll,
    changeViewOptions, changeRowsPerPage, changeFiltered, changeSearch, loadData, controlView, controlFilter, cancelAction
} from '../functions/general'
import { showWarningSwal } from '../functions/alert'
import { getCompAnswers, getSchool, getSchools } from '../functions/getData'
import { approveSchool, rejectSchool, restoreSchool } from '../functions/patchData'
import { deleteSchool } from '../functions/deleteData'
import { showNotification } from '../functions/snackbar'
import NunitoText from '../components/general/NunitoText'
import ReusableTextField from '../components/general/ReusableTextField'
import ReusableButton from '../components/general/ReusableButton'
import ViewAndFilterButton from '../components/ViewAndFilterButton'
import LoadingBackdrop from '../components/general/LoadingBackdrop'
import NoData from '../components/general/NoData'
import Loader from '../components/general/Loader'
import { useSnackbar } from 'notistack'
import { isAdmin, isAdminOrPartner, isPartnerOrAssistant, isAdminOrPartnerOrAssistant } from '../functions/checkrole'
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min'
import DeleteAnswer from '../components/modal/DeleteAnswer'

const initialParams = '?limits=10'
export default function GeneralAnswers() {
    document.title = 'General Answers'
    const isMounted = useRef(false)
    const user = useSelector(state => state.user)
    const [header, setHeader] = useState()
    const [schoolTable, setSchoolTable] = useState()
    const [filtered, setFiltered] = useState([
        { label: "country", state: '', key: 'country_id', header: 'country' },
       { label: 'grade', state: '', key: 'grade', header: 'grade' },
        { label: 'Status', state: '', key: 'status', header: 'status' }
    ].filter(Boolean))
    const [filterOptions, setFilterOptions] = useState()
    const [rowsPerPage, setRowsPerPage] = useState(10)
    const [search, setSearch] = useState('')
    const [params, setParams] = useState(initialParams)
    const [loading, setLoading] = useState(false)
    const [loadingTable, setLoadingTable] = useState(false)
    const [compName , setCompName] = useState('')
    const [firstLoaded, setFirstLoaded] = useState(false)
    const [viewing, setViewing] = useState(false)
    const [filtering, setFiltering] = useState(false)
    const [onDelete , setonDlete] = useState(false)
    const [id, setId] = useState('')
    const [selected, setSelected] = useState([])
    const [selecting, setSelecting] = useState([])
    const handleView = () => controlView(viewing, setViewing, setFiltering)
    const cancelDelete = ()=>{ setonDlete(false)}
    const handleFilter = () => controlFilter(filtering, setFiltering, setViewing)
    const { enqueueSnackbar, closeSnackbar } = useSnackbar()
    const afterFunction = (cancel, data) => {
        if ([200, 201].includes(data.status)) {
            resetAll(initialParams, filtered, setParams, setSearch, setFiltered)
            getData(initialParams, signal)
            cancel()
            showNotification('success', data.message, enqueueSnackbar, closeSnackbar)
            setSelected([])
            setSelecting([])
        }
    }
    const getData = (params, signal) => {
        setSelected([])
        setSelecting([])
        setLoadingTable(true)
        if (location.search.slice(0, 4) === '?id=') {
        getCompAnswers(location.search.slice(4),params, signal).then(c=>{
            // c.filterOptions.map(el=>{
            //     temparr.push(makeSureIsArray(el))
            // })
          console.log(c)
          setCompName(c.competition)
            let headersarr = c.headers
            let header=[]
            headersarr.map(head=>{
                if(head==='Index No'){
                 header.push({id:'index_no' , label:head})
                }else if(head==="Name"){
                 header.push({id:'name' , label:head})
                }else if(head==="School"){
                 header.push({id:'school' , label:head})
                }else if(head==="Country"){
                 header.push({id:'country' , label:head})
                }else if(head==="Grade"){
                 header.push({id:'grade' , label:head})
                }else if(head==="Status"){
                 header.push({id:'status' , label:head})
                }else{
                    header.push({id:head , label:head})
                }
             })
             setHeader(header)
             setFilterOptions(c.filterOptions)
            setSchoolTable(c.data)
            setLoadingTable(false)

            
        }).catch(e => console.log(e))}else {
            showWarningSwal('Wrong query')
            if (!signal.aborted) {
               setLoading(false)
               setFirstLoaded(true)
            }
         }
    }
    const onChangeRowsPerPage = value => {
        changeRowsPerPage(value, rowsPerPage, filtered, search, signal, setRowsPerPage, setParams, getData)
    }
    const onChangeFiltered = value => {
        changeFiltered(value, filtered, search, rowsPerPage, signal, setFiltered, setParams, getData )
    }
    const onChangeSearch = value => {
        changeSearch(value, search, filtered, rowsPerPage, signal, setSearch, setParams, getData)
    }
    const onChangePage = pageNo => getData(`${params}&page=${pageNo}`, signal)
    const controller = useMemo(() => new AbortController(), [])
    const signal = controller.signal
    let location = useLocation()
    useEffect(() => {
        isMounted.current = true
       
       getData(initialParams,signal)
        return () => {
            controller.abort()
            isMounted.current = false
        }
    }, [controller, signal])
    return (
        <Box className='wrapperBox'>
            <LoadingBackdrop loading={loading} />
          
            <Collapse in={filtering}>
                {filterOptions && <Filter type='collapse' filterOptions={filtered.map(f => makeOptions(filterOptions, f.header))}
                    filtered={filtered} onChangeFiltered={onChangeFiltered} />}
            </Collapse>
            <Grid className='firstRowContainer' container justifyContent="space-between">
                <div className='dashboardAndSelfBtnDiv'>
                    <ReusableButton text='Dashboard' fontSize={14} bgColor='#F16774' height={36} width={125} br={18} to='/dashboard' iconType='home' />
                    <ChevronRightIcon />
                    <ReusableButton text='General Answers' fontSize={14} bgColor='#F16774' height={36} br={18} />
                </div>
                <div className='viewAndFilterBtnDiv'>
                
                    <ViewAndFilterButton text='Filter' state={filtering} fontSize={14} height={42}
                        onClick={handleFilter} />
                </div>
            </Grid>
            <Grid className='tableContainer' container>
                <NunitoText value={`${compName} Answers`} fontSize={40} fontWeight={700} italic color='#144A94' />
              
                <Grid className='searchAndBtnContainer' container alignItems="center" justifyContent="space-between">
                    <ReusableTextField type='search' width={500} height={60} bgColor='#F2F2F2' placeholder='Search using keyword'
                        state={search} setState={onChangeSearch} onBlur />
                          <div className='endBtnDiv'>
                            <ReusableButton text='Mass Delete' fontSize={15} bgColor='#E83042' height={46}
                                marginRight={20} onClick={()=>{setonDlete(true)}} iconType='delete' />
                          </div>
                    
                </Grid>
                <NunitoText value='Search only in "Index Number"'
                    fontSize={20} fontWeight={400} italic color='#144A94' />
                {loadingTable && <Loader height={600} />}
                {!loadingTable && schoolTable && makeSureIsArray(schoolTable.data).length &&
                    <HeavyTable headers={header} list={schoolTable} title='generalanswers' rowsPerPage={rowsPerPage}
                       onChangePage={onChangePage} 
                        setSelecting={setSelecting} selected={selected} setSelected={setSelected}
                        setId={setId} 
                       
                    />}
                {!loadingTable && firstLoaded && schoolTable && !makeSureIsArray(schoolTable.data).length && <NoData height={600} />}
            </Grid>
            <Modal open={onDelete} onClose={() => cancelDelete()}>
                <>
                    {schoolTable && <DeleteAnswer deleteFunction={()=>{}} cancelDelete={cancelDelete} refreshdata={getData}/>}
                </>
            </Modal>
        </Box>
    )
}