import React, { useState } from "react";
import NunitoText from "../general/NunitoText";
import CloseIcon from "@mui/icons-material/Close";
import {
  Table,
  TableRow,
  Box,
  Grid,
  Button,
  TableCell,
  TableContainer,
  TableBody,
  Paper,
  List,
  ListItem,
  ListItemText,
  Typography,
} from "@mui/material";
import EnhancedTableHead from "../table/EnhancedTableHead";
import { stableSort, getComparator } from "../../functions/table";
import Widget from "../general/Widget";
// import { List, ListItem, ListItemText, Typography } from "@material-ui/core";
import { makeStyles } from "@mui/styles";

export default function ViewLogsModal({
  handleClose,
  logs,
  countries,
  levelName,
}) {
  console.log(logs);
  // countries = countries.split(",");
  // const numColumns = 2;
  // const numRows = Math.ceil(countries.length / numColumns);
  // const gridData = Array.from({ length: numRows }, (_, rowIndex) =>
  //   countries.slice(rowIndex * numColumns, (rowIndex + 1) * numColumns)
  // );

  const headers = [
    { id: "computed_by", label: "Computed By" },
    { id: "computed_at", label: "Computed At" },
    { id: "options", label: "Options" },
    { id: "clear_previous_results", label: "Clear Previous Results" },
  ];
  const data = Object.values(logs);
  const getters = headers.map((h) => h.id);
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("id");
  const [widths, setWidths] = useState(
    headers.map((h) => ({ id: h.id, width: 0, totalWidth: 0 }))
  );
  const handleRequestSort = (property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  return (
    <Box
      className="popUpModal"
      style={{
        width: 820,
        height: 600,
        position: "fixed",
        top: "50%",
        right: "50%",
        zIndex: 9999,
        justifyContent: "flex-start",
        alignItems: "center",
        padding: "15px",
        overflowY: "auto",
      }}
    >
      <Grid
        container
        justifyContent="space-between"
        alignItems="center"
        margin="10px 0"
      >
        <div style={{ height: 32, width: 32 }}></div>
        <NunitoText
          value="Logs"
          fontSize={32}
          fontWeight={600}
          color="#5E75C3"
        />
        <Button style={{ border: "none", backgroundColor: "#ffffff80" }}>
          <CloseIcon
            style={{ height: 32, width: 32, color: "#707070" }}
            onClick={handleClose}
          />
        </Button>
      </Grid>
      <Grid
        container
        style={{
          margin: "10px 0",
          // flexDirection: "row",
          justifyContent: "center",
          alignItems: "center",
          gap: "5px",
        }}
      >
        {levelName && <Widget title="Level" content={levelName} />}
        {countries && (
          <Widget
            title="Countries"
            content={<CountryList countriesString={countries} />}
          />
        )}
      </Grid>

      <Grid
        container
        alignItems="center"
        justifyContent="center"
        style={containerStyle(840)}
      >
        <Paper style={paperStyle(840)}>
          <TableContainer
          // style={{ maxHeight: 300, overflowY: "auto" }}
          >
            <Table size="small">
              <EnhancedTableHead
                handleRequestSort={handleRequestSort}
                headers={headers}
                widths={widths}
                setWidths={setWidths}
                fixed={[]}
              />
              <TableBody>
                {stableSort(data, getComparator(order, orderBy)).map(
                  (row, i) => (
                    <Row
                      key={i}
                      count={data.findIndex((d) => d.id === row.id)}
                      row={row}
                      getters={getters}
                      widths={widths}
                    />
                  )
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
      </Grid>
    </Box>
  );
}

const Row = ({ row, getters }) => {
  const tableRowStyle = {
    height: 80,
    overFlow: "auto",
  };

  return (
    <TableRow style={tableRowStyle}>
      {getters.map((getter) => {
        console.log(getter);
        if (getter === "clear_previous_results") {
          console.log(row.logs[getter]);
        }
        const output =
          getter === "options"
            ? row.logs[getter].join(" / ")
            : getter === "clear_previous_results"
            ? row.logs[getter] !== undefined && row.logs[getter].toString()
            : row[getter];

        return (
          <TableCell align="left" component="th" scope="row">
            {output}
          </TableCell>
        );
      })}
    </TableRow>
  );
};

const CountryList = ({ countriesString }) => {
  // const countriesString = "Australia,Azerbaijan,Brazil,Cambodia,Canada,China,HongKong SAR,Egypt,Ghana,India,Indonesia,Iran,Malaysia,New Zealand,Philippines,Singapore,Sri Lanka,Thailand,United Arab Emirates,US";
  console.log(countriesString);
  const countries = countriesString.split(",");

  // Calculate the number of rows needed based on the number of countries and desired columns
  const numColumns = 5;
  const numRows = Math.ceil(countries.length / numColumns);

  // Create a two-dimensional array to represent rows and columns
  const gridData = Array.from({ length: numRows }, (_, rowIndex) =>
    countries.slice(rowIndex * numColumns, (rowIndex + 1) * numColumns)
  );

  const useStyles = makeStyles((theme) => ({
    topLevelGrid: {
      // margin: "-15px", // Negative margin to counteract spacing
      // border: "1px solid red",
      justifyContent: "center",
      alignItems: "center",
    },
    innerGrid: {
      margin: "-23px 0", // Half of the desired spacing
    },
    countryText: {
      fontSize: "14px",
    },
    tst: {
      // border: "1px solid red",
      justifyContent: "flex-start",
      // padding: "7px 1px",
      // margin: "0px 0 0 0",
    },
  }));
  const classes = useStyles();

  return (
    <Grid container spacing={0} className={classes.topLevelGrid}>
      {gridData.map((row, rowIndex) => (
        <Grid
          container
          item
          key={rowIndex}
          spacing={0}
          className={classes.innerGrid}
        >
          {row.map((country, columnIndex) => (
            <Grid item xs={2.4} key={columnIndex}>
              <List>
                <ListItem className={classes.tst}>
                  <ListItemText>
                    <Typography className={classes.countryText}>
                      -{country}
                    </Typography>
                  </ListItemText>
                </ListItem>
              </List>
            </Grid>
          ))}
        </Grid>
      ))}
    </Grid>
  );
};

const containerStyle = (width) => ({
  marginBlock: "2%",
  width: 0.89 * width,
});
const paperStyle = (width) => ({
  boxShadow: "0 0 3px #9E9E9E",
  minWidth: 400,
  maxWidth: 3200,
  width: 0.89 * width,
});
