import React, { useState, useEffect, useMemo, useRef } from 'react'
import { Box, Grid, Collapse, Modal } from '@mui/material'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import Filter from '../components/Filter'
import HeavyTable from '../components/table/HeavyTable'
import DeleteModal from '../components/modal/DeleteModal'
import {
    makeSureIsArray, makeOptions, resetAll,
    changeViewOptions, changeRowsPerPage, changeFiltered, changeSearch, loadData, controlView, controlFilter, cancelAction
} from '../functions/general'
import { showWarningSwal } from '../functions/alert'
import { useDispatch } from 'react-redux'
import {  getModerateReport, getReport } from '../functions/getData'
import { deleteCompetition } from '../functions/deleteData'
import { showNotification } from '../functions/snackbar'
import { isAdmin, isPartner, isPartnerOrAssistant } from '../functions/checkrole'
import NunitoText from '../components/general/NunitoText'
import ReusableTextField from '../components/general/ReusableTextField'
import ReusableButton from '../components/general/ReusableButton'
import ViewAndFilterButton from '../components/ViewAndFilterButton'
import LoadingBackdrop from '../components/general/LoadingBackdrop'
import NoData from '../components/general/NoData'
import Loader from '../components/general/Loader'
import { useSnackbar } from 'notistack'
import { useLocation } from 'react-router-dom'
const competitionHeader = () => [
    { id: 'name', label: 'participant' },
    { id: 'index_no', label: 'index' },
    { id: 'certificate_no', label: 'certificate number' },
    { id: 'competition', label: 'Competition' },
    { id: 'organization', label: 'organization' },
    { id: 'country', label: 'country' },  
    { id: 'level', label: 'level' },
    { id: 'grade', label: 'grade' },
    { id: 'school', label: 'school' },
    { id: 'tuition_centre', label: 'tuition' },
    { id: 'points', label: 'points' },
    { id: 'award', label: 'award' },
    { id: 'school_rank', label: 'school rank' },
    { id: 'country_rank', label: 'country rank' }, 
    { id: 'global_rank', label: 'global rank' },
]
const initialParams = '?'
export default function CompReport() {
    document.title = 'Competition Report'
    const isMounted = useRef(false)
    const dispatch = useDispatch()
    const [header, setHeader] = useState(competitionHeader())
    const [competitionTable, setCompetitionTable] = useState()
    const [filtered, setFiltered] = useState([
        { label: "award", state: '', key: 'award', header: 'award' },
        { label: 'country', state: '', key: 'country', header: 'country' },
        { label: 'grade', state: '', key: 'grade', header: 'grade' },
        { label: 'school', state: '', key: 'school', header: 'school' }
    ])
    const [filterOptions, setFilterOptions] = useState()
    
    const [rowsPerPage, setRowsPerPage] = useState(10)
    const [search, setSearch] = useState('')
    const [params, setParams] = useState(initialParams)
    const [loading, setLoading] = useState(false)
    const [loadingTable, setLoadingTable] = useState(false)
    const [firstLoaded, setFirstLoaded] = useState(false)
    const [viewing, setViewing] = useState(false)
    const [filtering, setFiltering] = useState(false)
    const [id, setId] = useState('')
    const [selected, setSelected] = useState([])
    const [selecting, setSelecting] = useState([])
    const [onDelete, setOnDelete] = useState(false)
    const [onAddPartner, setOnAddPartner] = useState(false)
    const [onUpload, setOnUpload] = useState(false)
    const handleView = () => controlView(viewing, setViewing, setFiltering)
    const handleFilter = () => controlFilter(filtering, setFiltering, setViewing)
    const cancelDelete = () => cancelAction(setOnDelete, setId)
    const cancelAddPartner = () => cancelAction(setOnAddPartner, setId)
    const cancelUpload = () => cancelAction(setOnUpload, setId)
    const { enqueueSnackbar, closeSnackbar } = useSnackbar()
    const onChangeRowsPerPage = value => {
        changeRowsPerPage(value, rowsPerPage, filtered, search, signal, setRowsPerPage, setParams, getData)
    }
    const onChangeFiltered = value => {
        changeFiltered(value, filtered, search, rowsPerPage, signal, setFiltered, setParams, getData)
    }
    const onChangeSearch = value => {
        changeSearch(value, search, filtered, rowsPerPage, signal, setSearch, setParams, getData)
    }
    const onChangePage = pageNo => getData(`${params}&page=${pageNo}`, signal)
    const controller = useMemo(() => new AbortController(), [])
    const signal = controller.signal
    let location = useLocation()
    const getData = (params,signal)=>{
        setLoadingTable(true)
        if (location.search.slice(0, 4) === '?id=') {
        getModerateReport(location.search.slice(4),params, signal).then(c=>{
            // c.filterOptions.map(el=>{
            //     temparr.push(makeSureIsArray(el))
            // })
            setCompetitionTable(c)
            setLoadingTable(false)

            
        }).catch(e => console.log(e))}else {
            showWarningSwal('Wrong query')
            if (!signal.aborted) {
               setLoading(false)
               setFirstLoaded(true)
            }
         }
    }
    const downloadFile = ({ data, fileName, fileType }) => {
        const blob = new Blob([data], { type: fileType })
      
        const a = document.createElement('a')
        a.download = fileName
        a.href = window.URL.createObjectURL(blob)
        const clickEvt = new MouseEvent('click', {
          view: window,
          bubbles: true,
          cancelable: true,
        })
        a.dispatchEvent(clickEvt)
        a.remove()
      }
    const exportToCsv = (e,data,compname) => {
        console.log(data)
          // Headers for each column
          let headers = ['competition,organization,country,level,grade,school,school name in certificate,tuition,index,participant,certificate number,points,award,country_rank,school_rank,global_rank']
          // Convert users data to a csv
          data.shift();
          let usersCsv = data.reduce((acc, user) => {
            let cirtname = user['school name in certificate']
            const { competition, organization, country, level, grade, school,tuition_centre, index_no, name, certificate_no,points, award, country_rank, school_rank, global_rank} = user
            acc.push([competition, organization, country, level, grade, school,cirtname,tuition_centre, index_no, name,certificate_no, points, award, country_rank, school_rank, global_rank].join(','))
            return acc
          }, [])
        
          downloadFile({
            data: [...headers, ...usersCsv].join('\n'),
            fileName: `${compname}.csv`,
            fileType: 'text/csv',
          })
        }
    useEffect(() => {
        isMounted.current = true
        if (location.search.slice(0, 4) === '?id=') {
            setLoadingTable(true)
            getModerateReport(location.search.slice(4),initialParams, signal).then(c=>{
                console.log(c)
                let temparr = {}
                let filters = c.filterOptions
                for (const property in filters) {
                    if(Array.isArray(filters[property])){
                        temparr[`${property}`] = filters[property]
                    }else{
                        let singleobj = filters[property]
                        let miniarr = []
                        for(const item in singleobj){
                            let miniobj = {
                                id:item,
                                name:singleobj[item]
                            }
                            miniarr.push(miniobj)
                        }
                        temparr[`${property}`]=miniarr
                    }
                  }
                  setFilterOptions(temparr)
                // c.filterOptions.map(el=>{
                //     temparr.push(makeSureIsArray(el))
                // })
                setCompetitionTable(c)
                setLoadingTable(false)
    
                
            }).catch(e => console.log(e))}else {
                showWarningSwal('Wrong query')
                if (!signal.aborted) {
                   setLoading(false)
                   setFirstLoaded(true)
                }
             }
    }, [controller, signal])
   console.log('table' , competitionTable)
    return (
        <Box className='wrapperBox'>
            <LoadingBackdrop loading={loading} />
          
            <Collapse in={filtering}>
                {filterOptions && <Filter type='collapse' filterOptions={filtered.map(f => makeOptions(filterOptions, f.header))}
                    filtered={filtered} onChangeFiltered={onChangeFiltered} />}
            </Collapse>
            <Grid className='firstRowContainer' container justifyContent="space-between">
                <div className='dashboardAndSelfBtnDiv'>
                    <ReusableButton text='Dashboard' fontSize={14} bgColor='#F16774' height={36} width={125} br={18} to='/dashboard' iconType='home' />
                    <ChevronRightIcon />
                    <ReusableButton text='Competition Report' fontSize={14} bgColor='#F16774' height={36} br={18} />
                </div>
                <div className='viewAndFilterBtnDiv'>
                    <ViewAndFilterButton text='Filter' state={filtering} fontSize={14} height={42}
                        onClick={handleFilter} />
                </div>
            </Grid>
            <Grid className='tableContainer' container>
                <NunitoText value='Competition Report' fontSize={40} fontWeight={700} italic color='#144A94' />
                <Grid className='searchAndBtnContainer' container alignItems="center" justifyContent="space-between">
                    <ReusableTextField type='search' width={500} height={60} bgColor='#F2F2F2' placeholder='Search using keyword'
                        state={search} setState={onChangeSearch} onBlur />
                    { <div className='endBtnDiv'>
                        <ReusableButton text='Export Csv' fontSize={15} bgColor='#5E75C3' height={46} marginRight={20}
                            iconType='export' onClick={e=>{
                                getReport(location.search.slice(4),'',signal).then(res=>{
                                    console.log(res)
                                    exportToCsv(e , res ,competitionTable.data.data[0].competition )
                                })
                            }} />
                       
                    </div>}
                </Grid>
                <NunitoText value='Search only in "Competition" column' fontSize={20} fontWeight={400} italic color='#144A94' />
                {loadingTable && <Loader height={600} />}
                {!loadingTable && competitionTable&&competitionTable.data && makeSureIsArray(competitionTable.data.data).length &&
                    <HeavyTable headers={header} list={competitionTable.data} title='competition Report' rowsPerPage={rowsPerPage}
                        setOnDelete={setOnDelete} onChangePage={onChangePage} fixed={[]} isSelecting={true}
                        selecting={selecting} setSelecting={setSelecting} selected={selected}  setSelected={()=>{

                        }}
                      
                    />}
                {!loadingTable && firstLoaded && competitionTable && (competitionTable.data.data).length===0 && <NoData height={600} />}
            </Grid>    
        </Box>
    )
}