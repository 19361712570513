import React from 'react'
import { Box, Grid } from '@mui/material'
import NunitoText from '../general/NunitoText'
import ReusableTextField from '../general/ReusableTextField'
import ReusableButton from '../general/ReusableButton'
import { useState } from 'react'
import { useEffect } from 'react'
import { getCountries, getCountries2 } from '../../functions/getData'
import { useSelector } from 'react-redux'
import ReusableAutocompleteWithID from '../general/ReusableAutocompleteWithID'

export default function ComputeCheating({comp , onComp,allCountryOptions}) {
    const [percentage , setPercentage] = useState(85)
    const [number , setNumber] = useState(5)
    const [param , setParam] = useState('')
    const [country , country_id] = useState([])
   
  const handleparam =()=>{
    if(country.length>0){
        return `percentage=${percentage}&number_of_incorrect_answers=${number}&country=[${country}]`
    }else{
        return `percentage=${percentage}&number_of_incorrect_answers=${number}`
    }
  }
console.log(handleparam())
    return  <Box className='popUpModal horizontalScrollable' style={{ width: 1200, height: 500 }}>
    <NunitoText value='Compute Cheating' fontSize={30} fontWeight={600} color='#5E75C3' marginTop={20} />

    <Grid container justifyContent={'space-between'} width={'100%'} alignSelf={'center'}>
        <ReusableTextField type={'withLabel'} placeholder={'Matching questions percentage %'} width={550}  state={percentage} setState={setPercentage}/>
        <ReusableTextField type={'withLabel'} placeholder={'Number of Min Matching Incorrect Answers'} width={550}  state={number} setState={setNumber}/>
    </Grid>
    <Grid container alignSelf={'center'} justifyContent={'center'}>
        <ReusableAutocompleteWithID type='withLabel' width={550} placeholder='Country' multiple
                        state={country} setState={country_id} borderColor='#707070' 
                        options={allCountryOptions.map(c => ({ id: c.id, option: c.display_name }))}/>
    </Grid>
    <Grid justifyContent={'space-between'} container width={'80%'} alignSelf={'center'}>
        <ReusableButton  text={'Compute'}  fontSize={22} height={60} width={300} bgColor='#5E75C3' onClick={()=>{comp(handleparam())
        onComp(false)
        }} marginBottom={20} />
        <ReusableButton  text={'Cancel'}  fontSize={22} height={60} width={300} bgColor='#E83042' onClick={()=>{
        onComp(false)
        }} marginBottom={20} />
    </Grid>
</Box>
}