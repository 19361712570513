import { isPartnerOrAssistant } from "./checkrole";

// Date functions
export const formatDate = (date, divider = "/") => {
  let c = new Date(date);
  return `${c.getDate() < 10 ? "0" : ""}${c.getDate()}${divider}${
    c.getMonth() < 9 ? "0" : ""
  }${c.getMonth() + 1}${divider}${c.getFullYear()}`;
};
export const formatDateTimeForDisplay = (date, divider = "/") => {
  let c = new Date(date);
  return `${c.getDate() < 10 ? "0" : ""}${c.getDate()}${divider}${
    c.getMonth() < 9 ? "0" : ""
  }${c.getMonth() + 1}${divider}${c.getFullYear()} ${c.toLocaleString("en-US", {
    hour: "numeric",
    minute: "numeric",
    second: "numeric",
    hourCycle: "h23",
  })}`;
};
export const formatDateTime = (date, divider = "/") => {
  let c = new Date(date);
  return `${c.getFullYear()}${divider}${c.getMonth() < 9 ? "0" : ""}${
    c.getMonth() + 1
  }${divider}${c.getDate() < 10 ? "0" : ""}${c.getDate()} ${c.toLocaleString(
    "en-US",
    {
      hour: "numeric",
      minute: "numeric",
      second: "numeric",
      hourCycle: "h23",
    }
  )}`;
};
export const getDateDiff = (bigDate, smallDate) => {
  return Math.round(
    (bigDate.setHours(0, 0, 0, 0) - smallDate.setHours(0, 0, 0, 0)) /
      (1000 * 60 * 60 * 24)
  );
};
export const dateHasPassed = (firstDate, secondDate) => {
  return firstDate.setHours(0, 0, 0, 0) < secondDate.setHours(0, 0, 0, 0);
};
// Ensure always return an array(even if an object is parsed in)
// Only using for now because when getting data from APIs, sometimes can get
// data: [{}, {}, {}] or data: {"0": {}, "1": {}, "2": {}}, so need ensure to always return the former
export const makeSureIsArray = (arr) => {
  return Array.isArray(arr) ? arr : Object.values(arr);
};
// Check if all selected rows are pending so can approve
// Check if all selected users are enabled so can disable
// Check if all selected users are disabled so can enable
export const checkIfAllSelectedRowsMatchStatus = (
  selected,
  data,
  status,
  user
) => {
  if (selected.length === 0) return false;
  if (user) {
    for (let i = 0; i < selected.length; i++) {
      let row = makeSureIsArray(data).find((d) => d.id === selected[i]);
      if (
        !status.includes(row.status) ||
        row.created_by_userid === user.user_id
      )
        return false;
    }
  } else {
    for (let i = 0; i < selected.length; i++) {
      let row = makeSureIsArray(data).find((d) => d.id === selected[i]);
      if (!status.includes(row.status)) return false;
    }
  }
  return true;
};
// Check if any selected rows are <status>
export const checkIfAnySelectedRowIs = (selected, data, status, user) => {
  if (selected.length === 0) return false;
  for (let i = 0; i < selected.length; i++) {
    let row = makeSureIsArray(data).find((d) => d.id === selected[i]);
    if (row.status === status) return true;
  }
  return false;
};
export const makeOptions = (obj, title) => {
    
    let arr = obj[title]
    
    arr = arr&&arr.filter(el=> el!=null)
    
    if (!Array.isArray(arr) || arr.length === 0) return []
    let objType = typeof arr[0]
    if (objType === 'object') {
    //   console.log(arr.filter(item => item.id !== null).sort((a, b) => {
    //     let first = Object.values(a).at(-1)
    //     let second = Object.values(b).at(-1)
    //     if (first === null) return -1
    //     else if (second === null) return 1
    //     else return String(first).localeCompare(String(second))
    // }))
        // To sort countries(and all other kinds of objects, like schools, organizations) by alphabetical order
      
        return arr.filter(item => item!=null&&item.id !== null).sort((a, b) => {
          
            let first = Object.values(a).at(-1)
            let second = Object.values(b).at(-1)
         
            if (first === null) return -1
            else if (second === null) return 1
            else return String(first).localeCompare(String(second))
        }).map(item => ({ id: item.id, option: Object.values(item).at(1) }))
    } else if (objType === 'number') {
        switch (title) {
            case 'schooltype':
            case 'private':
                return arr.map(item => ({ id: item, option: item ? 'Private' : 'School' }))
            case 'format':
                return arr.map(item => ({ id: item, option: item ? 'Global' : 'Local' }))
            case 'grade':
                return arr.map(item => ({ id: item, option: gradeOptions.find(g => g.id === item).name }))
            default:
                return arr.map(item => ({ id: item, option: item.toString() }))
        }
    } else {
       
        return arr.map(item => ({ id: item, option: item }))
    }
}
// Not using this for now
export const checkIfLastPage = (currentPage, links) =>
  currentPage !== links.filter((l) => !isNaN(l.label)).length;
// Check if password meets requirement, which is
// At least 8 characters, have both upper and lowercase, have a number and contains one of the following:
// !$#%@
export const checkPassword = (str) => {
  return (
    str.length < 8 ||
    str.toUpperCase() === str ||
    str.toLowerCase() === str ||
    !"!$#%@".split("").some((el) => str.includes(el)) ||
    !/\d/.test(str)
  );
};
export const invalidName = (str) => {
  if (str === undefined) return true;
  // eslint-disable-next-line no-useless-escape
  return str.length === 0 || !/^[\.\,\s\(\)\\[\]\w-]*$/.test(str.trim());
};
export const invalidNumber = (str) => !/^[\d]*$/.test(str) || !str.length;
export const invalidEmail = (str) => {
  // eslint-disable-next-line no-useless-escape
  return !/^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i.test(
    str
  );
};
// Not sure how the regex works, but it helps to remove any single or double quotes in a cell
// before storing the
export const delimitCSVRow = (str) => {
  if (str) {
    return str
      .split(/(".*?"|[^",]+)(?=\s*,|\s*$)/g)
      .filter((g) => !["", ","].includes(g))
      .map((f) => f.replace(",,", ""));
  }
  return [""];
};
export const sortErrorByRow = (obj) => {
  let sorted = Object.keys(obj).sort(
    (a, b) => a.split(".")[0] - b.split(".")[0]
  );
  let output = [];
  sorted.forEach((s) => {
    if (output.findIndex((o) => o.id === s.split(".")[0]) > -1) {
      output[output.findIndex((o) => o.id === s.split(".")[0])][
        [s.split(".")[1]]
      ] = true;
    } else output.push({ id: s.split(".")[0], [s.split(".")[1]]: true });
  });
  return output;
};
export const sortErrorByType = (obj) => {
  let newOrder = [];
  let sorted = Object.keys(obj);
  let output = [];
  sorted.forEach((s) => {
    if (output.findIndex((o) => o.errorType === s.split(".")[1]) > -1) {
      output[output.findIndex((o) => o.errorType === s.split(".")[1])].ids.push(
        s.split(".")[0]
      );
    } else output.push({ errorType: s.split(".")[1], ids: [s.split(".")[0]] });
  });
  output.forEach((o) => o.ids.forEach((id) => newOrder.push(id)));
  return output;
};
// Functions to show/hide data for table
export const changeViewOptions = (header, setViewOptions, setHeader, value) => {
    setViewOptions(value)
    let donShow = value.filter(v => !v.state)
    setHeader(header.filter(h => !donShow.find(d => d.key === h.id)))
}
const handleParams = (rowsPerPage, filtered, search, signal, setParams, getData , isModeration,id) => {

    let newParams =`?limits=${rowsPerPage}`
    if(isModeration){
        newParams = newParams+'&currentPage=moderation'
    }
    if(id!==undefined){
        newParams = newParams+`&collection_id=${id}`
    }
    filtered.forEach(({ key, state }) => {
        if (state.toString().length) newParams += `&${key}=${state}`
    })
    if (search.length) newParams += `&search=${search}`
    setParams(newParams)
    getData(newParams, signal)
}
export const changeRowsPerPage = (value, oldValue, filtered, search, signal, setRowsPerPage, setParams, getData) => {
    // Don't do anything if they attempt to set rowsPerPage to less than 10 or more than 50
    if (value < 10 || value > 50) return console.log('Min is 10, Max is 50')
    setRowsPerPage(value)
    if (JSON.stringify(value) === JSON.stringify(oldValue)) return console.log('Rows per page is unchanged')
    handleParams(value, filtered, search, signal, setParams, getData)
}
export const changeFiltered = (value, oldValue, search, rowsPerPage, signal, setFiltered, setParams, getData,isModeration , fillfilters) => {
    if(fillfilters){
        fillfilters(value)
    }
  
    setFiltered(value)
    if (JSON.stringify(value) === JSON.stringify(oldValue)) return console.log('Filtered is unchanged')
    handleParams(rowsPerPage, value, search, signal, setParams, getData,isModeration)
}
export const changeSearch = (value, oldValue, filtered, rowsPerPage, signal, setSearch, setParams, getData,isModeration,id) => {
    let newword = value.replace(/ /g, '%')
    setSearch(value)
    if (JSON.stringify(value) === JSON.stringify(oldValue)) return console.log('Search is unchanged')
    handleParams(rowsPerPage, filtered, newword, signal, setParams, getData , isModeration,id)
}
export const loadData = (setLoadingTable, setFirstLoaded, getFunction, params, setTable, setFilterOptions, custom, signal) => {
    setLoadingTable(true)
    getFunction(params, signal).then(d => {
        // The object d is something like { filterOptions: {}, SchoolOptions: {} }
        let output = Object.values(d)
        
        if (setFilterOptions) setFilterOptions(output[0])
        if (custom) {
            switch (custom.title) {
               
                case 'competition':
                    makeSureIsArray(output[1].data).forEach(c => {
                        let foundOrganization = c.competition_organization[0]
                      

                        c.status = foundOrganization.status
                        c.registration_open_date = isPartnerOrAssistant()?foundOrganization.registration_open_date: c.global_registration_date 
                        
                        c.competition_start_date = isPartnerOrAssistant()?foundOrganization.dates[0]: c.competition_start_date
                        c.competition_end_date = isPartnerOrAssistant()?foundOrganization.dates[foundOrganization.dates.length-1]:c.competition_end_date
                    })
                    setTable(output[1])
                  
                    break;
                default:
            }
        } else setTable(output[1])
        setLoadingTable(false)
        setFirstLoaded(true)
    }).catch(e => {
        if (e.name !== 'AbortError') {
            setLoadingTable(false)
            setFirstLoaded(true)
        }
    })
}
export const controlView = (viewing, setViewing, setFiltering) => {
  setViewing(!viewing);
  setFiltering(false);
};
export const controlFilter = (filtering, setFiltering, setViewing) => {
  setFiltering(!filtering);
  setViewing(false);
};
export const cancelAction = (setAction, setId) => {
  setAction(false);
  if (setId) setId("");
};
export const resetAll = (
  initialParams,
  filtered,
  setParams,
  setSearch,
  setFiltered
) => {
  setParams(initialParams);
  setSearch("");
  let newFiltered = window.structuredClone(filtered);
  newFiltered.forEach((f) =>
    Array.isArray(f.state) ? (f.state = []) : (f.state = "")
  );
  setFiltered(newFiltered);
  // setFiltered(filtered.map(f => ({ ...f, state: Array.isArray(f.state) ? [] : '' })))
};
export const gradeOptions = [
  { id: 14, name: "K1" },
  { id: 15, name: "K2" },
  { id: 1, name: "Grade 1 / Primary 1" },
  { id: 2, name: "Grade 2 / Primary 2" },
  { id: 3, name: "Grade 3 / Primary 3" },
  { id: 4, name: "Grade 4 / Primary 4" },
  { id: 5, name: "Grade 5 / Primary 5" },
  { id: 6, name: "Grade 6 / Primary 6" },
  { id: 7, name: "Grade 7 / Secondary 1" },
  { id: 8, name: "Grade 8 / Secondary 2" },
  { id: 9, name: "Grade 9 / Secondary 3" },
  { id: 10, name: "Grade 10 / Secondary 4" },
  { id: 11, name: "Grade 11 / Junior College 1" },
  { id: 12, name: "Grade 12 / Junior College 2" },
  { id: 13, name: "Grade 11 - 12 / Junior College 1 - 2" },
  { id: 0, name: "test grade" },
];
// let test = [14, 15, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13].map(g => {
//     let obj = { id: g }
//     if (g < 13) {
//         obj.name = `Grade ${g}${` / ${g < 7 ? 'Primary' : g < 11 ? 'Secondary' : 'Junior College'} ${g < 7 ? g : g < 11 ? g - 6 : g - 10}`}`
//     } else {
//         obj.name = g === 13 ? 'Grade 11 - 12 / Junior College 1 - 2' : g === 14 ? 'K1' : 'K2'
//     }
//     return obj
// })
