import React, { useState, useEffect, useMemo, useRef } from 'react'
import './table.css'
import { useSelector } from 'react-redux'
import { Box, Grid } from '@mui/material'
import { addMarkingPreparation } from '../../functions/postData'
import { showWarningSwal } from '../../functions/alert'
import CloseIcon from '@mui/icons-material/Close'
import NunitoText from '../general/NunitoText'
import ReusableAutocompleteWithID from '../general/ReusableAutocompleteWithID'
import ReusableButton from '../general/ReusableButton'
import { getMoreInfo } from '../../functions/getData'
import { gradeOptions } from '../../functions/general'
const boxStyle = { width: 1200, minHeight: 400, maxHeight: '90%' }
const closeIconDivStyle = { display: 'flex', justifyContent: 'flex-end', marginTop: -42 }
const closeIconStyle = { fontSize: 36, cursor: 'pointer' }
const fieldDivStyle = { display: 'flex', justifyContent: 'center' }
const buttonContainerStyle = { marginBlock: 20 }
export default function InfoTable({closeinfo , id , infoCountries}) {
   const isMounted = useRef(false)
   const [data, setData] = useState(undefined)
   const controller = useMemo(() => new AbortController(), [])
   const signal = controller.signal

  console.log(infoCountries)

   useEffect(() => {
      isMounted.current = true
		getMoreInfo(`${id}` ,{countries:infoCountries} ,signal).then((res)=>{
			setData(res)
			if(res.status!==200){
				closeinfo()
			}
		}).catch((err)=>{
			
		})
		
      return () => {
         controller.abort()
         isMounted.current = false
      }
   }, [controller ])
   const getGradeName = (id)=>{
	const grade= gradeOptions.filter((el)=>{
		return el.id===id
	})
	return grade[0].name
   }
   let total1 = 0
   let total2 =0
   let total3 = 0;
   
   return (
      <Box className='popUpModal horizontalScrollable' style={boxStyle}>
         <div style={{paddingBottom:'5%'}}>
            <NunitoText value='more info' fontSize={30} fontWeight={600} marginTop={20} />
            <div style={closeIconDivStyle}>
               <CloseIcon style={closeIconStyle} onClick={()=>closeinfo()} />
            </div>
			<h4><span style={{fontWeight:'bolder'}}>Appendix</span>: Total participants / <span style={{color:'green'}}>Total participants with answers uploaded</span> / <span style={{color:'red'}}>absentees</span></h4>
           {data!==undefined&&data.status=== 200&&<table className='table'>
	<tr>
		<th>Country/Grade</th>
		{data.countries.map(el=>{
			return	<th>{el.name}</th>
		})}
		<th>Total</th>	
	</tr>
		{data.grades.sort(function(a, b){return a - b}).map(el=>{
			const grade = data.data[el]
			const totalData = grade['total']
			
			return <><tr><th>{getGradeName(el)}</th>
			{
				data.countries.map((elem)=>{
					const grade = data.data[el]
					const countrydata = grade[elem.name]
					console.log(countrydata)
					return countrydata?<th>{<span>{countrydata.total_participants!==undefined?countrydata.total_participants:'0'}</span>} \ {<span style={{color:'green'}}>{countrydata.total_participants_with_answers!==undefined?countrydata.total_participants_with_answers:'0'}</span>} \ {<span style={{color:'red'}}>{countrydata.absentees!==undefined?countrydata.absentees:'0'}</span>}</th>:<th>0 \ 0 \ 0</th>
				})
			}
			{totalData?<th>{totalData.total_participants!==undefined?totalData.total_participants:'0'} \ {<span style={{color:'green'}}>{totalData.total_participants_with_answers!==undefined?totalData.total_participants_with_answers:'0'}</span>} \ {<span style={{color:'red'}}>{totalData.absentees!==undefined?totalData.absentees:'0'}</span>}</th>:<th>0/0/0</th>}
			</tr></>
		})}
	<tr><th>Total</th>
	{data.countries.map(el=>{
		const totalData = data.data.total[el.name]
		total1+=totalData.total_participants
		total2+=totalData.total_participants_with_answers
		total3+=totalData.absentees
		return 	totalData?<th>{totalData.total_participants!==undefined?totalData.total_participants:'0'} \ {<span style={{color:'green'}}>{totalData.total_participants_with_answers!==undefined?totalData.total_participants_with_answers:'0'}</span>} \ {<span style={{color:'red'}}>{totalData.absentees!==undefined?totalData.absentees:'0'}</span>}</th>:<th>0 \ 0 \ 0</th>
	})}
	<th>{total1} \ {<span style={{color:'green'}}>{total2}</span>} \ {<span style={{color:'red'}}>{total3}</span>}</th>
	</tr>	
</table>}
         </div>
        
      </Box>
   )
}