import React, { useState, useEffect, useMemo, useRef } from 'react'
import { Box, Button, Grid, Modal, TextField } from '@mui/material'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import ClearIcon from '@mui/icons-material/Clear'
import { Link } from 'react-router-dom'

import { useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { showInfoSwal, showWarningSwal } from '../functions/alert'
import { getMarkingPreparation, getLevelCountries, getMoreInfo, getCompCountries } from '../functions/getData'
import { patchMarking, patchMarkingPreparation } from '../functions/patchData'
import { showNotification } from '../functions/snackbar'
import { cancelAction } from '../functions/general'
import CloseIcon from '@mui/icons-material/Close'
import { FaLayerGroup } from 'react-icons/fa'
import AddLevelModal from '../components/MarkingPreparation/AddLevelModal'
import DeleteModal from '../components/modal/DeleteModal'
import NunitoText from '../components/general/NunitoText'
import ReusableAutocompleteWithID from '../components/general/ReusableAutocompleteWithID'
import ReusableButton from '../components/general/ReusableButton'
import ReusableTextField from '../components/general/ReusableTextField'
import Loader from '../components/general/Loader'
import NoData from '../components/general/NoData'
import Widget from '../components/general/Widget'
import TitleBar from '../components/general/TitleBar'
import AddPageWrapper from '../components/general/AddPageWrapper'
import LoadingBackdrop from '../components/general/LoadingBackdrop'
import {HiUserGroup} from 'react-icons/hi'
import { useSnackbar } from 'notistack'
import InfoTable from '../components/MarkingPreparation/InfoTable'
import { deleteGroup } from '../functions/deleteData'
import Markingbar from '../components/markingProgressBar'
import { AwardStats, AwardStatsPrecent } from '../functions/postData'


const Group =({canbeComputed,el ,computingData, setOnMoreInfo ,setSelected, selectedCountries, setCurrentGroup , setInfoCountries , format , openDeleteModal,getData , computeall})=>{
    const [countries , setCountries] = useState(el.countries.map(c => (c.id)))
    const [countreisNames , setNames] = useState('')
    const { enqueueSnackbar, closeSnackbar } = useSnackbar()
    let names = ''
    el.countries.map(el =>{
     
            names+=el.name+' ,'
      })
      names = names.slice(0 , names.length-1)
    const save = ()=>{
      let countriesobj = {}
      countries.map((el,index)=>{
         countriesobj[index] = el
      })
     let payload={
         name:el.name,
         countries:countriesobj 
      }
    if(countries.length<=0){
      showWarningSwal('select atleast one country')
    }else{
      patchMarking(el.id , payload).then(res=>{
         if(res.status===200){
         showNotification('success', res.message, enqueueSnackbar, closeSnackbar)}
      })
    }
    }

    const del = (id)=>{
    
    }
    const clearIconStyle = { fontSize: 36, cursor: 'pointer' , position:'absolute' , right:'1%' , top:'2%' }
    return(
      <Grid style={ { margin: 30, border: '1px solid #707070', borderRadius: 12, padding: 36 , width:'45%' , textAlign:'center' , position:'relative' }}>
         {format==='Local'&&<ClearIcon onClick={()=>openDeleteModal(el.id)} style={clearIconStyle}></ClearIcon>}
                        <h2><HiUserGroup/> {el.name}</h2>
                        
                        {/* <select style={{width:'100%' , height:'50px' , borderRadius:'8px' , textAlign:'center' , fontSize:'25px' , marginBottom:'15px'}}>
                        <option selected hidden>Country</option>
                           {el.countries.map(el=>{
                              return <option value={el.id}>{el.name}</option>
                           })}
                        </select> */}
                        {/* <ReusableAutocompleteWithID type='withLabel' width={400} bgColor='#FFF' borderColor='#707070'
                              state={countries} setState={setCountries} placeholder='Countries*' multiple fullWidth
             
                            options={el.countries.map(c => ({ id: c.id, option: c.name }))} required /> */}
                           <Grid style={{
                              width:'100%',
                              border:'1px solid #999',
                              'borderRadius':'18px',
                              padding:'15px'
                           }}>{names}</Grid>
                        <Grid container justifyContent={'space-between'} alignItems={'center'} width={'100%'}>
                           <Grid width={'30%'}  display={'flex'} justifyContent={'space-between'} alignItems={'start'}>
                  <h5  style={{cursor:'pointer'}} onClick={()=>{
                     setOnMoreInfo(true)
                     setCurrentGroup(el.id)
                     setInfoCountries(countries)
                  }}>More Info</h5>
                  {canbeComputed&&<h5 style={{cursor:'pointer',textDecoration:'none !important' , color:'black'}}><Link to={`/awardsStats?id=${el.id}`} style={{textDecoration:'none' , color:'black'}}> Statisticals Page </Link></h5>}
                  </Grid>
                  
                 {/* {canbeComputed&&<Markingbar computingData={computingData} computeall={computeall} getdata={getData} title={'Compute Awards'} name={el.name} isGlobal={false} id={el.id} countreis={selectedCountries} setSelected={setSelected}/>} */}
                  
                  
               </Grid>
               </Grid>
    )
}
const containerStyle = { paddingInline: 30 }
const widgetContainerStyle = { padding: 24 }
export default function MarkingPreparation() {
   document.title = 'Marking Preparation'
   const isMounted = useRef(false)
   const [loading, setLoading] = useState(false)
   const [data, setData] = useState(false)
   const [loadingData, setLoadingData] = useState(false)
   const [firstLoaded, setFirstLoaded] = useState(false)
   const [onAddLevel, setOnAddLevel] = useState(false)
   const [onMoreinfo , setOnMoreInfo] = useState(false)
   const [onDelete, setOnDelete] = useState(false)
   const [id, setId] = useState('')
   const [competition_id, setCompid] = useState()
   const [currentgroup , setCurrentGroup] = useState(1)
   const [infoCountries , setInfoCountries] = useState([])
   const [competitionName, setCompetitionName] = useState('')
   const [canbeComputed,setCanbeComputed] = useState()
   const [countries , setCountries] = useState([])
   const [format, setFormat] = useState('')
   const [levels, setLevels] = useState([])
   const [levelOptions, setLevelOptions] = useState([])
   const [round, setRound] = useState('')
   const [roundOptions, setRoundOptions] = useState([])
   const [reload , setReload] = useState(false)
   const [selectedCountries , setSelected] = useState(false)
   const [computeall , setComputeall] = useState(false)
   const [computingData , setComputingData] = useState([])
   const [overAllcomputingStatus , setoverAllCOmputingStatus] = useState('')
   const cancelAddLevel = () => cancelAction(setOnAddLevel)
   const cancelDelete = () => cancelAction(setOnDelete)
   const controller = useMemo(() => new AbortController(), [])
   const signal = controller.signal
   let location = useLocation()
   const openDeleteModal = id => {
      setId(id)
      setOnDelete(true)
   }
   const afterAddingLevel = ()=>{

   }
   const { enqueueSnackbar, closeSnackbar } = useSnackbar()
   const getData = ()=>{
      getMarkingPreparation(`${location.search.slice(4)}`, signal).then(d => {
           console.log(d)
         setLoadingData(true)
         setCompetitionName(d.header_data.name)
         setFormat(d.header_data.format ? 'Global' : 'Local')
         setData(d.data)    
         setCompid(d.header_data.competition_id)           
         setCanbeComputed(d.header_data.isComputed)
            setLoadingData(false)
         
      }).catch(e => {
         if (!signal.aborted && isMounted.current) {
            setFirstLoaded(true)
            setLoadingData(false)
         }
      })
   }
   useEffect(() => {
      isMounted.current = true
      if (location.search.slice(0, 4) === '?id=') {
         getData()
         getCompCountries('?mode=not_grouped',`${location.search.slice(4)}` , signal).then(res=>{
            console.log(res)
            let countreis = res.countries
            let countriesarr =[]
            for(const countrey in countreis){
               let obj = {
                  name: countreis[countrey],
                  id: Number(countrey)
               }
               countriesarr.push(obj)
               
            }
            setCountries(countriesarr)
         })
      } else {
         showWarningSwal('Wrong query')
      }
      return () => {
         controller.abort()
         isMounted.current = false
      }
      
                   

   }, [location.search, controller, signal , setOnAddLevel ])
   useEffect(() => {
   }, [setOnAddLevel , onAddLevel])
  
useEffect(()=>{
   let temparr = []
   data&& data.map(el=>{
       temparr.push(el.id)
    })
   if(computeall){
    
      setTimeout(()=>{
         AwardStatsPrecent({
            "groups":temparr
          }).then(res=>{
            console.log(res.status)
            setComputingData(res.group_jobs)
            setoverAllCOmputingStatus(res.status)
            getData()
            if(overAllcomputingStatus!=='Finished'){
            setReload(!reload)}
          })
      },3000)
   }else if(overAllcomputingStatus==='Finished'){
         getData()
         showNotification('success', 'Groups Computed Successfully', enqueueSnackbar, closeSnackbar)
            
   }
},[computeall , setoverAllCOmputingStatus , overAllcomputingStatus , setComputeall,setReload , reload])

   const deleteFunction = () => {
      setLoading(true)
      // let payload = { id: id }
      // deleteMarkingPreparation(payload).then(d => {
      //    if (d.status === 200) {
      //       cancelDelete()
      //       showNotification('success', d.message, enqueueSnackbar, closeSnackbar)
      //       if (isMounted.current) {
      //          setLevels(levels.filter(l => l.id !== id))
      //       }
      //    }
      // }).catch(e => console.log(e)).finally(() => isMounted.current && setLoading(false))

      deleteGroup(id).then(res=>{
         if(res.status===200){
            showNotification('success', res.message, enqueueSnackbar, closeSnackbar)
            cancelDelete()
            setLoading(false) 
            window.location.reload()}
      })
        
      
   }
   const computeAllgroups = ()=>{
      setComputeall(true)
      let temparr = []
      data.map(el=>{
         temparr.push(el.id)
      })
       AwardStats(location.search.slice(4),{
         "groups":temparr
       }).then((res)=>{
         showNotification('success', res.message, enqueueSnackbar, closeSnackbar)
       })
   } 
   return (
      <Box className='wrapperBox'>
         <LoadingBackdrop loading={loading} />
         <div className='firstRowContainer dashboardAndSelfBtnDiv'>
            <ReusableButton text='Dashboard' fontSize={14} bgColor='#F16774' height={36} width={125} br={18} to='/dashboard' iconType='home' />
            <ChevronRightIcon />
            <ReusableButton text='Competitions' fontSize={14} bgColor='#F16774' height={36} br={18} to='/competitions' />
         </div>
         <Grid className='tableContainer' container>
            <NunitoText value='Marking Preparation' fontSize={40} fontWeight={700} italic color='#144A94' />
            <TitleBar fixed main>
               {/* {roundOptions.length > 1 && <ReusableAutocompleteWithID type='default' width={400} height={50} bgColor='#FFF'
                  borderColor='#707070' state={round} setState={onChangeRound} placeholder='Round' marginRight={20}
                  options={roundOptions.map(r => ({ id: r.id, option: r.name }))} required />} */}

               {format === 'Local' && <ReusableButton text='Add group' fontSize={15} bgColor='#5E75C3' height={46}
                  marginRight={60} onClick={() => setOnAddLevel(true)} iconType='add' />} 

                   
            </TitleBar>
            <AddPageWrapper main>
               <Grid container style={containerStyle}>
                  {loadingData && <Loader height={110} />}
                  <Grid container style={widgetContainerStyle}>
                     {competitionName && <Widget title='Competition' content={competitionName} marginRight={28} />}
                     {format && <Widget title='Format' content={format} marginRight={28} />}
                     {round !== '' && <Widget title='Round'
                        content={roundOptions.find(r => r.id === round).name} marginRight={28} />}
                  </Grid>
                  <Grid container>
                     {data.length>0&&data.map(el=>{
                        return <Group canbeComputed={canbeComputed} computingData={computingData} computeall={computeall} getData={getData} selectedCountries={selectedCountries} setSelected={setSelected} openDeleteModal={openDeleteModal} el={el} setCurrentGroup={setCurrentGroup} setOnMoreInfo={setOnMoreInfo} setInfoCountries={setInfoCountries} format={format}></Group>
                     })
                    }
                  </Grid>
                 

               </Grid>
            </AddPageWrapper>
         </Grid>
         {format === 'Local' && <Modal open={onAddLevel} onClose={() => cancelAddLevel()}>
            <>
               <AddLevelModal id={competition_id} setOnAddLevel={setOnAddLevel} levels={levels} setLevels={setLevels}
                  availableCountries={countries} levelOptions={levelOptions} setLoading={setLoading}
                  cancelAddLevel={cancelAddLevel} afterAddingLevel={afterAddingLevel} />
            </>
         </Modal>}
         {onMoreinfo&&<Modal open={onMoreinfo} onClose={()=>setOnMoreInfo(false)}>
<InfoTable closeinfo = {()=>{setOnMoreInfo(false)}} id={competition_id} infoCountries={infoCountries}></InfoTable>
</Modal>}
         <Modal open={onDelete} onClose={() => cancelDelete()} >
            <>
               {<DeleteModal table={{ data: levels.map(l => ({ ...l, name: levelOptions.find(o => o.id === l.level_id)?.name })) }}
                  id={id} selected={[]}
                  deleteFunction={deleteFunction} cancelDelete={cancelDelete} />}
            </>
         </Modal>
      </Box>
   )
}