import React from 'react'
import { Box, Grid } from '@mui/material'
import NunitoText from '../general/NunitoText'
import ReusableButton from '../general/ReusableButton'
import { useSnackbar } from 'notistack'
import { useState } from 'react'
import { showNotification } from '../../functions/snackbar'
import { elemParticipants } from '../../functions/postData'

export default function ElemParticipant({setOnElm}) {
    const [partstring , setpartString] = useState("")
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();

    const submit=()=>{
        let arr = partstring.split(/\r?\n/);
       const filtered= arr.filter(el=>{
            return el!==''&&el.charAt(0)!='-'
        })
        console.log(filtered)
        elemParticipants({participants:filtered}).then(res=>{
            if(res==200||res==201){
                showNotification(  "success",
                res.message,
                enqueueSnackbar,
                closeSnackbar)
            }
        })
    }
    return  <Box className='popUpModal horizontalScrollable' style={{ width: 1200, height: 600 }}>
    <NunitoText value='Elimnate Participants' fontSize={30} fontWeight={600} color='#5E75C3' marginTop={20} />
    <Grid>
       <textarea onChange={(e)=>{setpartString(e.target.value)}} style={{width:'100%' , height:'400px',borderRadius:'25px' , padding:'2%',fontSize:'25px',overflowY:'scroll',resize:'none'}} placeholder='Copy and Paste the Students Indexes that need to be Elimenated from the INDEX NUMBER column as such
       123334
       213144
       213124
       421345
       312456
       -
       213123
       42213
       213144
       213124
       421345
       312456
       -
       213123
       422134'/>
    </Grid>
    <Grid justifyContent={'space-between'} container width={'80%'} alignSelf={'center'}>
        <ReusableButton  text={'Elimnate'}  fontSize={22} height={60} width={300} bgColor='#5E75C3' onClick={()=>{
            submit()
        }} marginBottom={20} />
        <ReusableButton  text={'Cancel'}  fontSize={22} height={60} width={300} bgColor='#E83042' onClick={()=>{
        setOnElm(false)
        }} marginBottom={20} />
    </Grid>
</Box>
}