import { Box, Button, Grid, Modal } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { downloadCheater, getAwardStatStatus, getCheaters, getCountries2 } from '../functions/getData'
import { showNotification } from '../functions/snackbar'
import { useSnackbar } from 'notistack'
import { AwardStats, AwardStatsPrecent } from '../functions/postData'
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min'
import { useMemo } from 'react'
import NunitoText from '../components/general/NunitoText'
import ReusableButton from '../components/general/ReusableButton'
import CheatingBar from '../components/CheatingBar'
import CheatDownloadModal from '../components/modal/CheatDownloadModal'
import ComputeCheating from '../components/modal/ComputeCheating'
import ElemParticipant from '../components/modal/ElemParticipant'
export default function CheatingList(){
    const [percentage , setPercentage] = useState(0)
    const [compName , setCompName] = useState('')
    const [canBeComputed , setCanBeComputed] = useState(true)
    const [recomute , setRecompute] = useState(false)
    const [canBeDownloaded , setCanBeDownload] = useState(false)
    const [reCallEndpoint, setRecallEndpoint] = useState(false)
    const [reload , setReload] = useState(true)
    const [onDownload , setOnDownload] = useState(false)
    const [onElem , setOnElem] = useState(false)
    const [comp , onComp] = useState(false)
    const [err , setError] = useState()
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const [allCountryOptions , setallCountryOptions] = useState()
    let location = useLocation()
    const controller = useMemo(() => new AbortController(), [])
    const signal = controller.signal
    const ProgressBar = ({ percentage,width , margin }) => <div style={{
        height: 40, width: width, borderRadius: 6,margin:'0 auto',
        background: `linear-gradient(90deg, #5E75C3 ${percentage}%, #D7D7D7 0%)`
     }} />
     const handleResponseCases = (status,msg)=>{
        if(status===206){
            setPercentage(0)
            setCanBeComputed(true)
            setCanBeDownload(false)
            setRecallEndpoint(false)
        }else if(status===202||status===201){
            setCanBeComputed(false)
            setRecompute(true)
            setCanBeDownload(false)
            setRecallEndpoint(true)
        }else if(status===200){
            setCanBeComputed(false)
            setRecompute(true)
            setCanBeDownload(true)
            setRecallEndpoint(false)
        }else{
            setCanBeComputed(false)
            setRecompute(true)
            setCanBeDownload(false)
            setRecallEndpoint(false)
            setError(msg)
        }
     }
     const downloadcsv = (fileName,country)=>{
           downloadCheater(location.search.slice(4) , fileName,country)
          
     }
     const compute = (param)=>{
        if(true){
            param+='&recompute=1'
        }
        getCheaters(location.search.slice(4),`?${param}`,signal).then(res=>{
            handleResponseCases(res.status , res.message)
            setPercentage(0)
            console.log(res , 'in botton')
            if(res.status!==417){
                showNotification(  "success",
                res.message,
                enqueueSnackbar,
                closeSnackbar)
            }
        })
 }
   useEffect(()=>{
    getCountries2(`?competition_id=${location.search.slice(4)}`).then(res=>{
        setallCountryOptions(res)
    })
            getCheaters(location.search.slice(4),'?get_status=1',signal).then((res)=>{
                handleResponseCases(res.status , res.message)
                console.log(res)
                setPercentage(res.progress)
                setCompName(res.competition)
            })
   },[location.search , signal])
     
    useEffect(()=>{
        if(reCallEndpoint){
            console.log('reloaded' , percentage)
            setTimeout(() => {
        getCheaters(location.search.slice(4),'?get_status=1',signal).then((res)=>{
            setPercentage(res.progress)
            handleResponseCases(res.status , res.message)
            setReload(!reload)
        })
    }, 5000);
}
    },[location.search , signal   , reCallEndpoint , setReload , setRecallEndpoint,reload])
    return <Box className='wrapperBox'>
                 <Grid container alignItems={'center'} justifyContent={'space-between'}>
                <NunitoText value={`${compName} Cheating List`} fontSize={40} fontWeight={700} italic color='#144A94' align={'left'} marginLeft={'20px'} marginTop={'20px'} />
                <Grid >
                <ReusableButton
                        text={"Elimnate Participants"}
                        fontSize={15}
                        bgColor={canBeDownloaded ? "#5E75C3" : "red"}
                        height={46}
                        marginRight={30}
                        // onClick={() => computeAll()}
                        onClick={canBeDownloaded?()=>{setOnElem(true)}:() => {}}
                        iconType="delete"
                        />
                    <ReusableButton
                        text={recomute?"Re-Compute":"Compute"}
                        fontSize={15}
                        bgColor={canBeComputed||recomute ? "#5E75C3" : "red"}
                        height={46}
                        marginRight={30}
                        // onClick={() => computeAll()}
                        onClick={canBeComputed||recomute?()=>onComp(true):()=>{}}
                        iconType="compute"
                        />
                          <ReusableButton
                        text="Download Csv"
                        fontSize={15}
                        bgColor={canBeDownloaded ? "#5E75C3" : "red"}
                        height={46}
                        marginRight={60}
                        
                        // onClick={() => computeAll()}
                        onClick={canBeDownloaded?()=>{
                            setOnDownload(true)
                        }:() => {}}
                        iconType="download"
                        />
                </Grid>
                </Grid>
              
                <CheatingBar reCallEndpoint={reCallEndpoint} percentage={percentage} handleResponseCases={handleResponseCases} setPercentage={setPercentage} id={location.search.slice(4)}/>
                <Modal open={onDownload} onClose={() => setOnDownload(false)} err={err}>
                    <>
                    {(canBeDownloaded)&&<CheatDownloadModal downloadcsv={downloadcsv} compName={compName}  allCountryOptions={allCountryOptions} />}
                    </>
                </Modal>
                <Modal open={comp} onClose={() => onComp(false)} >
                    <>
                    {(canBeComputed||recomute)&&<ComputeCheating comp={compute} onComp={onComp}  allCountryOptions={allCountryOptions}/>}
                    </>
                </Modal>
                <Modal open={onElem} onClose={() => setOnElem(false)} err={err}>
                    <>
                    {(canBeDownloaded)&&<ElemParticipant  setOnElm={setOnElem} />}
                    </>
                </Modal>
           </Box>
}