import React, { useState, useEffect, useCallback, useMemo } from "react";
import { Box, Grid } from "@mui/material";
import { CSVLink } from "react-csv";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import CSVUploadTable from "../components/table/CSVUploadTable";
import CSVUploadFilter from "../components/CSVUploadFilter";
import ReusableToggle from "../components/general/ReusableToggle";
import NunitoText from "../components/general/NunitoText";
import ReusableTextField from "../components/general/ReusableTextField";
import ReusableSelect from "../components/general/ReusableSelect";
import ReusableButton from "../components/general/ReusableButton";
import ReusableCheckbox from "../components/general/ReusableCheckbox";
import ReusableAutocompleteWithID from "../components/general/ReusableAutocompleteWithID";
import {
  invalidName,
  invalidNumber,
  invalidEmail,
  delimitCSVRow,
  gradeOptions as allGradeOptions,
  makeSureIsArray,
} from "../functions/general";
import { showWarningSwal, isEmpty, warningMessage } from "../functions/alert";
import { showNotification } from "../functions/snackbar";
import {
  isAdmin,
  isAdminOrPartnerOrAssistant,
  isManagerOrTeacher,
  isPartnerOrAssistant,
  isFromTuitionCentre,
} from "../functions/checkrole";
import { useSnackbar } from "notistack";
import Loader from "../components/general/Loader";
import { uploadParticipantAnswers } from "../functions/postData";
import { useLocation } from "react-router-dom";

import * as XLSX from "xlsx";
import { getCompetition } from "../functions/getData";

const gridStyle = { textAlign: "center" };
const flexStyle = { display: "flex" };
export default function UploadStudentAnswer() {
  let location = useLocation();

  const controller = useMemo(() => new AbortController(), []);
  const signal = controller.signal;
  const [contestName, setName] = useState("");
  const [competition, setCompetition] = useState([]);
  document.title = "Upload Answer";
  const [round, setRound] = useState();
  const [level, setLevel] = useState("");
  const [errors, setErrors] = useState([]);
  const [levelOptions, setLevelOptions] = useState([]);

  useEffect(() => {
    const controller = new AbortController();
    const signal = controller.signal;
    if (location.search.slice(0, 4) === "?id=") {
      getCompetition(`/${Number(location.search.slice(4))}`, signal).then(
        (res) => {
          const competition = res;
          setRound(competition.rounds[0].id);
          setName(competition.name);
          setCompetition(res);
        }
      );
    }
    return () => {
      controller.abort();
    };
  }, [controller, signal]);
  useEffect(() => {
    if (competition.rounds) {
      let levelOptionss = [];
      competition.rounds.map((round, index) => {
        round.levels.map((el) => {
          let obj = { id: el.id, option: `round${index + 1} ${el.name}` };
          levelOptionss.push(obj);
        });
      });
      setLevelOptions(levelOptionss);
    }
  }, [competition, setName]);

  const history = useHistory();
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const [headers, setHeaders] = useState([]);
  const [csvFile, setCSVFile] = useState(null);
  const [csvHeaders, setCSVHeaders] = useState();
  const [csvMapFields, setCSVMapFields] = useState();
  const [csvArray, setCSVArray] = useState([]);
  const [copyCSVArray, setCopyCSVArray] = useState([]);
  const [headerError, setHeaderError] = useState(false);
  const [groupError, setGroupError] = useState(false);
  const [dubsError, setDubsError] = useState(false);
  const [list, setList] = useState([]);
  const [num, setNum] = useState(0);
  //Select from the table
  const [selected, setSelected] = useState([]);
  const [selecting, setSelecting] = useState([]);
  const [sort, setSort] = useState("");
  const [okToHvDupes, setOkToHvDupes] = useState(false);
  const [loading, setLoading] = useState(false);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const delimit = (input) => {
    let tokens = [];
    let startPosition = 0;
    let isInQuotes = false;
    for (
      let currentPosition = 0;
      currentPosition < input.length;
      currentPosition++
    ) {
      if (input.charAt(currentPosition) === '"') {
        isInQuotes = !isInQuotes;
      } else if (input.charAt(currentPosition) === "," && !isInQuotes) {
        tokens.push(input.substring(startPosition, currentPosition));
        startPosition = currentPosition + 1;
      }
    }
    let lastToken = input.substring(startPosition);
    if (lastToken === ",") {
      tokens.push("");
    } else {
      tokens.push(lastToken);
    }
    return tokens;
  };
  // CSV File related functions
  const openFileInput = () =>
    document.getElementById("file-upload-for-csv").click();
  const onChangeCSVFile = (e, file) => {
    cancelCSVFile();

    // e.target.value = null
    // setCSVFile(file)
    // const reader = new FileReader()
    // try {
    //     reader.onload = e => {
    //         const text = e.target.result
    //         processCSV(text)

        //     }
        //     reader.readAsText(file)
        // } catch (err) {
        //     console.log(err.message)
        // }
        e.target.value = null
        const reader = new FileReader()
        if (file.type === 'text/csv') {
          setCSVFile(file)
            try {
                reader.onload = e => {
                    const text = e.target.result
                    processCSV(text, ',', file.type)
                }
                reader.readAsText(file)
            } catch (err) {
            }
        } else if (file.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') {
          setCSVFile(file)
            try {
                const rABS = !!reader.readAsBinaryString;
                reader.onload = e => { // e = on_file_select event
                    /* Parse data */
                    const bstr = e.target.result;
                    const wb = XLSX.read(bstr, { type: 'binary' })
                    /* Get first worksheet */
                    const wsname = wb.SheetNames[0]
                    const ws = wb.Sheets[wsname]
                    /* Convert array of arrays */
                    const text = XLSX.utils.sheet_to_csv(ws, { header: 1 })
                    /* Update state */
                    processCSV(text, ',', file.type)
                };
                if (rABS) reader.readAsBinaryString(file)
                else reader.readAsArrayBuffer(file)
            } catch (err) {
            }
        } else {
            alert('FUCKOFF')
        }

       
    }
    const processCSV = (str, delim = ',' , type) => {
      
          //Get the header from the large chunk of string read from the csv file
          const header = str.slice(0, str.indexOf('\n')).trim().replace(/['"]+/g, '').split(delim)
          // Get all the data from the large chunk of string read from the csv file as an array
           setCSVHeaders(header)
        let headerarr = []
        header.map((el,key)=>{
          let obj = {
            id:el,
            label:el,
            key:el
          }
          headerarr.push(obj)
        })
        setHeaders(headerarr)
        //Set an object of mapFields
        let newMapFields = {}
        headers.forEach((h, i) => {
            if (header[i]) newMapFields[h.id] = header[i]
        })
        setCSVMapFields(newMapFields)
        const rows = str.slice(str.indexOf("\n") + 1).split("\n");
          const newArray = rows.map(row => {
            let rowarr = row.split("");
            rowarr.map((el, index) => {
              if (el === "," && rowarr[index - 1] === ",") {
                rowarr.splice(index, 0, " ");
              }
            });
      
            const values = delimitCSVRow(rowarr.join(""));
              const eachObject = header.reduce((obj, header, i) => {
                  //This function will return an array with the last item being an object with undefined values
                  //So we check if the object is undefined before using string functions on the object
                  obj[header] = values[i]
                      ? values[i].trim().replace(/["]+/g, '')
                      : values[i]
                  return obj
              }, {})
              return eachObject
          })
            const a = newArray.filter((n, i) => i < newArray.length)
            console.log(a)
        if (isAdmin()) {
            setCSVArray(a)
            //Keep the original as reference and use the copyCSVArray to decide which columns to map to which field
            setCopyCSVArray(a)
            submitCSVAsList(a,headerarr)
        } else {submitCSVAsList(a , headerarr)
        }
       
         
        // if (!isAdmin()) submitCSVAsList(a)
    }
    const cancelCSVFile = () => {
        setCSVFile(null)
        setCSVHeaders()
        setCSVMapFields()
        setCSVArray([])
        setCopyCSVArray([])
        setList([])
        setSort('')
    }
    const validateGroup = (group)=>{
          let valid = true

       let arr =group.split(' ')
      
        let first = arr[0];
        let scnd = arr[1];
     
        if(first===undefined||first!=='Grade'){
         
            valid = false
        }
        if(scnd===undefined|| isNaN(Number(scnd))){
           
            valid =false
        }
        if(arr.length>2){
           
            valid = false
        }
        //this is temporarly canceled so it will alwayes return true
  return true;      
}
    const submitCSVAsList = (data,headerarr) => {
        let newArr = []
        data.forEach((row, INDEX) => {
            let newObj = {}
            newObj.id = INDEX

            headerarr.forEach(({ key }, i) => {
              newObj[key] = Object.values(row)[i]
            })
            newArr.push(newObj)
        })
        
        let templist = newArr
        
        templist.map((el , index)=>{
           
          

            if(checkError(templist , el)){
                const fromIndex = index;
                const element = templist.splice(fromIndex, 1)[0];
                templist.splice(0, 0, element);
            }
        })
        
       setList(templist)
       console.log(templist , headers , data)

    }
    
    const checkSelect = () => {
        // if(level===''){
        //   return showWarningSwal('Please select a Level')
        // }
        return openFileInput()
    }
   
    const onSubmit = () => {
      let payload= {}
      let rowsWithError = []
      let particpaents = []
//    let dubserror = false;
//    let gradeerr = false
//       let indexheader = Object.keys(list[0])[1]
//       let gradeheader = Object.keys(list[0])[2]
//       let sampleanswerheader =Object.keys(list[0])[3]
      console.log(dubsError)
    //  if(indexheader!=='INDEX NUMBER'||gradeheader!=='Grade'||sampleanswerheader!=='Q1'){
    //     return showWarningSwal('there is an error in your file header formation , make sure your header is as follows <br> INDEX NUMBER , Grade , Q1 , Q2 , ...')
    //  }
    //   list.forEach((row,INDEX) => {
       
     
    //       if (checkForErrorInRow(row)) rowsWithError.push(row)
    //       if(checkError(list,row)&&!validateGroup(row.Grade)){
                
    //         dubserror = true;
    //          gradeerr = true
          
    //     }else if(checkError(list,row)){
    //         dubserror = true;
    //     }else if(!validateGroup(row.Grade)){
    //         gradeerr = true
    //     }
          
    //   })
    //   if(rowsWithError.length>0){
    //    if(dubserror&&gradeerr){
    //     return showWarningSwal('there are Dubplicate indexes in the red marked columns , make sure all indexes are unique<br>the grade formation is wrong in the red marked columns make sure the input is "Grade" followed by the grade number')
    //    }else if(gradeerr){
    //     return showWarningSwal('the grade formation is wrong in the red marked columns make sure the input is "Grade" followed by the grade number')
    //    }else if(dubserror){
    //     return showWarningSwal('there are Dubplicate indexes in the red marked columns , make sure all indexes are unique')
    //    }
    //   }
console.log(list)
      list.map((el)=>{
        let particapObj = {}
        let particINDEX = el['INDEX NUMBER'];
        let answers = []
        let grade = ''
        Object.keys(el).forEach(function(key, INDEX) {
            console.log(key , INDEX)
          if(INDEX>2){
            answers.push(el[key])
          }else if(INDEX==1){
            particINDEX=Number(el[key])
          }else if(INDEX==2){
            grade = el[key]
          }
        });
        particapObj={
          index_number:particINDEX , answers:answers,grade:grade
        }
        particpaents.push(particapObj)
      })
      payload = {
        competition_id : Number(location.search.slice(4)) , 
     
        participants:particpaents
      }
      console.log(payload)
      setLoading(true)
     uploadParticipantAnswers(payload , signal).then(res=>{
      setLoading(false)
      if(res.status!==200){
       
         }else{
        showNotification('success' , res.message , enqueueSnackbar , closeSnackbar)
        window.open(res.report_url, '_blank', 'noreferrer');
        setErrors([])
      }
     }).catch(()=>{
     })
    }
   
    const uploadError = []

    
  
    const checkOrUncheck = () => {
        let rowsWithError = []
        list.forEach(row => {
            if (checkForErrorInRow(row)) rowsWithError.push(row)
        })
        let newSelected = [...selected]
        let newSelecting = [...selecting]
        for (let i = 0; i < rowsWithError.length; i++) {
            if (newSelected.includes(rowsWithError[i].id)) {
                let INDEX = newSelected.INDEXOf(rowsWithError[i].id)
                newSelected.splice(INDEX, 1)
                newSelecting.splice(INDEX, 1)
            } else {
                newSelected.push(Number(rowsWithError[i].id))
                newSelecting.push(list.find(l => l.id === Number(rowsWithError[i].id)).name)
            }
        }
        setSelected(newSelected)
        setSelecting(newSelecting)
    }
    const checkForErrorInRow = row => {
        // // return true upon encountering error at any column
        // if (list === undefined) return false
        // // return true upon encountering error at any row
        // for (let i = 0; i < list.length; i++){ 
        //     if(errors.includes(String(i))){ 
        //         uploadError.push(i)
        //     }
        //     if(checkError(list,row)&&!validateGroup(row.Grade)){
                
        //         setDubsError(true)
        //         setGroupError(true)
        //         return true;
        //     }else if(checkError(list,row)){
        //         setDubsError(true)
        //         return true;
        //     }else if(!validateGroup(row.Grade)){
        //         setGroupError(true)
        //         return true;
        //     }
        // }
        return false
    }
    
    const checkError= (list , row)=>{
      let occurences = 0
      list.forEach(l => {
          if (l['INDEX NUMBER'] === row['INDEX NUMBER']) {
              occurences += 1
          }
      })
      
      return occurences > 1
    }
    const correctColumnsInCSV = arr => {
        return new Set(arr).size === arr.length
        // return Object.keys(csvHeaders).length === headers.length * new Set(arr).size !== arr.length
        //     && new Set(arr).size === arr.length
    }
    // const onClickSubmitCSVMapFields = () => {
    //   setErrors([])
    //     return correctColumnsInCSV(Object.values(csvMapFields)) ? list.length ? null : submitCSVAsList(copyCSVArray) :
    //         showWarningSwal('Please assign only one column to each field.')
    // }
    const templateDivStyle = {
        display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'space-evenly',
        border: '1px solid #707070', borderRadius: 12, height: 220, width: 700
    }
    const massAssignStyle = { display: 'flex', alignItems: 'center', marginRight: 30 }
    const owoHeaders = headers.filter(h => h.id !== 'tuition_centre')
    const selectContainerStyle = {
        border: '1px solid #000', borderRadius: 12,
        backgroundColor: '#F5F5F5', padding: 24,
        
    }
    return (
        <Box style={{ flexGrow: 1 }}>
           
            <Grid container justifyContent='space-between' alignItems='center' style={{ paddingInline: 42, marginTop: 30 }}>
                <NunitoText value={`${contestName} - Upload Participant's Answers`} fontSize={40} fontWeight={700} italic color='#144A94' />
                <Grid container justifyContent='center' alignItems='center' style={{ marginTop: 30 , width:'100%' }}>
                    
                {/* <Grid container justifyContent='flex-start' alignItems='center' style={selectContainerStyle}>
                            
                          
                               
                                <ReusableAutocompleteWithID type='default' width={240} height={54} placeholder='Level'
                                    state={level} setState={setLevel} borderColor='#707070' required
                                    options={levelOptions} grayedOut={!round} readOnly={!round} />
                                    <Grid container style={{ marginTop: 24 }}>
                <ReusableButton text='Reset' bgColor='#F16774' fontSize={16} br={8} onClick={()=>{}} iconType='reset' />
            </Grid>
                           
                           </Grid>   */}
        </Grid>
        {/* school: {JSON.stringify(schoolFiltered)}<br /><br /> */}
        {/* participant: {JSON.stringify(participantFiltered)}<br /><br /> */}
        <Grid
          container
          justifyContent="space-between"
          alignItems={"center"}
          style={{ marginTop: 15 }}
        >
          <div>
            <NunitoText
              value={`File Select:${csvFile ? ` ${csvFile.name}` : ""}`}
              fontSize={20}
              fontWeight={600}
              color="#144A94"
              align="left"
            />
            <input
              id="file-upload-for-csv"
              type="file"
              accept=".csv"
              onChange={(e) => onChangeCSVFile(e, e.target.files[0])}
              style={{ display: "none" }}
            />
            <div
              style={{ display: "flex", alignItems: "center", marginTop: 20 }}
            >
              <ReusableButton
                text="Select File"
                bgColor="#144A94"
                fontSize={16}
                height={59}
                width={160}
                iconType="file"
                onClick={() => checkSelect()}
              />
              {/* <ReusableTextField type='clickOnly' width={500} height={60} bgColor='#F2F2F2'
                                marginLeft={120} onClick={() => checkSelect()} /> */}
                        </div>  
                    </div>
                    <div style={{ width: 700 }}>
                       <p>there should be only one answer for each question and number of answers must no exceed the amount of questions</p>
                       <p>please make sure to add the csv header column like the following INDEX NUMBER , Grade , Q1 , Q2 , ...</p>
                    </div>
                </Grid>
            </Grid>
          
            {isAdmin() && csvHeaders && Boolean(csvArray.length) &&
                <Grid container style={{ paddingInline: 42, marginTop: 20 }}>
                   
                    
                    <Grid container justifyContent="flex-end" style={{ marginBlock: 20 }}>
                        <ReusableButton text='Cancel' bgColor='#8D8D8D' fontSize={16} height={50} width={130} marginRight={20}
                            onClick={() => cancelCSVFile()} />
                        <ReusableButton text='Submit'
                            bgColor={correctColumnsInCSV(Object.values(csvMapFields)) && !list.length ? '#5E75C3' : '#8D8D8D'}
                            fontSize={16} height={50} width={130} onClick={() => onSubmit()} />
                        {/* <ReusableButton text='Submit' bgColor='#5E75C3' fontSize={16} height={50} width={130}
                            onClick={() => submitCSVAsList(copyCSVArray)} /> */}
                    </Grid>
                </Grid>}
            {/* {Boolean(list.length) && <Grid container justifyContent='space-between' alignItems='center' style={{ marginTop: 60 }}>
                <NunitoText value={'Answers'} fontSize={30} fontWeight={800} italic
                    color='#144A94' marginLeft={40} />
                <div style={{ marginTop: 20, paddingRight: 55, display: 'flex', alignItems: 'center' }}>
                    {checkForError(list) && <>
                        <ReusableAutocompleteWithID type='default' state={sort} setState={onChangeSort}
                            placeholder='Sort by error' width={240} borderColor='#000' marginRight={30}
                            options={sortOptions().map(o => ({ id: o.id, option: o.label }))} />
                        <ReusableButton text='Check/Uncheck Errors' bgColor='#5E75C3' fontSize={16} height={50} width={240} iconType='check'
                            marginRight={20} onClick={() => checkOrUncheck()} />
                    </>}
                    <ReusableButton text='Mass Delete' bgColor='#E83D4D' fontSize={16} height={50} iconType='delete'
                        marginRight={20} onClick={() => massDeleteList()} />
                    {Boolean(selected.length) ?
                        <CSVLink filename={'Answers'} style={{ textDecoration: 'none' }} data={list.filter(p => selected.includes(p.id))}>
                            <ReusableButton text={`Export Selected Answers`}
                                fontSize={16} bgColor='#5E75C3' height={50} iconType='export' />
                        </CSVLink> :
                        <ReusableButton text={`Export Selected Answers`} bgColor='#707070'
                            fontSize={16} height={50} iconType='export'
                            onClick={() => showWarningSwal(`Please select at least one Answer(s) to export`)} />}
                </div>
            </Grid>} */}
            {Boolean(list.length) && <Grid container alignItems='center' justifyContent='space-between'
                style={{ marginTop: 20, paddingInline: 42 }}>
                {isAdminOrPartnerOrAssistant() && <Grid container alignItems='center'>
                    {/** this will be mass assign answers*/} 
                    {/* <div style={massAssignStyle}>
                        <ReusableAutocomplete type='table' state={massAssignSchool} setState={setMassAssignSchool}
                            placeholder='School' width={240} borderColor='#000' marginRight={30} freeSolo
                            options={actualMassSchoolOptions.map(s => ({ id: s.id, option: s.name }))} />
                        <ReusableButton text='Mass Assign School' fontSize={15} bgColor='#5E75C3' height={50}
                            onClick={() => massUpdateList('school', massAssignSchool)} />
                    </div> */}
                    
                </Grid>}
               
            </Grid>}
            {loading && <Loader height={800} />}
            {/* <div style={{ display: loading && 'none' }}>
                {Boolean(list.length) && <Grid container style={{ paddingInline: 42 }}>
                    <CSVUploadTable headers={headers} data={list} error={errors}
                       rowsPerPage={rowsPerPage} setRowsPerPage={setRowsPerPage}
                        selecting={selecting} setSelecting={setSelecting} selected={selected} setSelected={setSelected} setState={setList}
                        checkForErrorInRow={checkForErrorInRow} isDuplicate={isDuplicate} isAnwers={true}
                      
                    />
                   
                </Grid>}
                {Boolean(list.length) && <Grid container justifyContent="flex-end" style={{ marginBlock: '50px 20px', paddingInline: 42 }}>
                    <ReusableButton text='Cancel' bgColor='#8D8D8D' fontSize={16} height={50} width={130} marginRight={20}
                        onClick={() => cancelList()} />
                    <ReusableButton text='Submit' bgColor='#5E75C3' fontSize={16} height={50} width={130}
                        onClick={() => onSubmit()} />
                </Grid>}
            </div> */}
            {/* {error && sortErrorByRow(error).map((e, i) => (
                <Grid key={i} container>{JSON.stringify(e)}</Grid>
            ))}<br /> 
           {error && sortErrorByType(error).map((e, i) => (
                <Grid key={i} container>{JSON.stringify(e)}</Grid>
            ))}<br />
            {error && sortErrorByRow(error).map((e, i) => (
                <Grid key={i} container>{JSON.stringify(e)}</Grid>
            ))}<br /> 
             {JSON.stringify(selected)} 
             <p>keys: {JSON.stringify(headers.map(h => h.id))}</p>
            {list.length && JSON.stringify(checkForError(list))}
            {list.length && list.map((l, i) => (
                <div key={i} style={{ border: '1px solid gold', marginBottom: 10 }}>
                    <p>row: {JSON.stringify(l)}</p>
                    <p>error: {JSON.stringify(checkForErrorInRow(l))}</p>
                </div>
            ))} */}
    </Box>
  );
}
