import React from 'react'
import ReactDOM from 'react-dom'
import App from './App.js'
import reportWebVitals from './reportWebVitals.js'
import { StyledEngineProvider } from '@mui/material/styles'

import { Provider } from 'react-redux'
import { PersistGate } from 'redux-persist/integration/react'
import crossBrowserListener from './utils/cross-browser-middleware'
import store, { persistConfig, persistor } from './reducers/store'
window.addEventListener('storage', crossBrowserListener(store, persistConfig))
ReactDOM.render(
   <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
         <StyledEngineProvider injectFirst>
            <App />
         </StyledEngineProvider>
      </PersistGate>
   </Provider>,
   document.getElementById('root')
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
