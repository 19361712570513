import React, { useState, useEffect, useCallback } from 'react'
import { Box, Grid, Modal, IconButton } from '@mui/material'
import Flags from 'country-flag-icons/react/3x2'
import EditUserModal from '../components/User/EditUserModal'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import EditIcon from '@mui/icons-material/Edit'
import RefreshIcon from '@mui/icons-material/Refresh'
import CancelIcon from '@mui/icons-material/Cancel'
import { useSelector } from 'react-redux'
import { checkPassword } from '../functions/general'
import { getBase64 } from '../functions/upload'
import { getProfile } from '../functions/getData'
import NunitoText from '../components/general/NunitoText'
import ReusableTextField from '../components/general/ReusableTextField'
import ReusableButton from '../components/general/ReusableButton'
import Loader from '../components/general/Loader'
import { isPartnerOrAssistant } from '../functions/checkrole'
export const CountryFlag = ({ iso }) => {
    const Flag = Flags[iso]
    return <Flag style={{ width: 60, height: 50 }} />
}
export function EditPasswordModal({ password, setPassword, confirmPassword,
    setConfirmPassword, setOnEditPassword, onCloseEditPassword, onSubmitPassword }) {
    return (
        <Box className='popUpModal' style={{ width: 850, height: 530 }}>
            <div>
                <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <CancelIcon style={{
                        color: '#E83D4D', width: 30, height: 30,
                        marginRight: 10, marginTop: 10, cursor: 'pointer'
                    }} onClick={() => setOnEditPassword(false)} />
                </div>
                <NunitoText value='EDIT PASSWORD' fontSize={30} fontWeight={600} color='#5E75C3' marginTop={-10} />
                <NunitoText value='Enter the new password to be used, or randomly generate one.'
                    fontSize={20} fontWeight={400} color='#5E75C3' marginTop={15} />
                <Grid container style={{ marginTop: 60 }}>
                    <Grid item xs={4} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        <NunitoText value='New Password' fontSize={20} fontWeight={600} italic />
                    </Grid>
                    <Grid item xs={6.2} style={{ display: 'flex', alignItems: 'center' }}>
                        <ReusableTextField type='default' width={435} height={55} bgColor='#F2F2F2'
                            placeholder='Password' state={password} setState={setPassword} />
                    </Grid>
                    <Grid item style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        <RefreshIcon style={{ width: 36, height: 36, marginLeft: 20, cursor: 'pointer' }} />
                    </Grid>
                </Grid>
                <Grid container style={{ marginTop: 50 }}>
                    <Grid item xs={4} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        <NunitoText value='Re-Enter New Password' fontSize={20} fontWeight={600} italic />
                    </Grid>
                    <Grid item style={{ display: 'flex', alignItems: 'center' }}>
                        <ReusableTextField type='default' width={435} height={55} bgColor='#F2F2F2'
                            placeholder='Password' state={confirmPassword} setState={setConfirmPassword} />
                    </Grid>
                </Grid>
                <NunitoText value='Minimum 8 characters with one uppercase, one lowercase letter, one number and one of the following 
                letters: "!$#%@"'  fontSize={16} fontWeight={400} marginTop={20} color={checkPassword(password) && '#E83D4D'} />
                <NunitoText value='Password must match confirm password' fontSize={16} fontWeight={400} marginTop={10}
                    color={(checkPassword(password) || password !== confirmPassword) && '#E83D4D'} />
            </div>
            <Grid container justifyContent='space-around' style={{ marginBottom: 40 }}>
                <ReusableButton text='Cancel' fontSize={22} height={60} width={300} bgColor='#8D8D8D'
                    onClick={() => onCloseEditPassword()} />
                <ReusableButton text='Submit' fontSize={22} height={60} width={300} bgColor='#5E75C3'
                    onClick={() => onSubmitPassword()} />
            </Grid>
        </Box>
    )
}
export default function Profile() {
    document.title = 'Profile'
    const allCountryOptions = useSelector(state => state.countryOptions)
    const [loading, setLoading] = useState(false)
    const [user, setUser] = useState()
    const [country, setCountry] = useState()
    const [imageOpacity, setImageOpacity] = useState(1)
    const [textOpacity, setTextOpacity] = useState(0)
    const [image, setImage] = useState()
    const [onEditUser, setOnEditUser] = useState(false)
    const profilePic = 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAOEAAADhCAMAAAAJbSJIAAAAM1BMVEXk5ueutLfn6eqrsbTp6+zg4uOwtrnJzc/j5earsbW0uby4vcDQ09XGyszU19jd3+G/xMamCvwDAAAFLklEQVR4nO2d2bLbIAxAbYE3sDH//7WFbPfexG4MiCAcnWmnrzkjIRaD2jQMwzAMwzAMwzAMwzAMwzAMwzAMwzAMwzAMwzAMw5wQkHJczewxZh2lhNK/CBOQo1n0JIT74/H/qMV0Z7GU3aCcVPuEE1XDCtVLAhgtpme7H0s1N1U7QjO0L8F7llzGeh1hEG/8Lo7TUmmuSrOfns9xnGXpXxsONPpA/B6OqqstjC6Ax/0ujkNdYQQbKNi2k64qiiEZ+ohi35X+2YcZw/WujmslYewiAliVYrxgJYrdwUmwXsU+RdApUi83oNIE27YvrfB/ZPg8+BJETXnqh9CVzBbTQHgojgiCvtqU9thFJg/CKz3VIMKMEkIXxIWqIpIg2SkjYj+xC816mrJae2aiWGykxRNsW0UwiJghJDljYI5CD8GRiCtIsJxizYUPQ2pzItZy5pcisTRdk/a9m4amtNNfBuQkdVhSaYqfpNTSFGfb9GRIakrE2Pm+GFLaCQPqiu0OpWP+HMPQQcgQMiQprWXNmsVwIjQjYi/ZrhAqNTCgr2gu0Jnz85RSSjso0HkMFZ0YZjKkc26a/jlmh9JiDyDxi9oeorTYAzZkwwoMz19pzj9bnH/GP/+qbchjSGflneWYhtTuKdMOmNKZcJ5TjInQKcYXnESd/jQxy0ENpULTNGOGgxpap/oyw9pbUAqhfx2Dbkhovvfgz4iUzoM9+GlK6/Mh4q29hyC1mwro30hpVVLPF9wYQr71RazOeM5/cw81iBRD+A03aM9/C/obbrKjbYSpCmIVG3qT/Q8oeUo3Rz0IL7vI1tEbCB9pSiu8I/aV8x3Kg/BGWrWp4ZVs0nZfmAoEG4h/61yHYIJiFSl6Q0Vk6tTW1N8kYp8hdOkfHYYMXd2Qft+8CYwqYDSKvqIh+MCF8Wgca2u/cwdgeW3TtuVn6+1oBs3yLo5C2JpK6CvQzGpfUkz9UG/87gCsi5o2LIXolxN0FbwAsjOLEr+YJmXn7iR6N0BCt5p5cMxm7eAsfS+/CACQf4CTpKjzgkvr2cVarVTf96372yut7XLJ1sa7lv6VcfgYrWaxqr3Wlo1S6pvStr22sxOtTNPLzdY3nj20bPP+ejFdJYkLsjGLdtPBEbe/mr2bQKiXWJDroA+vtzc0p9aahuwqHMDYrQEXHEw9jwQl3drMpts9JBU1SdktPe5FBRdJQ6bwXBpa57ib2A8kukQDzMjh++Uo7Fo6Wd02Pkf4fknqoo4HtvAIjsqUcjx6DIPgWCaOML9rKI/oqD9/lgNrn+eF+p7j8tnzHBiR7+kdUGw/+V1Kzkc75mMy6U+FMaxjPibiM1U1uGM+puInHpmALZCgP4pt7i840MV8+0R1zPsRB6UTcqpizncYwZ89syDydfyWCwXB1l8/zRNGWbTG/GHKUm9AkxHMc/EGSk3z2+ArEhPEV5TUBLEvUGFcjEUH80J/jveTGOAJEljJbILWGQT3zRYiwuKsUXN1EEJAzBhRJFll7mBUG7KD8EqPkKekBREaL8hMDZLQSG6AQjtHPYmvTQnX0TtpC1SYCe2YdkkyLP3jj5BSbKiuR585eQhTgoje6yIb0Yb0C+mV6EYvebqw5SDy2WmubogZiF2AVxPC2FpDf8H2Q9QWo6IkjUxTWVEI3WY/wrCeSuqJ+eRWzXR/JXwgVjUMozbCOfoEZiSiKVGepqv5CJ8RyR4D7xBeamqa7z3BJ/z17JxuBPdv93d/a2Ki878MMAzDMAzDMAzDMAzDMF/KP09VUmxBAiI3AAAAAElFTkSuQmCC'
    const getData = useCallback(() => {
        console.log('Getting profile')
        setLoading(true)
        getProfile().then(p => {
            setUser(p)
            if (p.role_id > 1) setCountry(allCountryOptions.find(c => c.id === p.country_id))
            setLoading(false)
        }).catch(e => console.log(e))
    }, [allCountryOptions])
    const openFile = () => document.getElementById(`file-upload`).click()
    const handleFileInputChange = file => {
        getBase64(file).then(result => setImage(result)).catch(err => {
            console.log(err)
            alert(`Error at handeFileInputChange: ${JSON.stringify(err)}`)
        })
    }
    const onHoverImage = enter => {
        if (enter) {
            setImageOpacity(0.3)
            setTextOpacity(1)
        } else {
            setImageOpacity(1)
            setTextOpacity(0)
        }
    }
    const containerStyle = { marginTop: 50 }
    const firstHalfStyle = { display: 'flex', justifyContent: 'center' }
    const secondHalfStyle = { display: 'flex', flexDirection: 'column', justifyContent:'space-between', alignItems: 'center' }
    const leftSideDivStyle = {
        border: '1px solid #000', backgroundColor: '#F8F8F8', borderRadius: 16, width: 580, paddingBlock: 40,
        display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'space-evenly'
    }
    const imageDivStyle = {
        display: 'flex', alignItems: 'flex-end', marginBottom: 30, position: 'relative', borderRadius: '50%', cursor: 'pointer'
    }
    const imageStyle = { borderRadius: '50%', opacity: imageOpacity }
    const centerTextStyle = {
        position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)',
        textAlign: 'center', width: '100%', userSelect: 'none', opacity: textOpacity
    }
    const editImageIconStyle = {
        backgroundColor: '#FF7600', color: '#FFF', width: 36, height: 36,
        marginLeft: -40, opacity: imageOpacity
    }
    const countryContainerStyle = { display: 'flex', alignItems: 'center', marginBottom: 30 }
    useEffect(() => {
        getData()
        // setCountry(allCountryOptions.find(c => c.id === user.country_id))
    }, [getData])
    return (
        <Box style={{ flexGrow: 1 }}>
            <div style={{ display: 'flex', alignItems: 'center', padding: 12, paddingTop: 15 }}>
                <ReusableButton text='Dashboard' fontSize={14} bgColor='#F16774' height={36} width={125} br={18} to='/dashboard' iconType='home' />
                <ChevronRightIcon />
                <ReusableButton text='Users' fontSize={14} bgColor='#F16774' height={36} br={18} to='/users' />
            </div>
            <Grid container style={{ paddingInline: 42, marginTop: 30 }}>
                <NunitoText value='Profile' fontSize={40} fontWeight={700} italic color='#144A94' />
                {loading ? <Loader height={600} /> : user && <Grid container style={containerStyle}>
                    <Grid item xs={6} style={firstHalfStyle}>
                        <div style={leftSideDivStyle}>
                            <div style={imageDivStyle} onMouseEnter={() => onHoverImage(true)} onMouseLeave={() => onHoverImage(false)}
                                onClick={() => openFile()}>
                                <img src={image ? image : profilePic} alt="profile" width={150} height={150}
                                    style={imageStyle} />
                                <p style={centerTextStyle}>Edit image</p>
                                <input id='file-upload' type="file" style={{ display: 'none' }}
                                    onChange={(e) => handleFileInputChange(e.target.files[0])} />
                                <IconButton style={editImageIconStyle}>
                                    <EditIcon />
                                </IconButton>
                            </div>
                            {user.email && <Grid container alignItems='center' justifyContent='space-evenly' style={{ marginBottom: 30 }}>
                                <NunitoText value={user.email} fontSize={25} />
                            </Grid>}
                            <NunitoText value={user.role_name} fontSize={35} fontWeight={700} color='#144A94' marginBottom={30} />
                            {country && <div style={countryContainerStyle}>
                                <CountryFlag iso={country["ISO3166-1-Alpha-2"]} />
                                <NunitoText value={country.display_name} fontSize={25} italic marginLeft={20} />
                            </div>}
                            {/* <ReusableButton text={user.school_name} fontSize={14} bgColor='#F16774' height={42}
                                br={18} iconType='school' /> */}
                        </div>
                    </Grid>
                    <Grid item xs={6} style={secondHalfStyle}>
                        <div style={{ width: '100%' }}>
                            <Grid container alignItems='center' style={{ marginBottom: 50 }}>
                                <Grid item xs={3}>
                                    <NunitoText value='Name' fontSize={30} fontWeight={600} italic align='left' />
                                </Grid>
                                <Grid item xs={9}>
                                    <NunitoText value={user.name} fontSize={30} fontWeight={600} italic align='left' textDecoration='underline' />
                                </Grid>
                            </Grid>
                            {isPartnerOrAssistant() && <Grid container alignItems='center' style={{ marginBottom: 50 }}>
                                <Grid item xs={3}>
                                    <NunitoText value='Organization' fontSize={30} fontWeight={600} italic align='left' />
                                </Grid>
                                <Grid item xs={9}>
                                    <NunitoText value={user?.organization_name || ''} fontSize={30} fontWeight={600} italic align='left' textDecoration='underline' />
                                </Grid>
                            </Grid>}
                        </div>
                        <ReusableButton text='Edit' fontSize={22} bgColor='#588BAE' width={375} height={60}
                            marginBottom={50} onClick={() => setOnEditUser(true)} />
                        {/* <ReusableButton text='Submit' fontSize={22} bgColor='#5E75C3' width={310} height={60}
                            onClick={() => onSubmit()} /> */}
                    </Grid>
                </Grid>}
            </Grid>
            <Modal open={onEditUser} onClose={() => setOnEditUser(false)}>
                <>
                    {user && <EditUserModal table={{ data: [user] }} id={user.id}
                        afterEditingUser={() => getData()} cancelEdit={() => setOnEditUser(false)} />}
                </>
            </Modal>
            {/* <Modal open={onEditPassword} onClose={() => onCloseEditPassword()}>
                <EditPasswordModal password={password} setPassword={setPassword} confirmPassword={confirmPassword}
                    setConfirmPassword={setConfirmPassword} setOnEditPassword={setOnEditPassword}
                    onCloseEditPassword={onCloseEditPassword} onSubmitPassword={onSubmitPassword} />
            </Modal> */}
        </Box >
    )
}