import React, { useState, useEffect } from "react";
import { Grid, Box, IconButton, Button, Modal } from "@mui/material";
import { Link } from "react-router-dom";
import { useLocation } from "react-router-dom";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { SortableContainer, SortableElement } from "react-sortable-hoc";
import AddCircleIcon from "@mui/icons-material/AddCircle";

import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
// import AddCircleSharpIcon from '@mui/icons-material/AddCircleSharp'
// import RemoveCircleSharpIcon from '@mui/icons-material/RemoveCircleSharp'
// import ArrowUpwardSharpIcon from '@mui/icons-material/ArrowUpwardSharp'
// import ArrowDownwardSharpIcon from '@mui/icons-material/ArrowDownwardSharp'
import AddIcon from "@mui/icons-material/Add";
import CloseIcon from "@mui/icons-material/Close";
import { invalidName, cancelAction } from "../functions/general";
import { getDomains, getCollections } from "../functions/getData";
import {
  patchCollectionSettings,
  patchCollectionRecommendations,
  patchCollectionSections,
  patchsectionscollection,
} from "../functions/patchData";
import { deleteSection } from "../functions/deleteData";
import { gradeOptions } from "../functions/general";
import { showWarningSwal } from "../functions/alert";
// import { getBase64 } from '../functions/upload'
import { showNotification } from "../functions/snackbar";
import {
  afterEdit,
  populateCollection,
  deepCopy,
  isUnchanged,
} from "../functions/edit";
import AddTaskToSectionModal from "../components/Collections/AddTaskToSectionModal";
import DeleteModal from "../components/modal/DeleteModal";
import NunitoText from "../components/general/NunitoText";
import ReusableButton from "../components/general/ReusableButton";
import ReusableTextField from "../components/general/ReusableTextField";
// import ReusableDatePicker from '../components/general/ReusableDatePicker'
import ReusableToggle from "../components/general/ReusableToggle";
import ReusableAutocompleteWithID from "../components/general/ReusableAutocompleteWithID";
// import ReusableCheckbox from '../components/general/ReusableCheckbox'
import TitleBar from "../components/general/TitleBar";
// import Solution from '../components/Solution'
import DescriptionEditor from "../components/DescriptionEditor";
import AddPageWrapper from "../components/general/AddPageWrapper";
import InputContainer from "../components/general/InputContainer";
import Loader from "../components/general/Loader";
import NoData from "../components/general/NoData";
import LightTooltip from "../components/general/LightTooltip";
import { useSnackbar } from "notistack";
import { isAdmin } from "../functions/checkrole";
import {
  addsection,
  verfiyCollection,
  removeVerifyCollection,
} from "../functions/postData";
import ConfirmVerfiy from "../components/modal/ConfrimVerfiy";
import RemoveVerify from "../components/modal/RemoveVerify";
import WarningModal from "../components/modal/WarningModal";
const collectionContainerStyle = {
  backgroundColor: "#F3F0F4",
  borderRadius: "0 0 12px 12px",
  paddingBottom: 20,
};
const difficultyOptions = ["easy", "intermediate", "hard", "advanced"].map(
  (d, i) => ({ id: d, name: d })
);
const recommendationContainerStyle = (index) => ({
  marginTop: index ? 10 : 40,
  marginBottom: 10,
});
const iconWrapperStyle = {
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
};
const iconStyle = { fontSize: 36, color: "#000", cursor: "pointer" };
const containerStyle = {
  backgroundColor: "#FFF",
  border: "1px solid #707070",
  borderRadius: 16,
  paddingBlock: 20,
};
const gradeOrDifficultyWrapperStyle = {
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-start",
  paddingInline: "30px 60px",
};
const buttonContainerStyle = { marginRight: 20, marginBlock: "10px 20px" };
const GroupList = SortableContainer(
  ({
    tasks,
    setOnAddTask,
    updateSection,
    count,
    setGroupCount,
    setTaskCount,
    isReadOnly,
    verfiy,
    entireSection,
    restriction_mode,
    competitions,
  }) => {
    const divStyle = {
      backgroundColor: "lightgray",
      display: "contents",
      width: 1460,
      border: "1px solid red",
    };
    console.log(entireSection.section_task);
    console.log(tasks);
    return (
      <div style={divStyle}>
        {tasks.map((task, index) => (
          <Group
            key={index}
            index={index}
            value={entireSection.section_task.filter(
              (item) => item.id === task.task_id[0]
            )}
            count={count}
            groupIndex={index}
            isReadOnly={isReadOnly}
            verfiy={verfiy}
            fulltask={
              entireSection.section_task
                ? entireSection.section_task[index]
                : task
            }
            setOnAddTask={setOnAddTask}
            setGroupCount={setGroupCount}
            setTaskCount={setTaskCount}
            updateSection={updateSection}
            status={
              entireSection.section_task.filter(
                (item) => item.id === task.task_id[0]
              ).status
            }
            // status={task[0].status}
            restriction_mode={restriction_mode}
            competitions={competitions}
          />
        ))}
      </div>
    );
  }
);
const Group = SortableElement(
  ({
    value,
    count,
    groupIndex,
    setOnAddTask,
    setGroupCount,
    setTaskCount,
    updateSection,
    isReadOnly,
    verfiy,
    fulltask,
    restriction_mode,
    competitions,
  }) => {
    const wrapperStyle = {
      display: "flex",
      alignItems: "center",
      marginTop: 10,
      marginRight: 10,
      height: 80,
    };
    const divStyle = {
      border: "1px solid gray",
      borderRadius: 12,
      boxShadow: "0 0 3px #9E9E9E",
      backgroundColor: "#F2F2F2",
      display: "flex",
    };
    const cancelStyle = {
      fontSize: 28,
      color: "#FFF",
      backgroundColor: "#E83D4D",
      cursor: "pointer",
      borderRadius: 16,
      border: "1px solid gray",
      padding: 3,
      marginLeft: -12,
      position: "relative",
      bottom: value.length > 1 ? 30 : 20,
    };
    // consdole.log(fulltask, "entire obj");
    const [displayDeleteWarning, setDisplayDeleteWarning] = useState(false);
    return (
      <div style={wrapperStyle}>
        <div style={value.length > 1 ? divStyle : { display: "flex" }}>
          <ItemList
            task={value}
            axis="x"
            pressDelay={200}
            groupIndex={groupIndex}
            setOnAddTask={setOnAddTask}
            updateSection={updateSection}
            count={count}
            setGroupCount={setGroupCount}
            setTaskCount={setTaskCount}
            isReadOnly={isReadOnly}
            verfiy={verfiy}
            onSortEnd={(e) => updateSection([count, groupIndex], "sortTask", e)}
            status={fulltask ? fulltask.status : ""}
          />
        </div>
        {!isReadOnly && (
          <CloseIcon
            onMouseDown={(e) => e.stopPropagation()}
            style={cancelStyle}
            onClick={() => {
              restriction_mode === "in_use_by_level"
                ? setDisplayDeleteWarning(true)
                : updateSection(count, "deleteTaskGroup", groupIndex);
            }}
          />
        )}
        <Modal
          open={displayDeleteWarning}
          onClose={() => setDisplayDeleteWarning(false)}
        >
          <>
            <WarningModal
              approveFunction={() => {
                updateSection(count, "deleteTaskGroup", groupIndex);
                setDisplayDeleteWarning(false);
              }}
              cancelFunction={() => setDisplayDeleteWarning(false)}
              affectedCompetitions={competitions?.filter(
                (item) => item.status === "active"
              )}
            />
          </>
        </Modal>
        {!isReadOnly && (
          <IconButton
            style={{ height: 24, width: 24 }}
            onClick={() => {
              setGroupCount(groupIndex);
              setOnAddTask(true);
            }}
          >
            <AddCircleIcon
              style={{ fontSize: 28 }}
              onMouseDown={(e) => e.stopPropagation()}
            />
          </IconButton>
        )}
      </div>
    );
  }
);
const ItemList = SortableContainer(
  ({
    task,
    groupIndex,
    setOnAddTask,
    updateSection,
    count,
    setGroupCount,
    setTaskCount,
    isReadOnly,
    verfiy,
    status,
  }) => {
    const containerStyle = { display: "flex", alignItems: "center" };
    return (
      <div style={containerStyle}>
        {task.map((value, index) => (
          <Item
            key={`item-${index}`}
            index={index}
            value={value}
            isReadOnly={isReadOnly}
            verfiy={verfiy}
            status={status}
            groupIndex={groupIndex}
            taskIndex={index}
            length={task.length}
            setOnAddTask={setOnAddTask}
            updateSection={updateSection}
            count={count}
            setGroupCount={setGroupCount}
            setTaskCount={setTaskCount}
          />
        ))}
      </div>
    );
  }
);
const Item = SortableElement(
  ({
    value,
    groupIndex,
    taskIndex,
    length,
    setOnAddTask,
    updateSection,
    count,
    setGroupCount,
    setTaskCount,
    isReadOnly,
    verfiy,
    status,
  }) => {
    const taskBtnStyle = {
      borderRadius: 10,
      height: 50,
      textTransform: "none",
      backgroundColor: status === "verified" ? "#3EC49D" : "#A9ABD3",
      color: "#FFF",
      marginBlock: 10,
      marginInline: length > 1 && `${!taskIndex ? "15px" : "0"} 15px`,
      paddingInline: 20,
    };
    return (
      <LightTooltip
        title={
          <Link
            to={
              verfiy
                ? `/edittask?id=${value.id}&ver`
                : `/edittask?id=${value.id}&mod`
            }
            target="_blank"
          >
            {value.languages[0].task_title}
          </Link>
        }
        // title="tst"
        placement="bottom-start"
      >
        <Button
          style={taskBtnStyle}
          endIcon={
            <>
              {taskIndex > 0 && (
                <IconButton
                  style={{ width: 26 }}
                  onClick={() =>
                    updateSection(
                      [count, groupIndex],
                      "deleteTaskFromGroup",
                      taskIndex
                    )
                  }
                >
                  <RemoveCircleIcon
                    style={{ color: "#FFF" }}
                    onMouseDown={(e) => e.stopPropagation()}
                  />
                </IconButton>
              )}
              {!isReadOnly && (
                <IconButton
                  style={{ width: 26 }}
                  onClick={() => {
                    setTaskCount(taskIndex);
                    setGroupCount(groupIndex);
                    setOnAddTask(true);
                  }}
                >
                  <AddCircleIcon
                    style={{ color: "#FFF" }}
                    onMouseDown={(e) => e.stopPropagation()}
                  />
                </IconButton>
              )}
            </>
          }
        >
          Task {value.id}
        </Button>
      </LightTooltip>
    );
  }
);
const tasksContainerStyle = {
  margin: 32,
  backgroundColor: "#FFF",
  padding: "10px 32px",
  border: "1px solid gray",
  borderRadius: 10,
};
const saveButtonContainerStyle = { margin: " 0 30px 30px 30px" };
const Section = ({
  count,
  original,
  setOriginal,
  collection,
  setCollection,
  updateSection,
  openDeleteModal,
  editanswers,
  isReadOnly,
  verfiy,
  entireSection,
}) => {
  const [expand, setExpand] = useState(false);
  const [onAddTask, setOnAddTask] = useState(false);
  const [displayWarning, setDisplayWarning] = useState(false);
  const [displayDeleteWarning, setDisplayDeleteWarning] = useState(false);
  const [groupCount, setGroupCount] = useState("");
  const [taskCount, setTaskCount] = useState("");
  const section = collection.sections[count];
  const { id, description, tasks, allow_skip, sort_randomly } = section;
  const cancelAddTask = () => {
    setOnAddTask(false);
    setGroupCount("");
    setTaskCount("");
  };
  const removeUnsavedSection = () => {
    let newCollection = window.structuredClone(collection);
    newCollection.sections = newCollection.sections.filter(
      (s, i) => i !== count
    );
    setCollection(newCollection);
  };
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const onSubmit = () => {
    console.log(tasks);
    let payload =
      id !== ""
        ? {
            collection_id: collection.id,
            section_id: id,
            section: {
              groups: tasks,
              sort_randomly,
              allow_skip,
              description,
            },
          }
        : {
            collection_id: collection.id,

            // groups: tasks.map((grp) => ({ task_id: grp.map((t) => t.id) })),
            groups: tasks,
            sort_randomly,
            allow_skip,
            description,
          };
    console.log(payload);
    if (editanswers) {
      if (id !== "") {
        patchsectionscollection(payload).then((res) => {
          if (res.status === 200) {
            showNotification(
              "success",
              res.message,
              enqueueSnackbar,
              closeSnackbar
            );
          }
        });
      } else {
        addsection(payload).then((res) => {
          if (res.status === 200) {
            showNotification(
              "success",
              "Section Successfuly Added to the Collection",
              enqueueSnackbar,
              closeSnackbar
            );
            window.location.reload(false);
          }
        });
      }
    } else {
      showWarningSwal(
        "editing sections is not allowed since its used by a competition"
      );
    }
  };
  return (
    <>
      <TitleBar
        title={`Section ${count + 1}${id === "" ? " - unsaved" : ""}`}
        state={expand}
        setState={setExpand}
      >
        {!isReadOnly && (
          <ReusableButton
            text="Delete Section"
            height={46}
            bgColor="#E83042"
            fontSize={16}
            marginRight={20}
            onClick={() =>
              id === ""
                ? removeUnsavedSection()
                : collection.restriction_mode === "in_use_by_level"
                ? setDisplayDeleteWarning(true)
                : openDeleteModal(id)
            }
            iconType="delete"
          />
        )}
      </TitleBar>
      {expand && (
        <AddPageWrapper>
          <Grid container style={tasksContainerStyle}>
            {!isReadOnly && (
              <Grid item xs={12}>
                <ReusableButton
                  text="Add Task"
                  bgColor="#5E75C3"
                  fontSize={14}
                  iconType="add"
                  onClick={() => {
                    collection.restriction_mode === "in_use_by_level"
                      ? setDisplayWarning(true)
                      : setOnAddTask(true);
                  }}
                />
              </Grid>
            )}
            <Grid container>
              <GroupList
                tasks={tasks}
                setOnAddTask={
                  collection.restriction_mode === "in_use_by_level"
                    ? setDisplayWarning
                    : setOnAddTask
                }
                updateSection={updateSection}
                count={count}
                isReadOnly={isReadOnly}
                verfiy={verfiy}
                setGroupCount={setGroupCount}
                setTaskCount={setTaskCount}
                axis="xy"
                pressDelay={200}
                onSortEnd={(e) => updateSection(count, "sortTaskGroup", e)}
                entireSection={entireSection}
                restriction_mode={collection.restriction_mode}
                competitions={collection.competitions}
              />
            </Grid>
          </Grid>
          <InputContainer
            first
            label="Sort Randomly"
            field={
              <ReusableToggle
                type="icon"
                width={124}
                state={sort_randomly}
                setState={updateSection}
                disabled={isReadOnly}
                count={count}
                target="sort_randomly"
              />
            }
          />
          <InputContainer
            label="Skip"
            field={
              <ReusableToggle
                type="icon"
                width={124}
                state={allow_skip}
                setState={updateSection}
                disabled={isReadOnly}
                count={count}
                target="allow_skip"
              />
            }
          />
          <InputContainer
            label="Total Tasks"
            field={
              <ReusableTextField
                type="immutableDisplay"
                width={240}
                placeholder="Total Tasks"
                state={tasks.flat().length}
                grayedOut
                readOnly
              />
            }
          />
          <InputContainer
            last
            label="Description"
            field={
              <DescriptionEditor
                state={description}
                setState={updateSection}
                height={400}
                placeholder="Description"
                disabled={isReadOnly}
                count={count}
                target="description"
              />
            }
          />
          {!isReadOnly && (
            <Grid
              container
              justifyContent="flex-end"
              style={saveButtonContainerStyle}
            >
              <ReusableButton
                text="Save"
                bgColor="#5E75C3"
                fontSize={16}
                onClick={() => onSubmit()}
              />
            </Grid>
          )}
        </AddPageWrapper>
      )}
      <Modal open={onAddTask} onClose={() => cancelAddTask()}>
        <>
          <AddTaskToSectionModal
            collection={collection}
            section={section}
            count={count}
            sectionCount={count}
            groupCount={groupCount}
            taskCount={taskCount}
            update={updateSection}
            cancelAddTask={cancelAddTask}
            edit
          />
        </>
      </Modal>
      <Modal open={displayWarning} onClose={() => setDisplayWarning(false)}>
        <>
          <WarningModal
            approveFunction={() => {
              setOnAddTask(true);
              setDisplayWarning(false);
            }}
            cancelFunction={() => setDisplayWarning(false)}
            affectedCompetitions={collection.competitions.filter(
              (item) => item.status === "active"
            )}
          />
        </>
      </Modal>
      <Modal
        open={displayDeleteWarning}
        onClose={() => setDisplayDeleteWarning(false)}
      >
        <>
          <WarningModal
            approveFunction={() => {
              openDeleteModal(id);
              setDisplayDeleteWarning(false);
            }}
            cancelFunction={() => setDisplayDeleteWarning(false)}
            affectedCompetitions={collection.competitions.filter(
              (item) => item.status === "active"
            )}
          />
        </>
      </Modal>
    </>
  );
};
const Sections = ({
  original,
  setOriginal,
  collection,
  setCollection,
  editanswers,
  isReadOnly,
  verfiy,
}) => {
  const [expand, setExpand] = useState(false);
  const [onDelete, setOnDelete] = useState(false);
  const [id, setId] = useState("");
  const cancelDelete = () => cancelAction(setOnDelete, setId);
  const openDeleteModal = (id) => {
    setId(id);
    setOnDelete(true);
  };

  const { sections } = collection;
  const updateSection = (count, target, value) => {
    let newCollection = { ...collection };
    switch (target) {
      case "justRemoveTask":
        for (let i = 0; i < newCollection.sections.length; i++) {
          const section = newCollection.sections[i];
          for (let j = 0; j < section.tasks.length; j++) {
            newCollection.sections[i].tasks[j] = newCollection.sections[
              i
            ].tasks[j].filter((task) => !value.includes(task.id));
          }
          newCollection.sections[i].tasks = newCollection.sections[
            i
          ].tasks.filter((tasks) => tasks.length);
        }
        break;
      case "addTaskGroup":
        value.reverse().forEach((task) => {
          newCollection.sections[count[0]].tasks.splice(count[1], 0, {
            task_id: [task.id],
            // task_title: task.title,
          });
          console.log(task);
          console.log(newCollection.sections[count[0]].section_task);
          newCollection.sections[count[0]].section_task.push(task.wholeTask);
        });
        break;
      case "addTaskToGroup":
        value.reverse().forEach((task) => {
          let obj = { id: task.id, task_title: task.title };
          newCollection.sections[count[0]].tasks[count[1]].splice(
            count[2],
            0,
            obj
          );
        });
        break;
      case "deleteTaskGroup":
        newCollection.sections[count].tasks.splice(value, 1);
        break;
      case "deleteTaskFromGroup":
        newCollection.sections[count[0]].tasks[count[1]].splice(value, 1);
        break;
      case "sortTaskGroup":
        let oldTaskGrp =
          newCollection.sections[count].tasks[Number(value.oldIndex)];
        newCollection.sections[count].tasks[Number(value.oldIndex)] =
          newCollection.sections[count].tasks[Number(value.newIndex)];
        newCollection.sections[count].tasks[Number(value.newIndex)] =
          oldTaskGrp;
        break;
      case "sortTask":
        let oldTask =
          newCollection.sections[count[0]].tasks[count[1]][value.oldIndex];
        newCollection.sections[count[0]].tasks[count[1]][value.oldIndex] =
          newCollection.sections[count[0]].tasks[count[1]][value.newIndex];
        newCollection.sections[count[0]].tasks[count[1]][value.newIndex] =
          oldTask;
        break;
      case "add":
        newCollection.sections.splice(count + 1, 0, {
          id: "",
          description: "",
          tasks: [],
          allow_skip: false,
          sort_randomly: false,
        });
        break;
      case "remove":
        newCollection.sections.splice(count, 1);
        break;
      default:
        newCollection.sections[count][target] = value;
    }
    setCollection(newCollection);
  };
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const deleteFunction = () => {
    let payload = { collection_id: collection.id, id: [id] };
    deleteSection(payload)
      .then((d) => {
        if (d.status === 200) {
          setOnDelete(false);
          showNotification(
            "success",
            d.message,
            enqueueSnackbar,
            closeSnackbar
          );
        }
      })
      .catch((e) => console.log(e))
      .finally(() => {
        afterEdit(
          getCollections,
          populateCollection,
          setOriginal,
          collection,
          setCollection
        );
      });
  };
  return (
    <>
      <TitleBar title="Sections" state={expand} setState={setExpand}>
        {!isReadOnly && (
          <ReusableButton
            text="New Section"
            height={46}
            bgColor="#5E75C3"
            fontSize={16}
            marginRight={40}
            onClick={() => updateSection(sections.length, "add")}
            iconType="add"
          />
        )}
      </TitleBar>
      {expand && (
        <AddPageWrapper>
          <Grid container style={{ paddingBottom: 20 }}>
            {sections.map((section, index) => (
              <Section
                key={section.id}
                count={index}
                original={original}
                setOriginal={setOriginal}
                editanswers={editanswers}
                isReadOnly={isReadOnly}
                verfiy={verfiy}
                collection={collection}
                setCollection={setCollection}
                updateSection={updateSection}
                openDeleteModal={openDeleteModal}
                entireSection={section}
              />
            ))}
          </Grid>
        </AddPageWrapper>
      )}
      <Modal open={onDelete} onClose={() => cancelDelete()}>
        <>
          <DeleteModal
            table={{
              data: sections.map((s, i) => ({
                ...s,
                name: `Section ${i + 1}`,
              })),
            }}
            id={id}
            selected={[]}
            deleteFunction={deleteFunction}
            cancelDelete={cancelDelete}
          />
        </>
      </Modal>
    </>
  );
};
const Recommendations = ({
  original,
  setOriginal,
  collection,
  setCollection,
  isReadOnly,
}) => {
  const [expand, setExpand] = useState(false);
  const [loading, setLoading] = useState(false);
  const { id, grade_difficulty } = collection;
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const updateRecommendation = (count, target, value) => {
    let newCollection = { ...collection };
    if (target === "add") {
      newCollection.grade_difficulty.splice(count + 1, 0, {
        grade: "",
        difficulty: "",
      });
    } else if (target === "remove") {
      newCollection.grade_difficulty.splice(count, 1);
    } else {
      newCollection.grade_difficulty[count][target] = value;
    }
    setCollection(newCollection);
  };
  const onSubmit = () => {
    if (isUnchanged(original, collection))
      return showWarningSwal("Please make some changes before submitting");
    let msg = "";
    // if (grade_difficulty.map(r => r.grade).includes('')) {
    //    msg += 'Please fill in all grades fields or remove recommended grade.<br>'
    // }
    // if (grade_difficulty.map(r => r.difficulty).includes('')) {
    //    msg += 'Please fill in all difficulty fields or remove recommended difficulty.<br>'
    // }
    if (msg) return showWarningSwal(msg);
    setLoading(true);
    let payload = {
      collection_id: id,
      recommendations: grade_difficulty.map((r) => ({
        grade: r.grade,
        difficulty: r.difficulty,
      })),
    };
    console.log(payload);
    patchCollectionRecommendations(payload)
      .then((d) => {
        if (d.status === 200)
          showNotification(
            "success",
            d.message,
            enqueueSnackbar,
            closeSnackbar
          );
      })
      .catch((e) => console.log(e))
      .finally(() => {
        afterEdit(
          getCollections,
          populateCollection,
          setOriginal,
          collection,
          setCollection
        );
        setLoading(false);
      });
  };
  return (
    <>
      <TitleBar title="Recomendations" state={expand} setState={setExpand}>
        {!isReadOnly && (
          <ReusableButton
            text="Add Recommendation"
            fontSize={16}
            bgColor="#5E75C3"
            marginRight={40}
            onClick={() => updateRecommendation(grade_difficulty.length, "add")}
            iconType="add"
          />
        )}
      </TitleBar>
      {expand && (
        <AddPageWrapper>
          {loading ? (
            <Loader height={600} />
          ) : (
            grade_difficulty.map(({ grade, difficulty }, index) => (
              <Grid
                key={index}
                container
                alignItems="center"
                style={recommendationContainerStyle(index)}
              >
                <Grid item xs={0.5} style={iconWrapperStyle}>
                  {!isReadOnly && (
                    <IconButton
                      onClick={() => updateRecommendation(index, "remove")}
                    >
                      <CloseIcon style={iconStyle} />
                    </IconButton>
                  )}
                </Grid>
                <Grid item xs={11}>
                  <Grid container style={containerStyle}>
                    <Grid item xs={6} style={gradeOrDifficultyWrapperStyle}>
                      <NunitoText
                        value="Recommended Grade:"
                        fontSize={20}
                        marginBottom={10}
                        fontWeight={600}
                        italic
                      />
                      {isReadOnly ? (
                        <ReusableTextField
                          type="default"
                          width={420}
                          bgColor="#F2F2F2"
                          placeholder="Grade"
                          disabled={isReadOnly}
                          state={
                            gradeOptions.filter((el) => {
                              return el.id === grade;
                            })[0].name
                          }
                          setState={updateRecommendation}
                          //   required
                        />
                      ) : (
                        <ReusableAutocompleteWithID
                          type="default"
                          placeholder="Grade"
                          width="100%"
                          bgColor="#F2F2F2"
                          btnBgColor="#5E75C3"
                          btnWidth={56}
                          state={grade}
                          setState={updateRecommendation}
                          count={index}
                          //   required
                          target="grade"
                          options={gradeOptions.map((g) => ({
                            id: g.id,
                            option: g.name,
                          }))}
                        />
                      )}
                    </Grid>
                    <Grid item xs={6} style={gradeOrDifficultyWrapperStyle}>
                      <NunitoText
                        value="Recommended Difficulty:"
                        fontSize={20}
                        marginBottom={10}
                        fontWeight={600}
                        italic
                      />
                      {isReadOnly ? (
                        <ReusableTextField
                          type="default"
                          width={420}
                          bgColor="#F2F2F2"
                          placeholder="Difficulty"
                          disabled={isReadOnly}
                          state={difficulty}
                          setState={updateRecommendation}
                          //   required
                        />
                      ) : (
                        <ReusableAutocompleteWithID
                          type="default"
                          placeholder="Difficulty"
                          width="100%"
                          bgColor="#F2F2F2"
                          btnBgColor="#5E75C3"
                          btnWidth={56}
                          state={difficulty}
                          setState={updateRecommendation}
                          count={index}
                          //   required
                          target="difficulty"
                          options={difficultyOptions.map((d) => ({
                            id: d.id,
                            option: d.name,
                          }))}
                        />
                      )}
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={0.5} style={iconWrapperStyle}>
                  {!isReadOnly && (
                    <IconButton
                      onClick={() => updateRecommendation(index, "add")}
                    >
                      <AddIcon style={iconStyle} />
                    </IconButton>
                  )}
                </Grid>
              </Grid>
            ))
          )}
          {!isReadOnly && (
            <Grid
              container
              justifyContent="flex-end"
              style={buttonContainerStyle}
            >
              <ReusableButton
                text="Save"
                bgColor="#5E75C3"
                width={140}
                height={50}
                fontSize={16}
                onClick={() => onSubmit()}
              />
            </Grid>
          )}
        </AddPageWrapper>
      )}
    </>
  );
};
const Collection = ({
  original,
  setOriginal,
  collection,
  setCollection,
  tagOptions,
  editanswers,
  isReadOnly,
  verfiy,
  compid,
}) => {
  const [expandCollection, setExpandCollection] = useState(true);
  const [expandSetting, setExpandSetting] = useState(true);
  const [confirmVerfiy, setConfirmVerfiy] = useState(false);
  const [removeVerfiy, setRemoveVerify] = useState(false);
  const [loading, setLoading] = useState(false);
  const {
    id,
    name,
    identifier,
    time_to_solve,
    initial_points,
    tags,
    description,
  } = collection;
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const updateCollection = (target, value) => {
    let newCollection = { ...collection };
    newCollection[target] = value;
    if (target === "name") {
      newCollection.identifier = value.toLowerCase().trim().replace(/\s/g, "_");
    }
    setCollection(newCollection);
  };
  console.log(original, "setorginial");
  const onSubmit = () => {
    if (isUnchanged(original, collection))
      return showWarningSwal("Please make some changes before submitting");
    let warningMessage = "";
    if (invalidName(name)) warningMessage += "Invalid Collection Name.<br>";
    if (invalidName(identifier))
      warningMessage += "Invalid Collection Identifier.<br>";
    if (isNaN(time_to_solve) || !time_to_solve) {
      warningMessage +=
        "Invalid Time to Solve(Must be a number greater than 0).<br>";
    }
    if (isNaN(initial_points) || !initial_points.toString().length) {
      warningMessage += "Invalid Initial Points(Must be a number).<br>";
    }
    if (warningMessage) return showWarningSwal(warningMessage);
    actuallySubmit();
  };
  const actuallySubmit = () => {
    let payload = {
      collection_id: id,
      settings: {
        time_to_solve,
        initial_points,
        tags,
        description,
      },
    };
    if (name.toLowerCase() !== original.name.toLowerCase())
      payload.settings.name = name;
    if (identifier.toLowerCase() !== original.identifier.toLowerCase())
      payload.settings.identifier = identifier;
    setLoading(true);
    patchCollectionSettings(payload)
      .then((d) => {
        if (d.status === 200)
          showNotification(
            "success",
            d.message,
            enqueueSnackbar,
            closeSnackbar
          );
      })
      .catch((e) => console.log(e))
      .finally(() => {
        setLoading(false);
        afterEdit(
          getCollections,
          populateCollection,
          setOriginal,
          collection,
          setCollection
        );
      });
  };
  const vefiy = () => {
    verfiyCollection({
      competition_id: compid,
      collection_id: id,
    })
      .then((res) => {
        if (res.status === 200 || res.status === 201) {
          showNotification(
            "success",
            res.message,
            enqueueSnackbar,
            closeSnackbar
          );
        } else {
          showWarningSwal(res.message);
        }
      })
      .catch((e) => console.log(e))
      .finally(() => {
        setLoading(false);
        afterEdit(
          getCollections,
          populateCollection,
          setOriginal,
          collection,
          setCollection
        );
        setConfirmVerfiy(false);
      });
  };

  const confirmRemoveVerify = () => {
    removeVerifyCollection(id)
      .then((res) => {
        if (res.status === 200 || res.status === 201) {
          showNotification(
            "success",
            res.message,
            enqueueSnackbar,
            closeSnackbar
          );
        } else {
          showWarningSwal(res.message);
        }
      })
      .catch((e) => console.log(e))
      .finally(() => {
        setLoading(false);
        afterEdit(
          getCollections,
          populateCollection,
          setOriginal,
          collection,
          setCollection
        );
        setRemoveVerify(false);
      });
  };
  return (
    <>
      <TitleBar
        title={collection.identifier}
        state={expandCollection}
        setState={setExpandCollection}
        main
      />
      {expandCollection && (
        <Grid container style={collectionContainerStyle}>
          <TitleBar
            title="Settings"
            state={expandSetting}
            setState={setExpandSetting}
          />
          {loading ? (
            <Loader height={600} />
          ) : (
            <>
              {expandSetting && (
                <AddPageWrapper>
                  <InputContainer
                    first
                    label="Collection Name*"
                    field={
                      <ReusableTextField
                        type="default"
                        bgColor="#F2F2F2"
                        placeholder="Collection Name*"
                        disabled={isReadOnly}
                        state={name}
                        setState={updateCollection}
                        head="settingText"
                        target="name"
                        required
                        onBlur
                        fullWidth
                      />
                    }
                  />
                  <InputContainer
                    label="Collection Identifier*"
                    field={
                      <ReusableTextField
                        type="default"
                        bgColor="#F2F2F2"
                        placeholder="Collection Identifier*"
                        disabled={isReadOnly}
                        state={identifier}
                        setState={updateCollection}
                        head="settingText"
                        target="identifier"
                        required
                        onBlur
                        fullWidth
                      />
                    }
                  />
                  <InputContainer
                    label="Time to Solve(mins)*"
                    field={
                      <ReusableTextField
                        type="default"
                        width={420}
                        bgColor="#F2F2F2"
                        placeholder="Time to Solve*"
                        disabled={isReadOnly}
                        state={time_to_solve}
                        setState={updateCollection}
                        head="settingText"
                        target="time_to_solve"
                        required
                        number
                      />
                    }
                  />
                  <InputContainer
                    label="Initial Points*"
                    field={
                      <ReusableTextField
                        type="default"
                        width={420}
                        bgColor="#F2F2F2"
                        placeholder="Initial Points*"
                        disabled={isReadOnly}
                        state={initial_points}
                        setState={updateCollection}
                        head="settingText"
                        target="initial_points"
                        required
                        number
                      />
                    }
                  />
                  <InputContainer
                    label="Tags"
                    field={
                      <ReusableAutocompleteWithID
                        type="default"
                        placeholder="Tags"
                        width={590}
                        multiple
                        disabled={isReadOnly}
                        state={tags}
                        setState={updateCollection}
                        head="settingMultiple"
                        target={isReadOnly ? "" : "tags"}
                        borderColor="#707070"
                        options={tagOptions.map((d) => ({
                          id: d.id,
                          option: d.name,
                        }))}
                      />
                    }
                  />
                  <InputContainer
                    last
                    label="Description"
                    field={
                      <DescriptionEditor
                        state={description}
                        setState={updateCollection}
                        height={400}
                        disabled={isReadOnly}
                        placeholder="Description"
                        head="settingText"
                        target="description"
                      />
                    }
                  />
                  <Grid
                    container
                    justifyContent="flex-end"
                    style={saveButtonContainerStyle}
                  >
                    {!isReadOnly && (
                      <ReusableButton
                        text="Submit"
                        bgColor="#5E75C3"
                        fontSize={16}
                        onClick={() => onSubmit()}
                      />
                    )}
                  </Grid>
                </AddPageWrapper>
              )}
            </>
          )}
          <Recommendations
            original={original}
            setOriginal={setOriginal}
            isReadOnly={isReadOnly}
            collection={collection}
            setCollection={setCollection}
          />
          <Sections
            original={original}
            setOriginal={setOriginal}
            editanswers={editanswers}
            isReadOnly={
              isReadOnly || collection.restriction_mode === "restricted"
            }
            verfiy={verfiy}
            collection={collection}
            setCollection={setCollection}
          />
          {verfiy && (
            <Grid
              container
              style={saveButtonContainerStyle}
              justifyContent="flex-end"
            >
              {console.log(original.status)}
              <ReusableButton
                text={
                  original.status === "verified"
                    ? isAdmin()
                      ? "remove verified"
                      : "verified"
                    : "verify"
                }
                bgColor={
                  original.status === "verified"
                    ? isAdmin()
                      ? "#5E75C3"
                      : "#999"
                    : "#5E75C3"
                }
                fontSize={16}
                marginTop={"1%"}
                onClick={() =>
                  original.status === "verified"
                    ? isAdmin()
                      ? setRemoveVerify(true)
                      : {}
                    : setConfirmVerfiy(true)
                }
              ></ReusableButton>
            </Grid>
          )}
          <Modal
            open={confirmVerfiy}
            onClose={() => {
              setConfirmVerfiy(false);
            }}
          >
            <ConfirmVerfiy
              confirmFunction={vefiy}
              cancelConfirm={() => setConfirmVerfiy(false)}
              object={"Collection"}
              value={collection}
            ></ConfirmVerfiy>
          </Modal>
          <Modal
            open={removeVerfiy}
            onClose={() => {
              setRemoveVerify(false);
            }}
          >
            <RemoveVerify
              cancelVerify={() => setRemoveVerify(false)}
              id={id}
              confirmUnverify={confirmRemoveVerify}
              task={collection}
              type="Collection"
            />
          </Modal>
        </Grid>
      )}
    </>
  );
};
export default function EditCollection() {
  const [loading, setLoading] = useState(true);
  const [firstLoaded, setFirstLoaded] = useState(false);
  const [original, setOriginal] = useState();
  const [collection, setCollection] = useState();
  const [tagOptions, setTagOptions] = useState([]);
  const [editanswers, setEditAnswers] = useState(false);
  const [isReadOnly, setIsReadOnly] = useState(false);
  const [compid, setcompid] = useState();
  const [verfiy, setIsVerfiy] = useState(false);
  document.title = "Edit Collection";
  let location = useLocation();
  useEffect(() => {
    const controller = new AbortController();
    const signal = controller.signal;
    if (location.search.slice(0, 4) === "?id=") {
      if (
        location.search.slice(
          location.search.length - 3,
          location.search.length
        ) === "mod"
      ) {
        setIsReadOnly(true);
      } else if (
        location.search.slice(
          location.search.length - 3,
          location.search.length
        ) === "ver"
      ) {
        let query = location.search
          .slice(1)
          .split("&")
          .map((q) => q.split("=")[1]);
        console.log(query, "params");
        setcompid(query[1]);
        setIsReadOnly(true);
        setIsVerfiy(true);
      }
      getCollections(`?id=${location.search.slice(4)}`, signal)
        .then((d) => {
          setEditAnswers(d.collectionList.data[0].allow_update_sections);
          let foundCollection = populateCollection(Object.values(d)[1]);
          setOriginal(deepCopy(foundCollection));
          setCollection(foundCollection);
        })
        .catch((e) => console.log(e))
        .finally(() => {
          if (!signal.aborted) {
            setLoading(false);
            setFirstLoaded(true);
          }
        });
      getDomains("?status=active&limits=50")
        .then((d) => {
          setTagOptions(
            Object.values(d)[1]
              .data.filter((c) => c.is_tag)
              .map((t) => ({ id: t.id, name: t.name }))
          );
        })
        .catch((e) => console.log(e));
    } else {
      showWarningSwal("Wrong query");
      setLoading(false);
      setFirstLoaded(true);
    }
    return () => controller.abort();
  }, [location.search]);
  return (
    <Box className="wrapperBox">
      <div className="firstRowContainer dashboardAndSelfBtnDiv">
        <ReusableButton
          text="Dashboard"
          fontSize={14}
          bgColor="#F16774"
          height={36}
          width={125}
          br={18}
          to="/dashboard"
          iconType="home"
        />
        <ChevronRightIcon />
        <ReusableButton
          text="Collections"
          fontSize={14}
          bgColor="#F16774"
          height={36}
          br={18}
          to="/collection"
        />
      </div>
      <div className="formContainer">
        <NunitoText
          value={
            verfiy
              ? "Verfiy Collection"
              : isReadOnly
              ? "Moderate collection"
              : "Edit Collection"
          }
          fontSize={40}
          fontWeight={700}
          italic
          color="#144A94"
          align="left"
        />
        {loading ? (
          <Loader height={600} />
        ) : collection ? (
          <Collection
            verfiy={verfiy}
            original={original}
            setOriginal={setOriginal}
            editanswers={editanswers}
            isReadOnly={isReadOnly}
            collection={collection}
            setCollection={setCollection}
            tagOptions={tagOptions}
            compid={compid}
          />
        ) : (
          firstLoaded && <NoData height={600} />
        )}
      </div>
    </Box>
  );
}
