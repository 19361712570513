import React, { useState, useEffect, useCallback, useMemo } from 'react'
import { Box, Grid, Modal } from '@mui/material'
import { CSVLink } from 'react-csv'
import { useHistory } from 'react-router-dom'
import { useSelector } from 'react-redux'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward'
import CSVUploadTable from '../components/table/CSVUploadTable'
import CSVUploadFilter from '../components/CSVUploadFilter'
import ReusableToggle from '../components/general/ReusableToggle'
import NunitoText from '../components/general/NunitoText'
import ReusableTextField from '../components/general/ReusableTextField'
import ReusableSelect from '../components/general/ReusableSelect'
import ReusableButton from '../components/general/ReusableButton'
import ReusableCheckbox from '../components/general/ReusableCheckbox'
import {  CSVDownload } from "react-csv";
import all from '../files/editparticipants/all.csv'
import ad from '../files/editparticipants/a.csv'
import ai from '../files/editparticipants/ai.csv'
import i from '../files/editparticipants/i.csv'
import n from '../files/editparticipants/n.csv'
import nad from '../files/editparticipants/na.csv'
import nadi from '../files/editparticipants/nai.csv'
import ni from '../files/editparticipants/ni.csv'
import ns from '../files/editparticipants/ns.csv'
import nsad from '../files/editparticipants/nsa.csv'
import nsi from '../files/editparticipants/nsi.csv'
import s from '../files/editparticipants/s.csv'
import sad from '../files/editparticipants/sa.csv'
import sadi from '../files/editparticipants/sai.csv'
import si from '../files/editparticipants/si.csv'
import ReusableAutocompleteWithID from '../components/general/ReusableAutocompleteWithID'
import { invalidName, invalidNumber, invalidEmail, delimitCSVRow, gradeOptions as allGradeOptions, makeSureIsArray } from '../functions/general'
import { showWarningSwal, isEmpty, warningMessage } from '../functions/alert'
import { showNotification } from '../functions/snackbar'
import { isAdmin, isAdminOrPartnerOrAssistant, isManagerOrTeacher, isPartnerOrAssistant, isFromTuitionCentre } from '../functions/checkrole'
import { useSnackbar } from 'notistack'
import Loader from '../components/general/Loader'
import { uploadParticipantAnswers } from '../functions/postData'
import { useLocation } from 'react-router-dom'

import * as XLSX from 'xlsx'
import { getCompetition } from '../functions/getData'
import EditParticipantsCheckList from '../components/modal/EditParticipantsCheckList'
import { patchParticipantbulk } from '../functions/patchData'

const gridStyle = { textAlign: 'center' }
const flexStyle = { display: 'flex' }
export default function EditParticpantsBulk() {
  let location = useLocation()
    
      const controller = useMemo(() => new AbortController(), [])
    const signal = controller.signal
    document.title = 'Edit Participants'
    const [errors , setErrors] = useState([])

     
     
    const history = useHistory()
    const [rowsPerPage, setRowsPerPage] = useState(10)
    const [name , setN] = useState(false)
    const [school , setS] = useState(true)
    const [adress , setA] = useState(true)
    const [ident , setI] = useState(false)
    const [Class , setC] = useState(false)
    const [onDownload , setOnDownload] = useState(false)
    const [headers, setHeaders] = useState([])
    const [csvFile, setCSVFile] = useState(null)
    const [csvHeaders, setCSVHeaders] = useState()
    const [csvMapFields, setCSVMapFields] = useState()
    const [csvArray, setCSVArray] = useState([]);
    const [copyCSVArray, setCopyCSVArray] = useState([])
    const [list, setList] = useState([])
    //Select from the table
    const [selected, setSelected] = useState([])
    const [selecting, setSelecting] = useState([])
    const [sort, setSort] = useState('')
    const [loading, setLoading] = useState(false)
    const { enqueueSnackbar, closeSnackbar } = useSnackbar()
    const delimit = input => {
      let tokens = []
      let startPosition = 0
      let isInQuotes = false
      for (let currentPosition = 0; currentPosition < input.length; currentPosition++) {
          if (input.charAt(currentPosition) === '"') {
              isInQuotes = !isInQuotes
          } else if (input.charAt(currentPosition) === ',' && !isInQuotes) {
              tokens.push(input.substring(startPosition, currentPosition))
              startPosition = currentPosition + 1
          }
      }
      let lastToken = input.substring(startPosition)
      if (lastToken === ',') {
          tokens.push("")
      } else {
          tokens.push(lastToken)
      }
      return tokens
  }
    // CSV File related functions
    const openFileInput = () => document.getElementById('file-upload-for-csv').click()
    const onChangeCSVFile = (e, file) => {
      cancelCSVFile()

        // e.target.value = null
        // setCSVFile(file)
        // const reader = new FileReader()
        // try {
        //     reader.onload = e => {
        //         const text = e.target.result
        //         processCSV(text)

        //     }
        //     reader.readAsText(file)
        // } catch (err) {
        //     console.log(err.message)
        // }
        e.target.value = null
        const reader = new FileReader()
        if (file.type === 'text/csv') {
          setCSVFile(file)
            try {
                reader.onload = e => {
                    const text = e.target.result
                    processCSV(text, ',', file.type)
                }
                reader.readAsText(file)
            } catch (err) {
            }
        } else if (file.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') {
          setCSVFile(file)
            try {
                const rABS = !!reader.readAsBinaryString;
                reader.onload = e => { // e = on_file_select event
                    /* Parse data */
                    const bstr = e.target.result;
                    const wb = XLSX.read(bstr, { type: 'binary' })
                    /* Get first worksheet */
                    const wsname = wb.SheetNames[0]
                    const ws = wb.Sheets[wsname]
                    /* Convert array of arrays */
                    const text = XLSX.utils.sheet_to_csv(ws, { header: 1 })
                    /* Update state */
                    processCSV(text, ',', file.type)
                };
                if (rABS) reader.readAsBinaryString(file)
                else reader.readAsArrayBuffer(file)
            } catch (err) {
            }
        } else {
            alert('FUCKOFF')
        }
    }
    const downloadTemplate = () => {
    let arr = [{bool:name,name:'Student Name',sample:'test name'},{bool:school,name:'School',sample:'test School'},{bool:Class,name:'Class',sample:'test class'},{bool:adress,name:'Email Address',sample:'test@test.com'},{bool:ident,name:'Identification',sample:'1'}]
   
    let csvHeaders=['index number']
    let csvsamplearr=[1]
         for(let i =0;i<arr.length;i++){
           
            if(arr[i].bool){
                csvHeaders.push(arr[i].name)
                csvsamplearr.push(arr[i].sample)
            }
        }
        let csvarr = [csvHeaders,csvsamplearr]
        return csvarr
    }
    const processCSV = (str, delim = ',' , type) => {
      
          //Get the header from the large chunk of string read from the csv file
          const header = str.slice(0, str.indexOf('\n')).trim().replace(/['"]+/g, '').split(delim)
          // Get all the data from the large chunk of string read from the csv file as an array
           setCSVHeaders(header)
        let headerarr = []
        header.map((el,key)=>{
          let obj = {
            id:el,
            label:el,
            key:el
          }
          headerarr.push(obj)
        })
        setHeaders(headerarr)
        //Set an object of mapFields
        let newMapFields = {}
        headers.forEach((h, i) => {
            if (header[i]) newMapFields[h.id] = header[i]
        })
        setCSVMapFields(newMapFields)
        const rows = str.slice(str.indexOf("\n") + 1).split("\n");

              let filterd = rows.filter(el=>{
                return el.length>1;
              })
              console.log(rows)
          const newArray = filterd.map(row => {
            let rowarr = row.split("");
          
            rowarr.map((el, index) => {
              if (el === "," && rowarr[index - 1] === ",") {
                rowarr.splice(index, 0, " ");
              }
            });
      
            const values = delimitCSVRow(rowarr.join(""));
      
              const eachObject = header.reduce((obj, header, i) => {
                  //This function will return an array with the last item being an object with undefined values
                  //So we check if the object is undefined before using string functions on the object
                  obj[header] = values[i]
                      ? values[i].trim().replace(/["]+/g, '')
                      : values[i]
                  return obj
              }, {})
              return eachObject
          })
        
            const a = newArray.filter((n, i) => i < newArray.length)
            console.log(newArray)
      
            setCSVArray(a)
            //Keep the original as reference and use the copyCSVArray to decide which columns to map to which field
            setCopyCSVArray(a)

       
         
        // if (!isAdmin()) submitCSVAsList(a)
    }
    const cancelCSVFile = () => {
        setCSVFile(null)
        setCSVHeaders()
        setCSVMapFields()
        setCSVArray([])
        setCopyCSVArray([])
        setList([])
        setSort('')
    }
   
    const submitCSVAsList = data => {
        let newArr = []
        data.forEach((row, INDEX) => {
            let newObj = {}
            newObj.id = INDEX

            headers.forEach(({ key }, i) => {
              newObj[key] = Object.values(row)[i]
            })
            newArr.push(newObj)
        })
        
        let templist = newArr
        
        templist.map((el , index)=>{
           
          

            if(checkError(templist , el)){
                const fromIndex = index;
                const element = templist.splice(fromIndex, 1)[0];
                templist.splice(0, 0, element);
            }
        })
        
       setList(templist)
       

    }
    // Handle changes to dataset
    const cancelList = () => {
        setList([])
        setSort('')
    }
    const massUpdateList = (identifier, value) => {
        let newList = [...list]
        newList.forEach(n => {
            if (selected.includes(n.id)) n[identifier] = value
        })
        setList(newList)
    }
   
    const checkSelect = () => {
        // if(level===''){
        //   return showWarningSwal('Please select a Level')
        // }
        return openFileInput()
    }
   
    const onSubmit = () => {
      let payload= {}
      let rowsWithError = []
      let particpaents = []
      list.forEach((row,INDEX) => {
       
     
          if (checkForErrorInRow(row)) rowsWithError.push(row)
      })
      if(rowsWithError.length>0){
        return showWarningSwal('There are errors in your data')
      }

      list.map((el)=>{
        let particapObj = {}
        let particINDEX = el['INDEX NUMBER'];
        let answers = []
        Object.keys(el).forEach(function(key, INDEX) {
    if(key==='index number'){
        particapObj.index_no = el[key]
    }else if(key==='Student Name'){
        particapObj.name = el[key]
    }else if(key==='School'){
        particapObj.school_name = el[key]
    }else if(key==='Email Address'){
        particapObj.email = el[key]
    }else if(key==='Identification'){
        particapObj.identifier = el[key]
    }else if(key==='Class'){
        particapObj.class = el[key]
    }
        });
        particpaents.push(particapObj)
      })
      payload = {
        participants:particpaents
      }
      console.log(payload, 'payload')
      setLoading(true)
      patchParticipantbulk(payload , signal).then(d=>{
      setLoading(false)
      if(d.errors){
       
        let temperrors= []
        Object.keys(d.errors).forEach(function(key, INDEX) {
          let errorINDEX = key.split('.')[1]
          if(!temperrors.includes(errorINDEX)){
            temperrors.push(errorINDEX)
          }
        });
        setErrors(temperrors)
       console.log(temperrors)
    //     let templist = list
        
    //     templist.map((el , index)=>{
    //         if(temperrors.includes(String(index))){
    //             const fromIndex = index;
    //             const element = templist.splice(fromIndex, 1)[0];
    //             templist.splice(0, 0, element);
    //         }
    //     })
    //    setList(templist)
       
         }else{
        showNotification('success' , d.message , enqueueSnackbar , closeSnackbar)
        setErrors([])
      }
     }).catch(()=>{
     })
    }
    const massDeleteList = () => {
        let newList = [...list]
        selected.forEach(s => newList = newList.filter(n => n.id !== s))
        setList(newList)
        setSelected([])
        setSelecting([])
    }
    const noCondition = () => false
    // const invalidSchlName = value => !/^[\u{2019}\'\;\.\,\s\(\)\[\]\w-]*$/.test(value)
   
    const uploadError = []

    const sortOptions = () => {
        let options = []
        uploadError.forEach(e => {
            list.forEach(l => {
               
            })
        })
        return options
    }
    const onChangeSort = value => {
        setSort(value)
        if (list) {
            let newList = [...list]
            let ids = []
            newList.forEach(l => {
                if (sortOptions().find(s => s.id === value).condition(l[value])) ids.push(l.id)
            })
            ids.forEach(id => {
                let newOne = newList.find(n => n.id === Number(id))
                if (newOne !== undefined) {
                    newList = newList.filter(n => n.id !== Number(id))
                    newList.unshift(newOne)
                }
            })
            setList(newList)
        }
    }
    const checkOrUncheck = () => {
        let rowsWithError = []
        list.forEach(row => {
            if (checkForErrorInRow(row)) rowsWithError.push(row)
        })
        let newSelected = [...selected]
        let newSelecting = [...selecting]
        for (let i = 0; i < rowsWithError.length; i++) {
            if (newSelected.includes(rowsWithError[i].id)) {
                let INDEX = newSelected.INDEXOf(rowsWithError[i].id)
                newSelected.splice(INDEX, 1)
                newSelecting.splice(INDEX, 1)
            } else {
                newSelected.push(Number(rowsWithError[i].id))
                newSelecting.push(list.find(l => l.id === Number(rowsWithError[i].id)).name)
            }
        }
        setSelected(newSelected)
        setSelecting(newSelecting)
    }
    const checkForErrorInRow = row => {
        // return true upon encountering error at any column
        if (list === undefined) return false
        // return true upon encountering error at any row
        for (let i = 0; i < list.length; i++){ 
            if(errors.includes(String(i))){ 
                uploadError.push(i)
            }
          if (checkError(list , row)||isEmpty(row)) {
           
          return true
        }}
        return false
    }
    const checkForError = list => {
    
        if (list === undefined) return false
        // return true upon encountering error at any row
        for (let i = 0; i < list.length; i++) if (checkError(list , list[i])||isEmpty(list[i])) return true
        return false
    }
    const isDuplicate = (list, row) => {
        let occurences = 0
        list.forEach(l => {
            if (l['INDEX NUMBER'] === row['INDEX NUMBER']) {
                occurences += 1
            }
        })
        return false
    }
    const isEmpty=(row)=>{
        let bool =false
        for (const key in row) {
            console.log(key)
            if (row.hasOwnProperty(key)&&key!=='Identification') { 
                    if(row[key]===''){
                        console.log(row[key],'checkedd')
                       bool = true;
                    }
            }
          }
          return bool
    }
    const checkError= (list , row)=>{
      let occurences = 0
      list.forEach(l => {
          if (l['index number'] === row['index number']) {
              occurences += 1
          }
      })
   
      return occurences > 1
    }
    const correctColumnsInCSV = arr => {
        return new Set(arr).size === arr.length
        // return Object.keys(csvHeaders).length === headers.length * new Set(arr).size !== arr.length
        //     && new Set(arr).size === arr.length
    }
    const onClickSubmitCSVMapFields = () => {
      setErrors([])
        return correctColumnsInCSV(Object.values(csvMapFields)) ? list.length ? null : submitCSVAsList(copyCSVArray) :
            showWarningSwal('Please assign only one column to each field.')
    }
    const templateDivStyle = {
        display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'space-evenly',
        border: '1px solid #707070', borderRadius: 12, height: 220, width: 700
    }
    const massAssignStyle = { display: 'flex', alignItems: 'center', marginRight: 30 }
    const owoHeaders = headers.filter(h => h.id !== 'tuition_centre')
    const selectContainerStyle = {
        border: '1px solid #000', borderRadius: 12,
        backgroundColor: '#F5F5F5', padding: 24,
        
    }
    return (
        <Box style={{ flexGrow: 1 }}>
           
            <Grid container justifyContent='space-between' alignItems='center' style={{ paddingInline: 42, marginTop: 30 }}>
                <NunitoText value={`Edit Participants`} fontSize={40} fontWeight={700} italic color='#144A94' />
                <Grid container justifyContent='center' alignItems='center' style={{ marginTop: 30 , width:'100%' }}>
                    
                {/* <Grid container justifyContent='flex-start' alignItems='center' style={selectContainerStyle}>
                            
                          
                               
                                <ReusableAutocompleteWithID type='default' width={240} height={54} placeholder='Level'
                                    state={level} setState={setLevel} borderColor='#707070' required
                                    options={levelOptions} grayedOut={!round} readOnly={!round} />
                                    <Grid container style={{ marginTop: 24 }}>
                <ReusableButton text='Reset' bgColor='#F16774' fontSize={16} br={8} onClick={()=>{}} iconType='reset' />
            </Grid>
                           
                           </Grid>   */}
                </Grid>
                {/* school: {JSON.stringify(schoolFiltered)}<br /><br /> */}
                {/* participant: {JSON.stringify(participantFiltered)}<br /><br /> */}
                <Grid container justifyContent='space-between' alignItems={'center'} style={{ marginTop: 15 }}>
                    <div>
                        <NunitoText value={`File Select:${csvFile ? ` ${csvFile.name}` : ''}`} fontSize={20}
                            fontWeight={600} color='#144A94' align='left' />
                        <input id='file-upload-for-csv' type="file" accept='.csv' onChange={e => onChangeCSVFile(e, e.target.files[0])}
                            style={{ display: 'none' }} />
                        <div style={{ display: 'flex', alignItems: 'center', marginTop: 20 }}>
                            <ReusableButton text='Select File' bgColor='#144A94' fontSize={16} height={59} width={160} iconType='file'
                                onClick={() => checkSelect()} />
                            {/* <ReusableTextField type='clickOnly' width={500} height={60} bgColor='#F2F2F2'
                                marginLeft={120} onClick={() => checkSelect()} /> */}
                        </div>  
                    </div>
                    <div style={{ width: 700 }}>
                        <div style={templateDivStyle}>
                            <NunitoText value='PLEASE DOWNLOAD AND USE TEMPLATE HERE' fontSize={24} fontWeight={400} color='#F16774' />
                            <ArrowDownwardIcon style={{ color: '#F16774', fontSize: 45 }} />
                            <ReusableButton text='Download Template' bgColor='#F16774' fontSize={20} height={57} width={325}
                                iconType='download' br={24} onClick={() => setOnDownload(true)} />
                        </div>
                       
                    </div>
                </Grid>
            </Grid>
          
            { csvHeaders && Boolean(csvArray.length) &&
                <Grid container style={{ paddingInline: 42, marginTop: 20 }}>
                   
                    
                    <Grid container justifyContent="flex-end" style={{ marginBlock: 20 }}>
                        <ReusableButton text='Cancel' bgColor='#8D8D8D' fontSize={16} height={50} width={130} marginRight={20}
                            onClick={() => cancelCSVFile()} />
                        <ReusableButton text='Submit'
                            bgColor={correctColumnsInCSV(Object.values(csvMapFields)) && !list.length ? '#5E75C3' : '#8D8D8D'}
                            fontSize={16} height={50} width={130} onClick={() => onClickSubmitCSVMapFields()} />
                        {/* <ReusableButton text='Submit' bgColor='#5E75C3' fontSize={16} height={50} width={130}
                            onClick={() => submitCSVAsList(copyCSVArray)} /> */}
                    </Grid>
                </Grid>}
            {Boolean(list.length) && <Grid container justifyContent='space-between' alignItems='center' style={{ marginTop: 60 }}>
                <NunitoText value={'Participants'} fontSize={30} fontWeight={800} italic
                    color='#144A94' marginLeft={40} />
                <div style={{ marginTop: 20, paddingRight: 55, display: 'flex', alignItems: 'center' }}>
                   
                    <ReusableButton text='Mass Delete' bgColor='#E83D4D' fontSize={16} height={50} iconType='delete'
                        marginRight={20} onClick={() => massDeleteList()} />
                    {/* {Boolean(selected.length) ?
                        <CSVLink filename={'Answers'} style={{ textDecoration: 'none' }} data={list.filter(p => selected.includes(p.id))}>
                            <ReusableButton text={`Export Selected Answers`}
                                fontSize={16} bgColor='#5E75C3' height={50} iconType='export' />
                        </CSVLink> :
                        <ReusableButton text={`Export Selected Answers`} bgColor='#707070'
                            fontSize={16} height={50} iconType='export'
                            onClick={() => showWarningSwal(`Please select at least one Answer(s) to export`)} />} */}
                </div>
            </Grid>}
            {Boolean(list.length) && <Grid container alignItems='center' justifyContent='space-between'
                style={{ marginTop: 20, paddingInline: 42 }}>
                {isAdminOrPartnerOrAssistant() && <Grid container alignItems='center'>
                    {/** this will be mass assign answers*/} 
                    {/* <div style={massAssignStyle}>
                        <ReusableAutocomplete type='table' state={massAssignSchool} setState={setMassAssignSchool}
                            placeholder='School' width={240} borderColor='#000' marginRight={30} freeSolo
                            options={actualMassSchoolOptions.map(s => ({ id: s.id, option: s.name }))} />
                        <ReusableButton text='Mass Assign School' fontSize={15} bgColor='#5E75C3' height={50}
                            onClick={() => massUpdateList('school', massAssignSchool)} />
                    </div> */}
                    
                </Grid>}
               
            </Grid>}
            {loading && <Loader height={800} />}
            <div style={{ display: loading && 'none' }}>
                {Boolean(list.length) && <Grid container style={{ paddingInline: 42 }}>
                    <CSVUploadTable headers={headers} data={list} error={errors}
                       rowsPerPage={rowsPerPage} setRowsPerPage={setRowsPerPage}
                        selecting={selecting} setSelecting={setSelecting} selected={selected} setSelected={setSelected} setState={setList}
                        checkForErrorInRow={checkForErrorInRow} isDuplicate={isDuplicate} 
                      
                    />
                   
                </Grid>}
                {Boolean(list.length) && <Grid container justifyContent="flex-end" style={{ marginBlock: '50px 20px', paddingInline: 42 }}>
                    <ReusableButton text='Cancel' bgColor='#8D8D8D' fontSize={16} height={50} width={130} marginRight={20}
                        onClick={() => cancelList()} />
                    <ReusableButton text='Submit' bgColor='#5E75C3' fontSize={16} height={50} width={130}
                        onClick={() => onSubmit()} />
                </Grid>}
            </div>
            {/* {error && sortErrorByRow(error).map((e, i) => (
                <Grid key={i} container>{JSON.stringify(e)}</Grid>
            ))}<br /> 
           {error && sortErrorByType(error).map((e, i) => (
                <Grid key={i} container>{JSON.stringify(e)}</Grid>
            ))}<br />
            {error && sortErrorByRow(error).map((e, i) => (
                <Grid key={i} container>{JSON.stringify(e)}</Grid>
            ))}<br /> 
             {JSON.stringify(selected)} 
             <p>keys: {JSON.stringify(headers.map(h => h.id))}</p>
            {list.length && JSON.stringify(checkForError(list))}
            {list.length && list.map((l, i) => (
                <div key={i} style={{ border: '1px solid gold', marginBottom: 10 }}>
                    <p>row: {JSON.stringify(l)}</p>
                    <p>error: {JSON.stringify(checkForErrorInRow(l))}</p>
                </div>
            ))} */}
    
            <Modal open={onDownload} onClose={() => setOnDownload(false)}>
                <>
                    {<EditParticipantsCheckList name={name} school={school} adress={adress} ident={ident} setN={setN} setS={setS} setA={setA} setI={setI} Class={Class} setC={setC}
                        downloadfunc={downloadTemplate} cancelDownload={() => setOnDownload(false)} />}
                </>
            </Modal>
        </Box >
    )
}