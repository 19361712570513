import React from "react";
import { Box, Grid } from "@mui/material";
import { makeSureIsArray } from "../../functions/general";
import NunitoText from "../general/NunitoText";
import ReusableButton from "../general/ReusableButton";
export default function ConfirmVerfiy({
  confirmFunction,
  cancelConfirm,
  object,
  value,
}) {
  console.log(value);
  return (
    <Box className="popUpModal" style={{ width: 705, minHeight: 310 }}>
      <NunitoText
        value="CONFIRM SUBMIT?"
        fontSize={30}
        fontWeight={600}
        color="#F16774"
        marginTop={30}
      />
      <NunitoText
        value={`Are you sure you want to Verfiy the following ${object}`}
        fontSize={20}
        fontWeight={600}
      />
      <NunitoText
        value={value.name || value.identifier || value}
        fontSize={20}
        fontWeight={700}
      />

      <Grid
        container
        justifyContent="space-evenly"
        style={{ marginBlock: "30px 20px" }}
      >
        <ReusableButton
          text="Cancel"
          bgColor="#8D8D8D"
          fontSize={25}
          height={63}
          width={280}
          onClick={() => cancelConfirm()}
        />
        <ReusableButton
          text="Confirm"
          bgColor="#5E75C3"
          fontSize={25}
          height={63}
          width={280}
          onClick={() => confirmFunction()}
        />
      </Grid>
    </Box>
  );
}
