import React from 'react'
import { Box, Grid } from '@mui/material'
import { useSelector } from 'react-redux'
import { makeSureIsArray } from '../../functions/general'
import { CSVLink, CSVDownload } from "react-csv";

import NunitoText from '../general/NunitoText'
import ReusableButton from '../general/ReusableButton'
import ReusableCheckbox from '../general/ReusableCheckbox'
export default function EditParticipantsCheckList({ downloadfunc, cancelDownload ,name,school, adress, ident, setN, setS, setA,setI , setC , Class}) {
    const checkBoxStyle = { 
        color: "#000", marginTop: 6
    }
    let all = name&&school&&adress&&ident&&Class;
    const setAll=()=>{
        if(all){
            setN(false)
            setS(false)
            setA(false)
            setI(false)
            setC(false)
        }else{
            setN(true)
            setS(true)
            setA(true)
            setI(true)
            setC(true)
        }
    }
    return (
        <Box className='popUpModal' style={{ width: 705, minHeight: 365 }}>
            <NunitoText value='Edit Participants' fontSize={30} fontWeight={600} color='#5E75C3' marginTop={30} />
            <NunitoText value='Please select the attributes you want to edit' fontSize={20} fontWeight={600} />
           
            <Grid  container justifyContent={'flex-start'}  style={{ marginBlock: "30px 20px" }}>
                <ReusableCheckbox label={'All'} type='default' setState={setAll} state={all} width={'40%'}/>
                <ReusableCheckbox label={'Class'} type='default' setState={setC} state={Class} width={'40%'}/>
                <Grid container justifyContent={'flex-start'}  style={{ marginBlock: "30px 20px" }}>
                <ReusableCheckbox state={name} setState={setN} label={'Student Name'} type='default' width={'40%'}/>
                <ReusableCheckbox state={school} setState={setS} label={'School'} type='default'  width={'40%'}/>
                </Grid>
               
                <Grid container justifyContent={'flex-start'}  style={{ marginBlock: "30px 20px" }}>
                <ReusableCheckbox label={'Email Address'} state={adress} setState={setA} type='default'  width={'40%'}/>
                <ReusableCheckbox label={'Identification'} type='default'  width={'40%'} state={ident} setState={setI}/>
                </Grid>
            </Grid>
            <Grid container justifyContent="space-evenly" style={{ marginBlock: "30px 20px" }}>
                <ReusableButton text='Cancel' bgColor='#8D8D8D' fontSize={25} height={63} width={280}
                    onClick={() => cancelDownload()} />
                    <CSVLink data={downloadfunc()} filename='edit_participants.csv' style={{
                        background:'#5E75C3',
                        fontSize:'25px',
                        height:'63px',
                        width:'280px',
                        textDecoration:'none',
                        textTransform: 'none',
                        borderRadius: 12,
                        padding: '10px 20px',
                        color:'#FFF'
                    }}
                    onClick={()=>{setTimeout(() => {
                        cancelDownload()
                    }, 200)}}
                    >Confirm</CSVLink>;
                {/* <ReusableButton text='Confirm' bgColor='#5E75C3' fontSize={25} height={63} width={280}
                    onClick={() => downloadfunc()} /> */}
            </Grid>
        </Box>
    )
}